/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'

import DefaultButton from '../../components/DefaultButton'

import { clearAuthentication, loggedInUser } from '../../actions/auth'
import { default_theme as theme } from '../../emotion/theme'
import dashboard_light from '../../images/dashboard_light.png'
import content_light from '../../images/content_light.png'
import profile_light from '../../images/profile_light.png'
import transactions_light from '../../images/transactions_light.png'
import subscriptions_light from '../../images/subscriptions_light.png'
import logo from '../../images/npo-connect-square-flat-white.png'

class MenuLeft extends Component {
    
    onLogout = () => {
        const { dispatch } = this.props
        dispatch(clearAuthentication())
    }
    
    render() {
        const { email } = this.props
        return (
            <div css={ main }>
              <div css={ header } >
                <a className="navbar-brand" href="/admin/dashboard" css={ href }>
                  <img src={ logo } css={ image_src } alt="" />
                </a>   
              </div>
              <div css={ menu } >
                <Link
                    to="/admin/dashboard"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ dashboard_light } css={ icon } alt="" />
                  Dashboard
                </Link>
                <Link
                    to="/admin/organisations"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ content_light } css={ icon } alt="" />
                  NPOs
                </Link>
                <Link
                    to="/admin/transactions"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ transactions_light } css={ icon } alt="" />
                  Transactions
                </Link>
                <Link
                    to="/admin/subscriptions"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ subscriptions_light } css={ icon } alt="" />
                  Subscriptions
                </Link>
              </div>
              <div css={ footer } >
                <label css={ account }>
                  <img src={ profile_light } css={ icon } alt="" />
                  <div css={ truncate }>{ email }</div>
                </label>
                <DefaultButton
                    label="Logout"
                    type="button"
                    onClickButton={ this.onLogout }
                    extra_class={ footer_button }
                />
              </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const admin = loggedInUser()
    return {
        email: admin.email,
    }
}
export default connect(mapStateToProps)(MenuLeft)

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
height: 100vh;
font-size: 20px;
background-color: ${theme.colors.primary};
`

const button = css`
color: ${theme.colors.white};
display: flex;
align-items: center;
height: 100%;
width: 100%;
transition: background-color 0.3s ease-in-out;
letter-spacing: 1.5px;
:hover, :focus, :active {
color: ${theme.colors.white};
background-color: ${theme.colors.secondary};
font-size: 20px;
}
`

const icon = css`
padding-right: 15px;
`

const header = css`
height: 150px;
width: 90%;
display: flex;
flex-direction: column;
justify-content: center;
margin: 15px;
border-radius: 10px;
`

const menu = css`
height: 250px;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-around;
`

const image_src = css`
max-width: 100%;
max-height: 100%;
object-fit: contain;
`

const footer = css`
flex-grow: 1;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
padding-left: 15px;
color: ${theme.colors.white};
`

const footer_button = css`
color: ${theme.colors.white};
background-color: ${theme.colors.primary};
display: flex;
align-items: center;
padding-left: 49px;
font-size: 20px;
`

const href = css`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`

const account = css`
display: flex;
`

const truncate = css`
width:220px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`
