/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import Select from 'react-select'
import ReactTimeout from 'react-timeout'

import DefaultButton from './DefaultButton'

const SEARCH_DELAY_MILLISECONDS = 1000

class DropdownFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: null,
        }
    }
    
    onChange = (value) => {
        const { onChange } = this.props
        this.setState({
            value: value,
        })
        onChange(value)
    }

    onClear = () => {
        const { onClear } = this.props
        this.setState({
            value: null,
        })
        onClear()
    }

    render() {
        const { value } = this.state
        const { options, placeholder } = this.props

        return (
            <div css={ main }>
              <Select
                  value={value}
                  options={options}
                  onChange={this.onChange}
                  css={ date }
                  placeholder={ `Filter ${placeholder}` }
              />
              <div css={ clear }>
                <DefaultButton
                    label="Clear"
                    type="button"
                    light={ true }
                    onClickButton={ this.onClear }
                />
              </div>
            </div>
        )
    }
}
export default ReactTimeout(DropdownFilter)

const main = css`
display: flex;
align-items: center;
`

const date = css`
width: 250px;
&:hover {
cursor:pointer;
}
`

const clear = css`
padding-left: 10px;
`
