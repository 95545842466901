import { ItemList } from '../../orm'
import { has } from 'lodash'
import { http } from '../../lib'
import { SubmissionError } from 'redux-form'

class AdminOrganisationList extends ItemList {
    getEntityKey() {
        return 'admin/organisation'
    }
}

export const adminOrganisationList = new AdminOrganisationList('admin/organisation')

class AdminActiveOrganisationList extends ItemList {
    getEntityKey() {
        return 'admin/active_organisation'
    }
}

export const adminActiveOrganisationList = new AdminActiveOrganisationList('admin/active_organisation')

class AdminOrganisationNoPaginationList extends ItemList {
    getEntityKey() {
        return 'admin/organisation_no_pagination'
    }
}

export const adminOrganisationNoPaginationList = new AdminOrganisationNoPaginationList('admin/organisation_no_pagination')

class AdminOrganisationOptionList extends ItemList {
    getEntityKey() {
        return 'admin/organisation_option'
    }
}

export const adminOrganisationOptionList = new AdminOrganisationOptionList('admin/organisation_option')

export const ANNOUNCE_SENDING_INVITE = "ANNOUNCE_SENDING_INVITE"
export const ANNOUNCE_INVITE_SENT = "ANNOUNCE_INVITE_SENT"
export const ANNOUNCE_INVITE_NOT_SENT = "ANNOUNCE_INVITE_NOT_SENT"

export function announceSendingInvite() {
    return {
        type: ANNOUNCE_SENDING_INVITE
    }
}

export function announceInviteSent() {
    return {
        type: ANNOUNCE_INVITE_SENT
    }
}

export function announceInviteNotSent() {
    return {
        type: ANNOUNCE_INVITE_NOT_SENT
    }
}

export function sendInvite(values, on_done) {
    return async (dispatch, getState) => {
        dispatch(announceSendingInvite())
        let [json, success] = await http.post(getState(), 'admin/organisation/send_invite', values)
        if (success.status === 200 && has(json, 'status') && json['status'] === 'success') {
            dispatch(announceInviteSent())
            on_done(json)
        } else {
            dispatch(announceInviteNotSent())
            throw new SubmissionError({_error: 'Unable to send invite'})
        }
    }
}
