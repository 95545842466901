/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field, getFormValues } from 'redux-form'
import { isEmpty } from 'lodash'
import moment from 'moment'

import { articleList } from '../actions/article'
import { imageList, imageCropList } from '../actions/image'

import InputField from './InputField'
import InputArea from './InputArea'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'
import CheckboxField from './CheckboxField'
import DatetimePickerField from './DatetimePickerField'
import RadioSelectField from './RadioSelectField'
import Modal from './Modal'
import FileUploader from './FileUploader'
import BusyMask from './BusyMask'
import ImageCropper from './ImageCropper'

import { default_theme as theme } from '../emotion/theme'
import placeholder from '../images/placeholder_dark.png'
import npo_icon from '../images/npo-connect.png'

const required = value => value ? undefined : 'Required'
const maxLength = max => value => 
    value && value.length > max ? `Must be ${max} characters or less` : undefined
const charCount = max => value => `${value ? value.length : 0}/${max}`
const maxLength75 = maxLength(75)
const charCount75 = charCount(75)
const maxLength1500 = maxLength(1500)
const charCount1500 = charCount(1500)

class ArticleForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            displayUnpublishModal: false,
            display_image_cropper: false,
            image_id: null,
        }
    }
    
    componentDidMount() {
        const { dispatch, article_id, image_id } = this.props
        dispatch(articleList.ensureObjectLoaded(article_id))
        dispatch(imageList.ensureObjectLoaded(image_id))

    }

    componentDidUpdate() {
        const { dispatch, article } = this.props
        dispatch(imageList.fetchListIfNeeded())
        dispatch(articleList.fetchListIfNeeded())
        dispatch(articleList.ensureObjectLoaded(article.id))
        dispatch(imageList.ensureObjectLoaded(article.image_id))

    }

    onUnpublishArticle = (e) => {
        e.preventDefault()
        this.toggleUnpublishModal()
    }

    toggleUnpublishModal = () => {
        const { displayUnpublishModal } = this.state
        this.setState({displayUnpublishModal: !displayUnpublishModal})
    }

    handleUnpublishArticle = () => {
        const { dispatch, history, article } = this.props
        const that = this
        article.status = 'Unpublished'
        article.published = false
        article.todays_message = false
        const on_done = function(json) {
            that.toggleUnpublishModal()
            history.push('/article/' + json.id)
        }
        dispatch(articleList.saveObject(article, on_done))
    }

    renderUnpublishModal = () => {
        const { article } = this.props
        return (
            <Modal
                title_icon={ npo_icon }
                title="Unpublish this Article?"
                onCloseModal={ this.toggleUnpublishModal }
                onActionModal={ this.handleUnpublishArticle }
                actionLabel="Unpublish" >
              <div css={ modal_container }>
                <strong>{ article.heading }</strong>
              </div>
            </Modal>
        )
    }

    renderExpiresAt = (field) => {
        if (!field.input.value) {
            field.input.value = moment().add(7, 'days')
        }
        return (
            <DatetimePickerField
                input={field.input}
                meta={field.meta}
            />
        )
    }

    handleCrop = (crop) => {
        const { dispatch, newImageFile, updateNewImageFile } = this.props
        const { image_id } = this.state
        const that = this
        const on_done = function(json) {
            dispatch(articleList.invalidateList())
            dispatch(imageList.invalidateObject(json.fileinfo.id))
            if (newImageFile) {
                updateNewImageFile(json.fileinfo)
            }
            that.toggleImageCropper()
        }
        return dispatch(imageCropList.update({image_id: image_id, crop: crop}, on_done))
    }

    renderImageCropper = () => {
        const { image_id } = this.state
        return (
            <ImageCropper
                image_id={ image_id }
                onCrop={ this.handleCrop }
                onCancel={ this.toggleImageCropper }
            />
        )
    }

    toggleImageCropper = () => {
        const { newImageFile, image } = this.props
        const { display_image_cropper } = this.state
        this.setState({
            image_id: newImageFile ? newImageFile.id : image.id,
            display_image_cropper: !display_image_cropper,
        })
    }

    twitterPostChange = (value) => {
        const { change } = this.props
        if (value === true) {
            change('display_in_app', true)
        }
    }

    appPostChange = (value) => {
        const { change } = this.props
        if (value === false) {
            change('display_in_twitter', false)
        }
    }

    render() {
        const { handleSubmit,
                onCancelArticle,
                onNewArticle,
                error,
                articleFormValues,
                article,
                is_busy,
                onSuccess,
                onFailure,
                upload_relative_url,
                image,
                onSubmit,
                newImageFile,
        } = this.props
        const { displayUnpublishModal, display_image_cropper } = this.state
        const expire_options = [
            {name: 'Never expires', id: true},
            {name: 'Date', id: false}
        ]
        
        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ column_container }>
                <div css={ field_container }>
                  <div className="form-group">
                    <label css={ label }>Article Heading</label>
                    <div>
                      <Field
                          type="text"
                          name="heading"
                          component={ InputField }
                          validate={[required, maxLength75]}
                          warn={[charCount75]}
                          maxLength={ 75 }
                      />
                    </div>
                  </div>
                  <div className="form-group" >
                    <label css={ label }>Article Body</label>
                    <div>
                      <Field
                          name="body"
                          rows={9}
                          component={ InputArea }
                          validate={[required, maxLength1500]}
                          warn={[charCount1500]}
                          maxLength={ 1500 }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div css={ column_container }>
                <div className="form-group" css={ field_container }>
                  <label css={ label }>Thumbnail Image (optional)</label>
                  <div css={ logo }>
                    <div css={ thumbnail }>
                      <div css={ image_container }>
                        { newImageFile &&
                          <img src={ newImageFile.thumbnail_download_url } alt="" css={ image_src }/>
                          
                        }
                        { !isEmpty(image) && !newImageFile &&
                          <img src={ image.thumbnail_download_url } alt="" css={ image_src } />
                        }
                        { isEmpty(image) && !newImageFile &&
                          <img src={ placeholder } alt="" css={ image_src } />
                        }
                      </div>
                      <FileUploader
                          upload_relative_url={ upload_relative_url }
                          upload_params={{ article_id: article.id }}
                          onSuccess={ onSuccess }
                          onFailure={ onFailure }
                          label="Upload"
                          file_types=".jpg, .png, .jpeg"
                          class_name="btn"
                      />
                    </div>
                    <div css={ details }>
                      <div>
                        <p css={ image_text }>
                          Please upload your image as at least a 512x512 pixel square image. If your image is not square, please upload a larger image and use the crop button to select a square-shaped size for the images in your mobile app.
                        </p>
                      </div>
                      <div css={ buttons }>
                        { (newImageFile || (!isEmpty(image) && !newImageFile)) &&
                          <PrimaryButton
                              label="Crop" 
                              type="button"
                              onClickButton={ this.toggleImageCropper }
                              ></PrimaryButton>
                        }
                        { (newImageFile || (!isEmpty(image) && !newImageFile)) &&
                          <a target="_blank" rel="noopener noreferrer" href={ newImageFile ? newImageFile.thumbnail_download_url : image.thumbnail_download_url } css={ link_button } className="btn">View</a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div css={ [column_container, css`max-width:750px;min-width:750px`] }>
                <div css={ fields_container }>
                  <div className="form-group" css={css`width:100%;`}>
                    <label css={ label }>Article appears in</label>
                    <div css={ checkboxes }>
                      <Field
                          horizontal={true}
                          component={CheckboxField}
                          name="display_in_app"
                          label="App"
                          postChange={this.appPostChange}
                      />
                      <Field
                          horizontal={true}
                          component={CheckboxField}
                          name="display_in_facebook"
                          label="Facebook"
                      />
                      <Field
                          horizontal={true}
                          component={CheckboxField}
                          name="display_in_twitter"
                          label="Twitter"
                          postChange={this.twitterPostChange}
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ [field_container, css`width:39%;`] }>
                    <label css={ label }>First Date Visible</label>
                    <div css={ padding_bottom }>
                      <Field
                          component={ DatetimePickerField }
                          name="visible_at" 
                          type="text"
                          autoComplete="off"
                          date_format={"YYYY-MM-DD"}
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ [field_container, css`width:61%;`] }>
                    <label css={ label }>Expiry Rule</label>
                    <div css={ horizontal_field }>
                      <div>
                        <Field
                            component={ RadioSelectField }
                            name="never_expires"
                            options={ expire_options }
                            horizontal={ true }
                        />
                      </div>
                      { articleFormValues && articleFormValues.never_expires === false &&
                        <div css={ date_field }>
                          <Field
                              component={ this.renderExpiresAt }
                              name="expires_at" 
                              type="text"
                              autoComplete="off"
                              date_format={"YYYY-MM-DD"}
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <div className="form-group" css={ [field_container, css`width:120px;`] }>
                    <label css={ label }>Article Status</label>
                    <Field
                        component={ InputField }
                        name="status"
                        type="text"
                        readOnly={ true }
                    />
                  </div>
                </div>
              </div>
              <div css={ footer }>               
                <div>
                  <DefaultButton
                      label="Back to News"
                      type="button"
                      onClickButton={ onCancelArticle }
                  />
                    <PrimaryButton
                        label="New Article"
                        type="button"
                        onClickButton={ onNewArticle }
                        extra_class={ margin_left }
                    ></PrimaryButton>
                </div> 
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div>
                  { article.published ?
                    <PrimaryButton
                        label="Unpublish"
                        type="button"
                        onClickButton={ this.onUnpublishArticle }
                        extra_class={ margin_right }
                        ></PrimaryButton>
                    :
                    <PrimaryButton
                        label="Save and Publish"
                        type="button"
                        onClickButton={ handleSubmit(
                            values => onSubmit({...values, published: true, status: 'Published'})
                        ) }
                        extra_class={ margin_right }
                        ></PrimaryButton>
                  }
                  <PrimaryButton
                      label="Save" 
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
              { displayUnpublishModal && this.renderUnpublishModal() }
              { display_image_cropper && this.renderImageCropper() }
              { is_busy && <BusyMask /> }
            </form>
        )
    }
}
ArticleForm = reduxForm({
    form: 'ARTICLE_FORM'
})(ArticleForm);
function mapStateToProps(state, props) {
    const { onSubmit,
            article_id,
            onCancelArticle,
            onNewArticle,
            onSuccess,
            onFailure,
            newImageFile,
            image_id,
    } = props
    const upload_relative_url = 'fileinfo/'
    const article = articleList.getObject(article_id) || {}
    const image = imageList.getObject(article.image_id) || {}
    const articleFormValues = getFormValues('ARTICLE_FORM')(state)
    let initialValues = {}
    if (!isEmpty(article)) {
        let status = ''
        if (article.is_expired) {
            status = 'Expired'
        } else if (article.published) {
            status = 'Published'
        } else {
            status = 'Unpublished'
        }
        initialValues = Object.assign({},
                                      article,
                                      {status: status})
    } else {
        initialValues = Object.assign({never_expires: true,
                                       display_in_app: true,
                                       status: 'Unpublished'})
    }

    return {
        onSubmit,
        onCancelArticle,
        onNewArticle,
        onSuccess,
        image_id,
        onFailure,
        article_id,
        article,
        initialValues,
        enableReinitialize: true,
        articleFormValues,
        upload_relative_url,
        image,
        newImageFile,
        is_busy: articleList.isLoading() ||
                 imageList.isLoading() ||
                 imageCropList.isLoading() ||
                 articleList.getIsSavingObject() ||
                 imageList.getIsSavingObject() ||
                 imageCropList.getIsSavingObject(),
    }
}
export default withRouter(connect(mapStateToProps)(ArticleForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
justify-content: space-between;
padding: 20px;
@media(max-width: 1280px) {
    justify-content: center;
    align-items: center;
}
`

const column_container = css`
display: flex;
flex-direction: column;
width: 48%;
min-width: 400px;
max-width: 600px;
padding: 20px;
@media(max-width: 1280px) {
    justify-content: center;
    align-items: center;
    min-width: 750px;
    max-width: 750px;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const fields_container = css`
width: 100%;
display: flex;
flex-wrap: wrap;
`

const horizontal_field = css`
display: flex;
flex-direction: row;
`

const date_field = css`
padding-left: 20px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
height: 100px;
padding-top:20px;
`

const logo = css`
display: flex;
justify-content: flex-start;
`

const thumbnail = css`
display: flex;
flex-direction: column;
justify-items: center;
align-items: center;
padding-right: 20px;
`

const details = css`
display: flex;
flex-direction: column;
justify-content: space-between;
`

const checkboxes = css`
display: flex;
`

const margin_right = css`
margin-right: 20px;
`

const margin_left = css`
margin-left: 20px;
`

const padding_bottom = css`
padding-bottom: 20px;
`

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const image_container = css`
width: 110px;
height: 110px;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 10px;
`

const image_src = css`
max-width: 110px;
max-height: 110px;
`

const buttons = css`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
`

const link_button = css`
background-color: ${theme.colors.primary};
color: ${theme.colors.white};
margin-left: 15px;
transition: background-color 0.15s ease-in-out;
&:hover {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
}
`

const image_text = css`
font-size: 0.8rem;
@media(max-width: 1300px) {
font-size: 0.75rem;
}
`
