/** @jsx jsx */
import {Component} from 'react'
import {connect} from 'react-redux'
import DropzoneComponent from 'react-dropzone-component'
import ReactDOMServer from 'react-dom/server'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import loading_icon from '../images/loading.gif'

class FileUploader extends Component {
    
    render() {

        const { upload_url, onSuccess, onFailure, upload_params, request_headers, file_types, label, class_name } = this.props

        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: upload_url,
        }

        var djsConfig = {
            params: upload_params,
            headers: request_headers,
            dictDefaultMessage: label,
            acceptedFiles: file_types,
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                  <div className="dz-progress">
                    <span className="dz-upload" data-dz-uploadprogress="true">
                      <div>
                        <div css={mask} id="mask" className="mask">
                        </div>
                        <div css={msg_container} id="msg_container" className="msg_container">
                          <div css={msg} id="msg" className="msg">
                            <img alt="loading" src={ loading_icon } />
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
            )
        }

        const eventHandlers = {
            init: (dropzone) => { this.dropzone = dropzone },
            error: onFailure,
            success: onSuccess,
            complete: (f) => this.dropzone.removeFile(f)
        }

        return (
            <div css={ class_name === 'btn' ? dropzone_container : undefined } className={ class_name }>
              <DropzoneComponent
                  config={componentConfig}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
              />
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { upload_relative_url, upload_params, onSuccess, onFailure, file_types, label, class_name } = props

    let request_headers = {}
    if (localStorage.getItem('apitoken')) {
        request_headers['X-NPOCONNECT-AUTHENTICATION-TOKEN'] = 'Token ' + localStorage.getItem('apitoken')
    }

    const API_BASE = state.settings.configured && state.settings.API_BASE
    const upload_url = API_BASE + upload_relative_url

    return {
        upload_url,
        upload_params,
        request_headers,
        onSuccess,
        onFailure,
        file_types,
        label,
        class_name,
    }
}

export default connect(mapStateToProps)(FileUploader)

const dropzone_container = css`
background-color: ${theme.colors.primary};
color: ${theme.colors.white};
transition: background-color 0.15s ease-in-out;
&:hover {
background-color: ${theme.colors.secondary};
}
`

const mask = css`
width: 100vw;
height: 100vw;
background-color: lightgrey;
height: 99%;
position: fixed;
top: 0px;
left: 0px;
opacity: 0.9;
z-index: 998;
`

const msg_container = css`
width: 100vw;
height: 100vw;
background-color: transparent;
height: 99%;
position: fixed;
top: 0px;
left: 0px;
z-index: 999;
text-align: center;
display: flex;
justify-content: center;
`

const msg = css`
opacity: 1.0;
border-radius: 25px;
position: absolute;
padding: 20px;
margin-top: 40vh;
background-color: ${theme.colors.white};
`
