import { ItemList } from '../orm'
import { ENTITY_KEY__ORGANISATION, LIST_KEY__ORGANISATION } from './ItemListKeyRegistry'

class OrganisationList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__ORGANISATION
    }
}

export const organisationList = new OrganisationList(LIST_KEY__ORGANISATION)
