/** @jsx jsx */
import react, { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css, Global } from '@emotion/core'
import { withRouter } from 'react-router'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment'
import { filter, map, isEmpty } from 'lodash'

import { eventList } from '../actions/event'

import MainLayout from './MainLayout'
import DefaultButton from './DefaultButton'
import { messageList } from '../actions/message';
import Modal from './Modal'

import npo_icon from '../images/npo-connect.png'

class Events extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      eventId: null,
      dayEvents: [],
      date: moment(),
    }
  }

  calendarComponentRef = react.createRef()

    componentDidMount() {
      const { dispatch } = this.props
      const calendarApi = this.calendarComponentRef.current.getApi()
      dispatch(eventList.invalidateList())
      dispatch(eventList.fetchListIfNeeded())
      const sessionStorageDate = sessionStorage.getItem("session_storage_date")

      if (sessionStorage.getItem("session_storage_date")) {
        this.setState({date: moment(sessionStorageDate)})
        calendarApi.gotoDate(moment(sessionStorageDate).format( 'YYYY-MM-DD'))
      }
    }
 
    onAddEvent = () => {
      const { history } = this.props
      history.push('/event')
    }
    
    onEditClick = (event_id) => {
      const { history } = this.props
      history.push('/event/' + event_id)
    }

    onDeleteClick = (event_id) => {
      const { dispatch } = this.props
      dispatch(eventList.deleteObject(parseInt(event_id)))
      this.hideModal()
    }

    onAddEventReminder = (event_id) => {
      const { history, dispatch } = this.props
      const on_done = function(json) {
          dispatch(messageList.updateListFilter({active: false}))
          history.push('/event_reminder/' + json.id + `?event_id=${event_id}`)
      }
      return dispatch(messageList.saveObject({isNewInstance: true}, on_done))
    }

  
    renderDeleteEventModal = () => {
        return (
            <Modal
              title_icon={ npo_icon }
              title="Delete event?"
              onActionModal={() => this.onDeleteClick(this.state.eventId) }
              onCloseModal={this.hideModal}
              actionLabel="Yes"
              hide_secondary_button={ false }
              modal_size="small"
            >
            </Modal>
        )
    }
  
    hideModal = () => {
      this.setState({modalOpen: false})
    }
    
    showModal = (event_id) => {
      this.setState({modalOpen: true, eventId: event_id})
    }

    selectDay = (date) => {
      this.setState({
        date: moment(date),
      })
      eventList.getDayEvents(date)
      sessionStorage.setItem("session_storage_date", moment(date))
    }

    getDayEvents = (date, events) => {
        return filter(events, (event) => {
            return moment(event.start).isSame(moment(date), 'date')
        })
    }

    renderDayCellContent = (dayInfo, createElementWithValidation) => {
        const { calendar_events } = this.props
        const selectedDate = this.state.date
        const { dayNumberText, date } = dayInfo
        const dayEvents = this.getDayEvents(date, calendar_events)
        const selected = moment(date).isSame(moment(selectedDate), 'date')

        return (
            <div
                css={ selected ? [ calendar_day_cell, calendar_day_cell_outline] : calendar_day_cell }
                onClick={ () => { this.selectDay(date) } }
            >
              <div css={ calendar_day_cell_date}>
                { dayNumberText && dayNumberText }
              </div>
              <div css={ calendar_day_cell_events }>
                {
                    map(dayEvents, (event) => {
                        const { color, id } = event
                        return (
                            <div
                                key={ id }
                                css={ [calendar_day_cell_event,
                                       css`background: ${color}`
                                ]
                                }>  
                            </div>
                        )
                    })
                }
              </div>
            </div>
        )
    }

    renderDayEvents = () => {
      const { date } = this.state
        return (
            <div css={ css`width:100%; padding: 1rem;` }>
              <div>
                { `Events for: ${date.format("dddd DD MMMM YYYY")}` }
              </div>
              <hr/>
              <div css={ css` height:300px; overflow:auto;` }>
                {
                    map(eventList.getDayEvents(date), (event) => {
                        const { id, title, color, start, end, address, description, document_name, confirmed,
                                maybe, rejected, allDay, document_url } = event

                        const eventDetails = [
                            {
                                'name': 'Event Name',
                                'value': title,
                            },
                            {
                                'name': 'Start Time',
                                'value': allDay ? 'All day event' : moment(start).format('HH:mm'),
                            },
                            {
                                'name': 'End Time',
                                'value': allDay ? 'All day event' : moment(end).format('HH:mm'),
                            },
                            {
                                'name': 'Address',
                                'value': address,
                            },
                            {
                                'name': 'Details',
                                'value': description,
                            },
                        ]
                        if (document_name) {
                            eventDetails.push({
                                'name': 'Event Document',
                                'value': document_name,
                                'url': document_url
                            })
                        }

                        const eventAttendance = [
                            {
                                'name': 'Going',
                                'value': confirmed,
                                'color': '#9bcb3c',
                            },
                            {
                                'name': 'Maybe',
                                'value': maybe,
                                'color': '#559dc2',
                            },
                            {
                                'name': 'Not Going',
                                'value': rejected,
                                'color': '#e56325',
                            },
                        ]
                        
                        return (
                            <div key={ id } >
                              <div css={ event_container }>
                                <div css={ [event_details, css`border-left 6px solid ${color}`] }>
                                  {
                                      map(eventDetails, ({name, value, url}, key) => {
                                          return (
                                              <div key={ key } css={ event_detail_row }>
                                                <div css={ event_detail_name }>{ `${name}:` }</div>
                                                { url ?
                                                  <div css={ event_detail_value }>
                                                    <a target={ '_blank' } href={ url }>{ value }</a>
                                                  </div>
                                                  :
                                                  <div css={ event_detail_value }>{ value }</div>
                                                }
                                              </div>
                                          )
                                      })
                                  }
                                </div>
                                <div css={ event_attendance }>
                                  {
                                      map(eventAttendance, ({ name, value, color }, key) => {
                                          return (
                                              <div key={ key } css={ [ event_attendance_column, css`color: ${color}` ] }>
                                                <div css={ event_attendance_cell }>{`${name}:`}</div>
                                                <div css={ event_attendance_cell }>{ value }</div>
                                              </div>
                                          )
                                      })
                                  }
                                </div>
                                <div css={ event_options }>
                                  <div css={ event_options_row }>
                                    <div css={ event_option_cell }>
                                      <DefaultButton
                                          label="Edit"
                                          type="button"
                                          onClickButton={() => this.onEditClick(id)}
                                      />                    
                                    </div>
                                    <div css={ event_option_cell }>
                                      <DefaultButton
                                          label="Delete"
                                          type="button"
                                          onClickButton={() => this.showModal(id)}
                                      />                
                                    </div>
                                  </div>
                                  <div css={ event_options_row }>
                                    <DefaultButton
                                        label="Send Reminder"
                                        type="button"
                                        onClickButton={() => this.onAddEventReminder(id) }
                                    />              
                                  </div>
                                </div>
                              </div>
                              <hr/>
                            </div>
                        )
                    })
                }
              </div>
            </div>
        )
    }

    render() {
        const { calendar_events, is_loading } = this.props

        return (
            <MainLayout title="Events Calendar">
              <div css={container_style}>
                <div css={add_btn_container_style}>
                  <DefaultButton
                      label="Add Event"
                      type="button"
                      light={true}
                      onClickButton={ this.onAddEvent }
                  />
                </div>
                <Global styles={global_styles} />
                {is_loading !== true && <FullCalendar
                                            plugins={[dayGridPlugin]}
                                            initialView="dayGridMonth"
                                            headerToolbar={{
                                                left: 'prev',
                                                center: 'title',
                                                right: 'next',
                                            }}
                                            height="400px"
                                            ref={this.calendarComponentRef}
                                            handleWindowResize={true}
                                            events={calendar_events}
                                            dayCellContent={this.renderDayCellContent}
                />}
                {this.renderDayEvents()}
                {this.state.modalOpen && this.renderDeleteEventModal()}
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const calendar_events = eventList.getAsCalendarEvents()
    return {
        calendar_events,
        is_loading: eventList.getAsCalendarEvents()
    }
}
export default withRouter(connect(mapStateToProps)(Events))

const container_style = css`
    width: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const add_btn_container_style = css`
    margin-bottom: 20px;
`

const global_styles = css`
    .fc-day-grid-container.fc-scroller {
    height: auto!important;
    overflow-y: auto;
    }
    .fc {
    width: 50% !important;
    }
    .fc .fc-toolbar.fc-header-toolbar {
    background: #599DC2 !important;
    height: 50px;
    margin-bottom: 0px;
    width: 100% !important;
    }
    .fc .fc-scroller-liquid-absolute {
    overflow: inherit;
    }
    .fc .fc-daygrid-day.fc-day-today {
    background: #ffffff;
    }
    .fc-col-header {
    width:100%;
    } 
    .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    border: 0;
    padding: 5px 0px;
    }
    .fc .fc-toolbar-title {
    color: #fff;
    }
    .fc .fc-button-primary {
    background: #599DC2 !important;
    border: #599DC2;
    }
    .fc-h-event .fc-event-main {
    color: #fff;
    }
    .fc-theme-standard .fc-popover {
    position: absolute !important;
    top: 100% !important;
    left: 0px !important;
    width: 100%;
    }
    .fc-event-main {
    display: flex;
    border: none;
    color: #000;
    }
    .fc-daygrid-more-link {
    display: flow-root list-item;
    bottom: 40px;
    left: 10px;
    font-size: 0px;
    }

    .fc-daygrid-day {
    &:hover {
    background: #599DC2;
    }
    }

    .fc-daygrid-day-top {
    height: 50px;
    }

    .fc-daygrid-day-number {
    display: flex;
    width: 100%;
    height: 100%;
    }

    .fc-daygrid-day-bottom {
    display: none;
    }

    .fc-daygrid-day-events {
    display: none;
    }
    
    .eventInfo_table_style tr td {
    border: 0;
    color: #000;
    padding: 5px 6px;
    }
   .fc-daygrid-day:hover {
    background: #599DC2 !important;
    }
`
const calendar_day_cell = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
`

const calendar_day_cell_outline = css`
    outline: 3px solid #599DC2;
`

const calendar_day_cell_date = css`
display: flex;
width: 100%;
    justify-content: flex-end;
    font-size: 0.8rem;
    padding-right: 0.5rem;
`

const calendar_day_cell_events = css`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 0.2rem;
`    

const calendar_day_cell_event = css`
height: 10px;
width: 10px;
border-radius: 50%;
margin-left: 0.1rem;
margin-right: 0.1rem;
`
const event_container = css`
display: flex;
flex-direction: row;
width: 100%;
`

const event_details = css`
display: flex;
flex-direction: column;
justify-content: flex-start
align-items: center;
flex: 2;
margin-left: 10px;
padding-left: 10px;
`

const event_attendance = css`
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
flex: 1;
`

const event_options = css`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
flex: 1;
`

const event_options_row = css`
display: flex;
flex-direction: row;
padding: 0.2rem;
`

const event_option_cell = css`
padding-left: 0.2rem;
padding-right: 0.2rem;
`

const event_detail_row = css`
display: flex;
flex-direction: row;
width: 100%;
`

const event_detail_name = css`
display: flex;
flex-direction: row;
width: 100%;
flex: 1;
`

const event_detail_value = css`
display: flex;
flex-direction: row;
width: 100%;
flex: 2;
overflow-x: hidden;
`

const event_attendance_column = css`
display: flex;
flex-direction: row;
width: 100%;
flex: 1;
padding: 0.2rem;
`
const event_attendance_cell = css`
padding: 0.1rem;
`
