/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import DefaultButton from './DefaultButton'

import { default_theme as theme } from '../emotion/theme'

class TableFilter extends Component {

    render() {
        const { handleSubmit, label, updateOnChange } = this.props

        return (
            <form onSubmit={ handleSubmit }>
              <div className="form-group" css={ form_container }>
                <label><strong>Filter { label }</strong></label>
                <div css={ field_container }>
                  <Field
                      autoComplete="off"
                      type="text"
                      name="any_field"
                      component={ InputField }
                      onChange={ updateOnChange }
                  />
                </div>
                { !updateOnChange &&
                  <div>
                    <DefaultButton
                        label="Apply"
                        type="submit"
                        light={ true }
                    />
                  </div>
                }
              </div>
            </form>
        )
    }
}
TableFilter = reduxForm({
    form: 'TABLE_FILTER_FORM'
})(TableFilter);

function mapStateToProps(state, props) {
    const { onSubmit, label, updateOnChange } = props
    return {
        onSubmit,
        label,
        updateOnChange,
    }
}
export default withRouter(connect(mapStateToProps)(TableFilter))

const form_container = css`
padding-top: 15px;
display:flex;
flex-direction: row;
align-items: baseline;
color: ${theme.colors.white};
`

const field_container = css`
margin-left: 15px;
margin-right: 15px;
max-width: 200px;
`
