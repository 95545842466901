import { ItemList } from '../orm'
import { ENTITY_KEY__MESSAGE, LIST_KEY__MESSAGE } from './ItemListKeyRegistry'
import { map, head, filter } from 'lodash'

class MessageList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__MESSAGE
    }

    excludeEventReminderMessages() {
        const messages = this.getVisibleObjects()
        let filtered_array = filter(messages, 
            { 
                'message_type': "message"
            }
        )
        return filtered_array
    }
}

export const messageList = new MessageList(LIST_KEY__MESSAGE)

class MessageAddFollowersList extends ItemList {
    getEntityKey() {
        return 'message/add_all_followers'
    }

    update(message_id, on_done) {
        return this.saveNewObject({message_id: message_id}, on_done)
    }
}

export const messageAddFollowersList = new MessageAddFollowersList('message_add_all_followers')

class EventReminderAddFollowersList extends ItemList {
    getEntityKey() {
        return 'message/add_all_followers'
    }

    update(message_id, event_id, on_done) {
        return this.saveNewObject({message_id: message_id, event_id: event_id}, on_done)
    }
}

export const eventReminderAddFollowersList = new EventReminderAddFollowersList('event_reminder_add_all_followers')

class MessageRemoveFollowersList extends ItemList {
    getEntityKey() {
        return 'message/remove_all_followers'
    }

    update(message_id) {
        return this.saveNewObject({message_id: message_id})
    }
}

export const messageRemoveFollowersList = new MessageRemoveFollowersList('message/remove_all_followers')

class MessageAddGroupsList extends ItemList {
    getEntityKey() {
        return 'message/add_all_groups'
    }

    update(message_id, on_done) {
        return this.saveNewObject({message_id: message_id}, on_done)
    }
}

export const messageAddGroupsList = new MessageAddGroupsList('message/add_all_groups')

class MessageRemoveGroupsList extends ItemList {
    getEntityKey() {
        return 'message/remove_all_groups'
    }

    update(message_id) {
        return this.saveNewObject({message_id: message_id})
    }
}

export const messageRemoveGroupsList = new MessageRemoveGroupsList('message/remove_all_groups')

class MessagePreviewList extends ItemList {
    getEntityKey() {
        return 'message/send'
    }

    send(message_id, on_done) {
        return this.saveNewObject({message_id: message_id}, on_done)
    }
}

export const messagePreviewList = new MessagePreviewList('message/send')

class  EventReminderPreviewList extends ItemList {
    getEntityKey() {
        return 'message/send'
    }

    send(message_id, event_id, on_done) {
        return this.saveNewObject({message_id: message_id, event_id: event_id}, on_done)
    }
}

export const  eventReminderPreviewList = new  EventReminderPreviewList('event_reminder_send')
