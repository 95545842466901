/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { reset, isPristine, submit } from 'redux-form'

import { eventReminderAddFollowersList, eventReminderPreviewList, messageList, messagePreviewList } from '../actions/message'
import { organisationList } from '../actions/organisation'
import { eventList } from '../actions/event'

import MainLayout from './MainLayout'
import MessageForm from './MessageForm'
import BusyMask from './BusyMask'
import Modal from './Modal'
import TopupInstructionModalText from './TopupInstructionsModalText'
import EventAttendenceCount from './EventAttendenceCount'

import { default_theme as theme } from '../emotion/theme'
import npo_icon from '../images/npo-connect.png'
import { messageUnassignedFollowerList } from '../actions/follower'
import { messageRecipientList } from '../actions/message_recipients'
import { messageUnassignedGroupList } from '../actions/group'

class Message extends Component {

    constructor(props) {
        super(props)
        this.state = {
            action: null,
            display_will_save_modal: false,
            display_did_save_modal: false,
            display_message_preview_modal: false,
            display_topup_instructions_modal: false,
            display_did_send_modal: false,
        }
    }
    
    componentDidMount() {
        const { dispatch, message_id, event_id } = this.props
        dispatch(organisationList.invalidateList())
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(messageList.ensureObjectLoaded(message_id))
        dispatch(eventList.ensureObjectLoaded(event_id))
        this.refresh()
    }
    
    refresh() {
        const { dispatch, message_id } = this.props
        dispatch(messageUnassignedFollowerList.fetchListIfNeeded())
        dispatch(messageUnassignedGroupList.fetchListIfNeeded())
        dispatch(messageRecipientList.fetchListIfNeeded())
        dispatch(messageList.ensureObjectLoaded(message_id))
        this.onAddAllFollowers()
    }
    
    handleSubmit = (values) => {
        const { dispatch, organisation_id } = this.props
        values.organisation_id = organisation_id
        const that = this
        const on_done = function() {
          that.showDidSaveModal()
        }
        return dispatch(messageList.saveObject(values, on_done))
    }

    handleBack = () => {
        const { is_pristine } = this.props
        if (is_pristine) {
            this.backToEvents()
        } else {
            this.setState({
                action: 'back',
            })
            this.hideWillSaveModal()
        }
    }

    handlePreview = () => {
        const { is_pristine } = this.props
        if (is_pristine) {
            this.clearAction()
          this.showMessagePreviewModal()
        } else {
            this.setState({
                action: 'preview'
            })
          this.showWillSaveModal()
        }
    }

    backToEvents = () => {
        const { dispatch, history } = this.props
        dispatch(reset('MESSAGE_FORM'))
        history.push('/events')
    }

  showWillSaveModal = () => {
    this.setState({
      display_will_save_modal: true
    })
  }

  hideWillSaveModal = () => {
    this.setState({
      display_will_save_modal: false
    })
  }
  
    renderWillSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Save Changes"
                onActionModal={ this.handleWillSave }
                actionLabel="Save"
                onCloseModal={ this.handleWillNotSave }
            >
              <div css={ modal_container }>
                <span>Save changes made to message?</span>
              </div>
            </Modal>
        )
    }

    renderEventAttendenceCount = () => {
        const { event } = this.props
        return (
            <EventAttendenceCount event={event} title={'Recipients'}/>
        )
    }

    handleWillSave = () => {
        const { dispatch } = this.props
        this.hideWillSaveModal()
        dispatch(submit('MESSAGE_FORM'))
    }

    handleWillNotSave = () => {
        const { action } = this.state
        this.hideWillSaveModal()
        if (action === 'back') {
            this.backToEvents()
        }
        else if (action === 'preview') {
            this.clearAction()
        }
        else {
          console.error('Unknown action')
        }
    }
  
  showDidSaveModal = () => {
    this.setState({
      display_did_save_modal: true
    })
  }
  
  hideDidSaveModal = () => {
    this.setState({
      display_did_save_modal: false
    })
  }

    renderDidSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Message Saved"
                onActionModal={ this.handleDidSave }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }

    handleDidSave = () => {
        const { action } = this.state
        this.hideDidSaveModal()
        if (action === 'back') {
            this.backToEvents()
        }
        else if (action === 'preview') {
            this.clearAction()
            this.showMessagePreviewModal()
        }
        else {
          console.error('Unknown action')
        }
    }

    showMessagePreviewModal = () => {
      this.setState({
        display_message_preview_modal: true
      })
    }

    hideMessagePreviewModal = () => {
      this.setState({
        display_message_preview_modal: false
      })
    }

    renderMessagePreviewModal = () => {
        const { message_funds_balance, message } = this.props
        const insufficient_funds = message_funds_balance < message.expected_cost
        return (
            <Modal
                title_icon={ npo_icon }
                title="Preview Message"
                onActionModal={ this.handleWillSend }
                actionLabel="Send"
                disable_action={ insufficient_funds }
                onCloseModal={ this.handleWillNotSend }
            >
                <div css={[modal_container, css` align-items: flex-start; padding: 20px;`,]}>
                  <label>Recipients</label>
                  <textarea
                    rows={2}
                    className="form-control"
                    css={modal_textarea}
                    readOnly={true}
                    value={message.recipients_string}
                  />
                  <br />
                  <label>Message Funds Balance</label>
                  <input
                    value={`R ${message_funds_balance}`}
                    className="form-control"
                    readOnly={true}
                  />
                  <br />
                  <label>Expected Cost</label>
                  <input
                    value={`R ${message.expected_cost}`}
                    className="form-control"
                    readOnly={true}
                  />
                  {insufficient_funds && (
                    <span css={css`color: red;`}>
                      {`Insufficient messaging funds! Please add more funds to your messaging balance. Click `}
                      <span css={fake_link} onClick={this.showTopupInstructionsModal}>
                        {`here`}
                      </span>
                      {` for more information.`}
                    </span>
                  )}
                  <br />
                  <div css={css`display: flex; justify-content: space-between; width: 100%;`}>
                    <div css={css`width: 45%;`}>
                      <label>SMS</label>
                      <input
                        value={message.notified_by_sms}
                        className="form-control"
                        readOnly={true}
                      />
                    </div>
                    <div css={css` width: 45%;`}>
                      <label>Push Notifications</label>
                      <input
                        value={message.notified_by_pn}
                        className="form-control"
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <br />
                  <label>Message Text</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    css={modal_textarea}
                    readOnly={true}
                    value={message.body}
                  />
                </div>
            </Modal>
        )
    }

    handleWillSend = () => {
        const { dispatch, message_id, event_id } = this.props
      this.hideMessagePreviewModal()
        const that = this
        const on_done = function() {
            that.showDidSendModal()
        }
        dispatch(eventReminderPreviewList.send(message_id, event_id, on_done))
    }

    handleWillNotSend = () => {
      this.hideMessagePreviewModal()
    }

    showTopupInstructionsModal = () => {
      this.setState({
        display_topup_instructions_modal: true
      })
    }

    hideTopupInstructionsModal = () => {
      this.setState({
        display_topup_instructions_modal: false
      })
    }

  renderTopupInstructionsModal = () => {
      const { organisation } = this.props
      return (
          <Modal
              title_icon={ npo_icon }
              title="Notifications Fund"
              onActionModal={ this.hideTopupInstructionsModal }
              actionLabel="Close"
              modal_size="large"
              hide_secondary_button={ true }
          >
              <TopupInstructionModalText organisation={organisation}/>
          </Modal>
      )
  }

  showDidSendModal = () => {
    this.setState({
      display_did_send_modal: true
    })
  }

  hideDidSendModal = () => {
    this.setState({
      display_did_send_modal: false
    })
  }

    renderDidSendModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Message added to queue"
                onActionModal={ this.handleDidSend }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }

    handleDidSend = () => {
        this.hideDidSendModal()
        this.backToEvents()
    }

    clearAction = () => {
        this.setState({
            action: null
        })
    }

    onAddAllFollowers = () => {
        const { dispatch, message_id, event_id } = this.props

        const on_done = function() {
            dispatch(messageUnassignedFollowerList.updateListFilter({page: 1}))
            dispatch(messageRecipientList.updateListFilter({page: 1}))
            dispatch(messageList.invalidateObject(message_id))
        }
        return dispatch(eventReminderAddFollowersList.update(message_id, event_id, on_done))
    }
    
    render() {
        const { message_id, is_busy } = this.props
        const {
            display_will_save_modal,
            display_did_save_modal,
            display_message_preview_modal,
            display_topup_instructions_modal,
            display_did_send_modal,
        } = this.state
        let title = 'Add Message'
        if (message_id) {
            title = 'Edit Message'
        }
        return (
            <MainLayout title={ title }>
              { is_busy && <BusyMask /> }
              { this.renderEventAttendenceCount()}
              <MessageForm
                  message_id={ message_id }
                  onSubmit={ this.handleSubmit }
                  onBack={ this.handleBack }
                  onPreview={ this.handlePreview }
                  onSubmitFail={ this.clearAction }
              />
              { display_will_save_modal && this.renderWillSaveModal() }
              { display_did_save_modal && this.renderDidSaveModal() }
              { display_message_preview_modal && this.renderMessagePreviewModal() }
              { display_topup_instructions_modal && this.renderTopupInstructionsModal() }              
              { display_did_send_modal && this.renderDidSendModal() }
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const queryParams = new URLSearchParams(window.location.search);
    const event_id = queryParams.get('event_id');
    const message_id = get(props, ["match", "params", "message_id"], null)
    const organisation =  head(organisationList.getObjects()) || {}
    const event = eventList.getObject(event_id) || {}
    const message = messageList.getObject(message_id) || {}
    return {
        organisation_id: organisation.id,
        organisation,
        message_funds_balance: organisation.parsed_message_funds || 0,
        message_id,
        message,
        event_id,
        event,
        is_pristine: isPristine('MESSAGE_FORM')(state),
        is_busy: messageList.getIsSavingObject() || 
                 organisationList.isLoading()
    }
}
export default connect(mapStateToProps)(Message)

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`

const modal_textarea = css`
width: 100%;
`

const fake_link = css`
color:${theme.colors.primary};
&:hover{
cursor:pointer;
text-decoration:underline;
}`