import { ItemList } from '../orm'
import { ENTITY_KEY__EVENT, LIST_KEY__EVENT } from './ItemListKeyRegistry'
import { map, head, filter } from 'lodash'
import moment from 'moment'
import { useSelector, useDispatch } from "react-redux";

class EventList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__EVENT
    }

    getAsCalendarEvents() {
        const events = this.getVisibleObjects()
        const result = map(events,(event) => {
          return { id: event.id,
                   title: event.name,
                   description: event.description,
                   address: event.address,
                   color: event.mobile_event_colour_hex,
                   backgroundColor: "transparent",
                   textColor: "#000",
                   start: event.event_start, 
                   end: event.event_end, 
                   allDay: event.is_all_day, 
                   confirmed: event.num_followers_confirmed, 
                   maybe: event.num_followers_maybe,
                   rejected: event.num_followers_rejected,
                   document_url: event.event_document_url,
                   document_name: event.event_document_name
                }
        })
        return result
    }
  
    getDayEvents(date) {
      return filter(this.getAsCalendarEvents(), (event) => {
            return moment(event.start).isSame(moment(date), 'date')
      })
    }
}
  
export const eventList = new EventList(LIST_KEY__EVENT)
