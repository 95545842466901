/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, has } from 'lodash'
import { reset, isPristine, submit } from 'redux-form'
import { groupList } from '../actions/group'
import { organisationList } from '../actions/organisation'

import MainLayout from './MainLayout'
import GroupForm from './GroupForm'
import GroupUsers from './GroupUsers'
import BusyMask from './BusyMask'
import Modal from './Modal'

import npo_icon from '../images/npo-connect.png'

class Group extends Component {

    constructor(props) {
        super(props)
        this.state = {
            action: null,
            display_will_save_modal: false,
            display_did_save_modal: false,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.invalidateList())
        dispatch(organisationList.fetchListIfNeeded())
    }
    
    handleSubmit = (values) => {
        const { dispatch, history } = this.props
        let on_done
        const that = this
        if (!has(values, 'id')) {
            values.isNewInstance = true
            on_done = function(json) {
                that.toggleDidSaveModal()
                history.push('/group/' + json.id)
            }
        } else {
            on_done = function() {
                that.toggleDidSaveModal()
            }
        }
        return dispatch(groupList.saveObject(values, on_done))
    }

    handleBack = () => {
        const { is_pristine } = this.props
        if (is_pristine) {
            this.backToGroups()
        } else {
            this.setState({
                action: 'back',
            })
            this.toggleWillSaveModal()
        }
    }

    backToGroups = () => {
        const { dispatch, history } = this.props
        dispatch(reset('GROUP_FORM'))
        history.push('/groups')
    }

    toggleWillSaveModal = () => {
        const { display_will_save_modal } = this.state
        this.setState({
            display_will_save_modal: !display_will_save_modal,
        })
    }

    renderWillSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Save Changes"
                onActionModal={ this.handleWillSave }
                actionLabel="Save"
                onCloseModal={ this.handleWillNotSave }
            >
              <div css={ modal_container }>
                <span>Save changes made to group?</span>
              </div>
            </Modal>
        )
    }

    handleWillSave = () => {
        const { dispatch } = this.props
        this.toggleWillSaveModal()
        dispatch(submit('GROUP_FORM'))
    }

    handleWillNotSave = () => {
        this.toggleWillSaveModal()
        this.backToGroups()
    }

    toggleDidSaveModal = () => {
        const { display_did_save_modal } = this.state
        this.setState({
            display_did_save_modal: !display_did_save_modal
        })
    }

    renderDidSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Group Saved"
                onActionModal={ this.handleDidSave }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }

    handleDidSave = () => {
        const { action } = this.state
        this.toggleDidSaveModal()
        if (action === 'back') {
            this.backToGroups()
        }
    }

    clearAction = () => {
        this.setState({
            action: null
        })
    }

    render() {
        const { group_id, is_busy } = this.props
        const { display_will_save_modal, display_did_save_modal } = this.state
        let title = 'Add Group'
        if (group_id) {
            title = 'Edit Group'
        }
        return (
            <MainLayout title={ title }>
              { is_busy && <BusyMask /> }
              <GroupForm
                  onSubmit={ this.handleSubmit }
                  group_id={ group_id }
                  onBack={ this.handleBack }
                  onSubmitFail={ this.clearAction }
              />
              { group_id &&
                <GroupUsers
                    group_id={ group_id }
                />
              }
              { display_will_save_modal && this.renderWillSaveModal() }
              { display_did_save_modal && this.renderDidSaveModal() }
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const group_id = get(props, ["match", "params", "group_id"], null)
    const organisation =  head(organisationList.getObjects()) || {}
    return {
        organisation_id: organisation.id,
        group_id,
        is_pristine: isPristine('GROUP_FORM')(state),
        is_busy: groupList.getIsSavingObject() || organisationList.isLoading()
    }
}
export default connect(mapStateToProps)(Group)

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`
