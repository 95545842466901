/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'

class ToggleButton extends Component {

    onClick = (event) => {
        const { onChange, value } = this.props
        if(onChange) {
            onChange(!value)
        }
    }
   
    render() {
        const { value, on_label, off_label, light } = this.props
       
        return (
            <div onClick={this.onClick} css={ container }>
              <div className="toggle-button__label" css={ [light ? css`color: white;` : css`color: ${theme.colors.secondary};`, label_container] }>{ off_label }</div>
              { value ?
                <div className="toggle-button__input" css={ [light ? css`&:after{background: white;}` : css`&:after{background: ${theme.colors.primary};}`, enabled] }>
                </div>
                :
                <div className="toggle-button__input" css={ disabled }>
                </div>
              }
              <div className="toggle-button__label" css={ [light ? css`color: white;` : css`color: ${theme.colors.secondary};`, label_container] }>{ on_label }</div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {}
}

export default connect(mapStateToProps)(ToggleButton)

const container = css`
height: 45px;
display: flex;
align-items: center;
padding: 20px;`

const enabled = css`
position: relative;
display: inline-block;
height: 15px;
width: 33px;
border-radius: 150px;
cursor: pointer;
transition: all 0.3s ease;
vertical-align: middle;
margin: 0 18px;
background: #b0bec5;
&:after {
    position: absolute;
    left: -2px;
    top: -2px;
    display: block;
    width: 19px;
    height: 19px;
    border-radius: 150px;
    box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
    content: '';
    transition: all 0.3s ease;
    left: 15px;
}
`

const disabled = css`
position: relative;
display: inline-block;
height: 15px;
width: 33px;
background: #b0bec5;
border-radius: 150px;
cursor: pointer;
transition: all 0.3s ease;
vertical-align: middle;
margin: 0 18px;
&:after {
    position: absolute;
    left: -2px;
    top: -2px;
    display: block;
    width: 19px;
    height: 19px;
    border-radius: 100px;
    background: #CECECF;
    box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
    content: '';
    transition: all 0.3s ease;

}
`

const label_container = css`

font-weight: 700;
`
