/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import { reset } from 'redux-form'
import { head } from 'lodash'

import { sendTokenEmail, updateUser } from '../actions/auth'
import { userList } from '../actions/user'

import MainLayout from './MainLayout'
import ProfileForm from './ProfileForm'
import BusyMask from './BusyMask'

class Profile extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(userList.invalidateList())
        dispatch(userList.fetchListIfNeeded())
    }
    
    handleSubmit = (values) => {
        const { dispatch, history, user } = this.props
        let on_done = function() {}
        if (values.email !== user.email) {
            const new_email = values.email
            values.email = user.email
            on_done = function() {
                history.push('/email/' + new_email)
            }
        } else {
            on_done = function() {
                history.push('/dashboard')
            }
        }
        return dispatch(updateUser(values, on_done))
    }

    onRequestToken = () => {
        const { dispatch, user } = this.props
        dispatch(sendTokenEmail(user))
    }
    
    onCancelProfile = () => {
        const { dispatch, history } = this.props
        dispatch(reset('PROFILE_FORM'))
        history.push('/dashboard')
    }
    
    render() {
        const { user, is_loading } = this.props
        return (
            <MainLayout title='Profile' layout="single">
              { is_loading && <BusyMask /> }
              <ProfileForm
                  onSubmit={ this.handleSubmit }
                  onRequestToken ={ this.onRequestToken }
                  onCancelProfile={ this.onCancelProfile }
                  user_id={ user.id }
              />
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user = head(userList.getVisibleObjects()) || {}
    return {
        user,
        is_loading: userList.isLoading(),
    }
}
export default connect(mapStateToProps)(Profile)

