import { ItemList } from '../orm'
import { ENTITY_KEY__ARTICLE, LIST_KEY__ARTICLE } from './ItemListKeyRegistry'

class ArticleList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__ARTICLE
    }
}

export const articleList = new ArticleList(LIST_KEY__ARTICLE)
