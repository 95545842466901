/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import PrimaryButton from './PrimaryButton'

import { default_theme as theme } from '../emotion/theme'

class LoginForm extends Component {

    render() {
        const { handleSubmit, onRequestToken, error, tokenError } = this.props

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
                { tokenError && <p>{ tokenError }</p>}
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Email Address</label>
                <div>
                  <Field
                      autoFocus
                      autoComplete="off"
                      type="text"
                      name="email"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Password or Token</label>
                <div>
                  <Field
                      type="password"
                      name="password"
                      component={ InputField }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <PrimaryButton
                      label="Forgot Password"
                      type="button"
                      onClickButton={ onRequestToken }
                  ></PrimaryButton>
                </div>
                <div>
                  <PrimaryButton
                      label="Login"
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
LoginForm = reduxForm({
    form: 'LOGIN_FORM'
})(LoginForm);
function mapStateToProps(state, props) {
    const { onSubmit, onRequestToken, tokenError } = props
    return {
        onSubmit,
        onRequestToken,
        tokenError
    }
}
export default withRouter(connect(mapStateToProps)(LoginForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
`

const field_container = css`
width: 80%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};

`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 80%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
