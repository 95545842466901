import { map } from 'lodash'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'

export function setErrorMessage(error) {
    return {
        type: SET_ERROR_MESSAGE,
        error_message: error
    }
}

export function clearErrorMessage() {
    return setErrorMessage(null)
}

export function getSubmissionErrors(json) {
    const submission_errors = {}
    map(json, (error, field_name) => {
        if (field_name !== 'non_field_errors') {
            submission_errors[field_name] = error
        } else {
            submission_errors['_error'] = error
        }
    })
    return submission_errors
}
