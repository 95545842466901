import { ItemList } from '../orm'
import { ENTITY_KEY__GROUP, LIST_KEY__GROUP } from './ItemListKeyRegistry'

class GroupList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__GROUP
    }
}

export const groupList = new GroupList(LIST_KEY__GROUP)
export const messageAssignedGroupList = new GroupList(LIST_KEY__GROUP+"_message_assigned")
export const messageUnassignedGroupList = new GroupList(LIST_KEY__GROUP+"_message_unassigned")

export const invalidateGroupLists = (dispatch) => {
    dispatch(groupList.invalidateList())
    dispatch(messageAssignedGroupList.invalidateList())
    dispatch(messageUnassignedGroupList.invalidateList())
}

class GroupAddFollowersList extends ItemList {
    getEntityKey() {
        return 'group/add_all_followers'
    }

    update(group_id, on_done) {
        return this.saveNewObject({group_id: group_id}, on_done)
    }
}

export const groupAddFollowersList = new GroupAddFollowersList('group/add_all_followers')

class GroupRemoveFollowersList extends ItemList {
    getEntityKey() {
        return 'group/remove_all_followers'
    }

    update(group_id, on_done) {
        return this.saveNewObject({group_id: group_id}, on_done)
    }
}

export const groupRemoveFollowersList = new GroupRemoveFollowersList('group/remove_all_followers')


export const invalidateFollowerLists = (dispatch) => {
    dispatch(groupList.invalidateList())
    dispatch(messageAssignedGroupList.invalidateList())
    dispatch(messageUnassignedGroupList.invalidateList())
}
