/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { remove, has } from 'lodash'

import { groupList, groupAddFollowersList, groupRemoveFollowersList } from '../actions/group'
import {
    groupAssignedFollowerList,
    groupUnassignedFollowerList,
    invalidateFollowerLists
} from '../actions/follower'

import CommonTable from './CommonTable'
import Pagination from './Pagination'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import TableFilter from './TableFilter'
import DefaultButton from './DefaultButton'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import left_dark_icon from '../images/left_dark.png'
import right_dark_icon from '../images/right_dark.png'

const ASSIGNED_FILTERS = { 'page': '1', 'order_by': 'last_name' }
const UNASSIGNED_FILTERS = { 'page': '1', 'order_by': 'last_name' }

class GroupUsers extends Component {
    
    componentDidMount() {
        const { dispatch, group_id } = this.props
        dispatch(groupList.ensureObjectLoaded(group_id))
        dispatch(groupAssignedFollowerList.updateListFilter(
            Object.assign(ASSIGNED_FILTERS, {in_group: group_id}))
        )
        dispatch(groupUnassignedFollowerList.updateListFilter(
            Object.assign(UNASSIGNED_FILTERS, {not_in_group: group_id}))
        )
        invalidateFollowerLists(dispatch)
    }

    componentDidUpdate(prevProps) {
        this.refresh()
    }

    refresh() {
        const { dispatch } = this.props
        dispatch(groupAssignedFollowerList.fetchListIfNeeded())
        dispatch(groupUnassignedFollowerList.fetchListIfNeeded())
    }

    getUnassignedCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'relationship':
                return item.relationship
            case 'action':
                return <img src={ right_dark_icon } alt="" css={ css`width:25px;` } />
            default:
                return <span>"No idea"</span>
        }
    }

    getAssignedCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'relationship':
                return item.relationship
            case 'action':
                return <img src={ left_dark_icon } alt="" css={ css`width:25px;` } />
                default:
                return <span>"No idea"</span>
        }
    }
    
    addFollower = (follower_id) => {
        const { dispatch, group } = this.props
        group.followers.push(follower_id)
        const on_done = function() {
            invalidateFollowerLists(dispatch)
        }
        dispatch(groupList.saveObject(group, on_done))
    }

    removeFollower = (follower_id) => {
        const { dispatch, group } = this.props
        const followers = remove(group.followers, (id) => {return id !== follower_id})
        group.followers = followers
        const on_done = function() {
            invalidateFollowerLists(dispatch)
        }
        return dispatch(groupList.saveObject(group, on_done))
    }

    filterUnassigned = (e) => {
        const { dispatch } = this.props
        dispatch(groupUnassignedFollowerList.updateListFilter({'any_field': e.target.value, 'page': 1}))

    }

    filterAssigned = (e) => {
        const { dispatch } = this.props
        dispatch(groupAssignedFollowerList.updateListFilter({'any_field': e.target.value, 'page': 1}))
    }

    sortUnassignedOnHeader = (key) => {
        const { dispatch, unassigned_follower_filter } = this.props
        if (key !== 'action') {
            let sort_by = key
            if (has(unassigned_follower_filter, 'order_by') &&
                unassigned_follower_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(groupUnassignedFollowerList.updateListFilter({'order_by':sort_by}))
        }
    }

    sortAssignedOnHeader = (key) => {
        const { dispatch, assigned_follower_filter } = this.props
        if (key !== 'action') {
            let sort_by = key
            if (has(assigned_follower_filter, 'order_by') &&
                assigned_follower_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(groupAssignedFollowerList.updateListFilter({'order_by':sort_by}))
        }
    }

    onAddAllFollowers = () => {
        const { dispatch, group_id } = this.props
        const on_done = function() {
            invalidateFollowerLists(dispatch)
            dispatch(groupList.invalidateList())
            dispatch(groupList.fetchListIfNeeded())
        }
        return dispatch(groupAddFollowersList.update(group_id, on_done))
    }

    onRemoveAllFollowers = () => {
        const { dispatch, group_id } = this.props
        const on_done = function() {
            invalidateFollowerLists(dispatch)
            dispatch(groupList.invalidateList())
            dispatch(groupList.fetchListIfNeeded())
        }
        return dispatch(groupRemoveFollowersList.update(group_id, on_done))
    }
    
    render() {
        const { is_assigned_followers_loading,
                is_unassigned_followers_loading,
                assigned_followers,
                unassigned_followers,
                assigned_follower_headers,
                unassigned_follower_headers,
                is_busy,
        } = this.props
        return (
            <div css={ form_layout }>
              { is_busy && <BusyMask /> }
              <h2 css={ header }>Select Recipients</h2>
              <div className="form-group" css={ tables_container }>
                <div css={ row }>
                  <div css={ table_container }>
                    <label css={ label }>Contacts</label>
                    <TableHeader custom_height="50px" >
                      <TableFilter
                          updateOnChange={ this.filterUnassigned }
                          form="UNASSIGNED_TABLE_FILTER_FORM"
                      />
                      <DefaultButton
                          label="Add All"
                          type="button"
                          light={ true }
                          onClickButton={ this.onAddAllFollowers }
                      />
                    </TableHeader>
                    <CommonTable
                        is_loading={ is_unassigned_followers_loading }
                        empty_message="There are no contacts."
                        headers={ unassigned_follower_headers }
                        items={ unassigned_followers }
                        getCellValue={ this.getUnassignedCellValue }
                        onRowClick={ this.addFollower }
                        sortOnHeader={ this.sortUnassignedOnHeader }
                        custom_row_height="small"
                    />
                    <TableFooter custom_height="50px" >
                      <div css={ pagination_container }>
                        <Pagination
                            listKey={ groupUnassignedFollowerList.listKey }
                            filters={ UNASSIGNED_FILTERS }
                        />
                      </div>
                    </TableFooter>
                  </div>
                  <div css={ table_container }>
                    <label css={ label }>Contacts in Group</label>
                    <TableHeader custom_height="50px" >
                      <TableFilter
                          updateOnChange={ this.filterAssigned }
                          form="ASSIGNED_TABLE_FILTER_FORM"
                      />
                      <DefaultButton
                          label="Remove All"
                          type="button"
                          light={ true }
                          onClickButton={ this.onRemoveAllFollowers }
                      />
                    </TableHeader>
                    <CommonTable
                        is_loading={ is_assigned_followers_loading }
                        empty_message="No users in group."
                        headers={ assigned_follower_headers }
                        items={ assigned_followers }
                        getCellValue={ this.getAssignedCellValue }
                        onRowClick={ this.removeFollower }
                        sortOnHeader={ this.sortAssignedOnHeader }
                        custom_row_height="small"
                    />
                    <TableFooter custom_height="50px" >
                      <div css={ pagination_container }>
                        <Pagination
                            listKey={ groupAssignedFollowerList.listKey }
                            filters={ ASSIGNED_FILTERS }
                        />
                      </div>
                    </TableFooter>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { group_id } = props
    const group = groupList.getObject(group_id) || {}
    const assigned_followers = groupAssignedFollowerList.getVisibleObjects()
    const unassigned_followers = groupUnassignedFollowerList.getVisibleObjects()

    return {
        group,
        assigned_followers,
        unassigned_followers,
        assigned_follower_filter: groupAssignedFollowerList.getFilter(),
        unassigned_follower_filter: groupUnassignedFollowerList.getFilter(),
        unassigned_follower_headers: {
            last_name: { name: 'Last name', column_size: 4 },
            first_name: { name: 'First name', column_size: 4 },
            relationship: { name: 'Relationship', column_size: 6 },
            action: { name: '', column_size: 1 },
        },
        assigned_follower_headers: {
            action: { name: '', column_size: 1 },
            last_name: { name: 'Last name', column_size: 4 },
            first_name: { name: 'First name', column_size: 4 },
            relationship: { name: 'Relationship', column_size: 6 },
        },
        is_assigned_followers_loading: groupAssignedFollowerList.isLoading(),
        is_unassigned_followers_loading: groupUnassignedFollowerList.isLoading(),
        is_group_loading: groupList.isLoading(),
        is_busy: groupAssignedFollowerList.isLoading() ||
                 groupUnassignedFollowerList.isLoading() ||
                 groupList.isLoading() ||
                 groupList.getIsSavingObject(),
    }
}
export default withRouter(connect(mapStateToProps)(GroupUsers))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
height: 670px;
`

const tables_container = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
height: 100%;
`

const row = css`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
`

const table_container = css`
display: flex;
flex-direction: column;
min-width: 45%;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const header = css`
width: 100%;
padding-bottom: 20px;
`
