/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has } from 'lodash'

import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import PrimaryButton from './PrimaryButton'
import Pagination from './Pagination'
import DefaultButton from './DefaultButton'
import TableFilter from './TableFilter'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import FileUploader from './FileUploader'
import Modal from './Modal'
import ToggleButton from './ToggleButton'
import ToolTip from './ToolTip'

import { followerList } from '../actions/follower'
import { dataFileList } from '../actions/datafile'

import npo_icon from '../images/npo-connect.png'

const FILTERS = { 'page': '1', 'enabled': true }

class Users extends Component {

    constructor(props) {
        super(props)
        this.state = {
            displayImportModal: false,
            displaySuccessModal: false,
            displayFailModal: false,
            datafile: {
                id: null,
                name: null
            },
            response: {
                error: null,
                users_imported: null,
                tip: null,
            },
            enabled: true,
        }
    }
    
    componentDidMount() {
        this.refreshUsers()
    }

    componentDidUpdate(old_props) {
        const { dispatch } = this.props
        dispatch(followerList.fetchListIfNeeded())
    }

    onSuccess = (file, response) => {
        this.setState({
            datafile: {
                id: response.datafileinfo.id,
                name: /[^/]*$/.exec(response.datafileinfo.filename)[0]}
        })
        this.onImportUsers()
    }

    onFailure = () => {
        this.setState({
            response: {
                error: 'Failed to upload file',
                users_imported: '0',
                tip: 'Ensure that the file has one of these extensions: .xlsx, .xls, .csv'
            }
        })
        this.toggleImportFail()
    }

    onImportUsers = () => {
        this.toggleImportUsers()
    }

    toggleImportUsers = () => {
        const { displayImportModal } = this.state
        this.setState({displayImportModal: !displayImportModal})
    }

    handleImportUsers = () => {
        const { dispatch } = this.props
        const { datafile } = this.state
        const that = this
        const on_success = function(response) {
            that.setState({
                response: {
                    error: null,
                    users_imported: response.users_imported,
                    tip: null,
                }
            })
            that.toggleImportUsers()
            that.toggleImportSuccess()
            that.setState({datafile: {id: null, name: null}})
        }
        const on_fail = function(response) {
            that.setState({
                response: {
                    error: response.error,
                    users_imported: response.users_imported,
                    tip: response.tip,
                }
            })
            that.toggleImportUsers()
            that.toggleImportFail()
            that.setState({datafile: {id: null, name: null}})
        }
        dispatch(dataFileList.processFile(datafile.id, on_success, on_fail))
    }

    renderImportModal = () => {
        const { datafile } = this.state
        return (
            <Modal
                title_icon={ npo_icon }
                title="Are you sure you want to import this file?"
                onCloseModal={ this.toggleImportUsers }
                onActionModal={ this.handleImportUsers }
                actionLabel="Import" >
              <div css={ modal_container }>
                <strong>{ datafile.name }</strong>
              </div>
            </Modal>
        )
    }

    onImportSuccess = () => {
        this.toggleImportSuccess()
    }

    toggleImportSuccess = () => {
        const { displaySuccessModal } = this.state
        this.setState({displaySuccessModal: !displaySuccessModal})
    }

    handleSuccessModal = () => {
        this.setState({
            response: {
                error: null,
                users_imported: null,
                tip: null,
            }
        })
        this.toggleImportSuccess()
        this.refreshUsers()
    }

    renderSuccessModal = () => {
        const { response } = this.state
        return (
            <Modal
                title_icon={ npo_icon }
                title="Import Successful"
                onActionModal={ this.handleSuccessModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
              <div css={ modal_container }>
                { parseInt(response.users_imported) === 1 ?
                  <span><strong>{ response.users_imported }</strong> contact was imported/updated successfully.</span>
                  :
                  <span><strong>{ response.users_imported }</strong> contacts were imported/updated successfully.</span>
                }
                <span><br/>Tip: Import will update existing contacts.</span>
              </div>
            </Modal>
        )
    }

    onImportFail = () => {
        this.toggleImportFail()
    }

    toggleImportFail = () => {
        const { displayFailModal } = this.state
        this.setState({displayFailModal: !displayFailModal})
    }

    handleFailModal = () => {
        this.setState({
            response: {
                error: null,
                users_imported: null,
                tip: null,
            }
        })
        this.toggleImportFail()
    }

    renderFailModal = () => {
        const { response } = this.state
        return (
            <Modal
                title_icon={ npo_icon }
                title="Import Failed"
                onActionModal={ this.handleFailModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
              <div css={ modal_container }>
                <span>
                  <strong>No</strong> contacts were imported.<br/><br/>
                  The following <strong>error</strong> occured: { response.error }
                  { response.tip &&
                    <span><br/><br/>
                      <strong>Tip:</strong> { response.tip }
                    </span>
                  }
                </span>
              </div>
            </Modal>
        )
    }

    onEditUser = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/user/'+item.id,
        })
    }

    refreshUsers = () => {
        const { dispatch } = this.props
        dispatch(followerList.invalidateList())
        dispatch(followerList.updateListFilter(FILTERS))
        dispatch(followerList.fetchListIfNeeded())
    }

    handleSubmit = (values) => {
        const { dispatch } = this.props
        dispatch(followerList.invalidateList())
        dispatch(followerList.updateListFilter(Object.assign(FILTERS, {'any_field':values.any_field})))
    }

    onAddUser = () => {
        const { history } = this.props
        history.push('/user')
    }

    sortOnHeader = (key) => {
        const { dispatch, follower_filter } = this.props
        if (key !== 'action') {
            let sort_by = key
            if (has(follower_filter, 'order_by') && follower_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(followerList.updateListFilter({'order_by': sort_by}))
        }
    }

    onChangeToggle = (value) => {
        const { dispatch } = this.props
        this.setState({enabled: value})
        dispatch(followerList.updateListFilter({'enabled': value}))
    }
    
    getUserCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'relationship':
                return item.relationship
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'action':
                return (
                <PrimaryButton onClickButton={(evt) => this.onEditUser(evt, item)}
                               label="Edit">
                </PrimaryButton>
                )
                
            default:
                return <span>"No idea"</span>
        }
    }

    render() {
        const {
            follower_headers,
            followers,
            is_loading,
            upload_relative_url,
            follower_filter,
        } = this.props
        const { displayImportModal, displaySuccessModal, displayFailModal, enabled } = this.state
        
        return (
            <MainLayout title="Contacts">
              <div css={ container }>
                <TableHeader>
                  <div css={ filter_container }>
                    <TableFilter
                        onSubmit={ this.handleSubmit }
                    />
                    <ToggleButton
                        value={ enabled }
                        onChange={ this.onChangeToggle }
                        on_label="Show Only Enabled"
                        off_label="Show All"
                        light={ true }
                    />
                  </div>
                  <div css={ add_container }>
                    <FileUploader
                        upload_relative_url={ upload_relative_url }
                        upload_params={{}}
                        onSuccess={ this.onSuccess }
                        onFailure={ this.onFailure }
                        label="Bulk Import Contacts"
                        file_types='.xls, .xlsx, .csv'
                        class_name="btn btn-light"
                    />
                    <ToolTip light={ true }>
                      {'You can bulk import your contacts using an Excel (.xls) or comma-separated-values (.csv) file.'}
                      <br/>
                      <br/>
                      {'Your file must have a first line with the following headings: Firstname, Surname, Mobilenumber, Emailaddress, Relationship'}
                      <br/>
                      <br/>
                      {'Each line of data in your import file must contain at least a First name, Surname and Mobile number otherwise the file will not import.'}
                    </ToolTip>
                    <div css={ css`padding-left:15px;` }>
                      <DefaultButton
                          label="Add Contact"
                          type="button"
                          light={ true }
                          onClickButton={ this.onAddUser }
                      />
                    </div>
                  </div>
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no contacts."
                    headers={ follower_headers }
                    items={ followers }
                    getCellValue={ this.getUserCellValue }
                    sortOnHeader={ this.sortOnHeader }
                />
                <TableFooter>
                  <div css={ pagination_container }>
                    <Pagination listKey={followerList.listKey} filters={ follower_filter } />
                  </div>
                </TableFooter>
              </div>
              { displayImportModal && this.renderImportModal() }
              { displaySuccessModal && this.renderSuccessModal() }
              { displayFailModal && this.renderFailModal() }
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const followers = followerList.getVisibleObjects()
    return {
        followers,
        follower_headers: {
            last_name: { name: 'Last name', column_size: 2},
            first_name: { name: 'First name', column_size: 2},
            relationship: { name: 'Relationship', column_size: 2},
            phone_number: { name: 'Phone Number', column_size: 2},
            email: { name: 'Email Address', column_size: 2},
            action: { name: 'Options', column_size: 1 }
        },
        is_loading: followerList.isLoading(),
        upload_relative_url: 'datafileinfo/',
        follower_filter: followerList.getFilter(),
    }
}
export default withRouter(connect(mapStateToProps)(Users))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const add_container = css`
display: flex;
align-items: center;
`

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
text-align: center;
`

const filter_container = css`
display: flex;
align-items: center;
`
