/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'
import { isEmpty } from 'lodash'

import { followerList } from '../actions/follower'

import InputField from './InputField'
import MultiDropdown from './MultiDropdown'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'
import FormToggleButton from './FormToggleButton'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined
const tel = value =>
    value && !(/^\d{7,15}$/).test(value.replace(/[\s()+\-\.]|ext/gi, '')) ?
    'Invalid Phone Number' : undefined
const maxLength = max => value => 
    value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength250 = maxLength(250)

class UserForm extends Component {
    
    componentDidMount() {
        const { dispatch, follower } = this.props
        if (follower) {
            dispatch(followerList.ensureObjectLoaded(follower.id))
        }
    }

    render() {
        const { handleSubmit, onCancelUser, error, is_busy } = this.props

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              { is_busy && <BusyMask /> }
              <div className="form-group" css={ field_container }>
                <label css={ label }>First Name <span css={ asterisk }>*</span></label>
                <div>
                  <Field
                      type="text"
                      name="first_name"
                      component={ InputField }
                      validate={[required]}
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Surname <span css={ asterisk }>*</span></label>
                <div>
                  <Field
                      type="text"
                      name="last_name"
                      component={ InputField }
                      validate={[required]}
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Phone number <span css={ asterisk }>*</span></label>
                <div>
                  <Field
                      type="text"
                      name="phone_number"
                      component={ InputField }
                      validate={[required, tel]}
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Email Address</label>
                <div>
                  <Field
                      type="text"
                      name="email"
                      component={ InputField }
                      validate={[email]}
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Relationship</label>
                <div>
                  <Field
                      type="text"
                      name="relationship"
                      component={ InputField }
                      validate={[maxLength250]}
                      maxLength={ 250 }
                      placeholder="e.g. Donor, Contact, Volunteer etc."
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Groups</label>
                <div>
                  <Field
                      name="groups"
                      component={ MultiDropdown }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <span css={ notice }>* Mandatory fields</span>
              </div>
              <div className="form-group" css={ fields_container }>
                <div className="form-group" css={ field_container }>
                  <label css={ label }>Notifications</label>
                  <div>
                    <Field
                        name="notifications"
                        on_label="Enabled"
                        off_label="Disabled"
                        component={ FormToggleButton }
                    />
                  </div>
                </div>
                <div className="form-group" css={ [field_container, css`width:200px;`] }>
                  <label css={ label }>Notification Method</label>
                  <div>
                    <Field
                        name="notification_method"
                        on_label="Push Notification"
                        off_label="Text Message"
                        component={ FormToggleButton }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Contact Status</label>
                <div>
                  <Field
                      name="enabled"
                      on_label="Enabled"
                      off_label="Disabled"
                      component={ FormToggleButton }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Cancel"
                      type="button"
                      onClickButton={ onCancelUser }
                  />
                </div>
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div>
                  <PrimaryButton
                      label="Save" 
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
UserForm = reduxForm({
    form: 'USER_FORM'
})(UserForm);
function mapStateToProps(state, props) {
    const { onSubmit, follower_id, onCancelUser } = props
    const follower = followerList.getObject(follower_id) || {}
    let initialValues = {}
    if (isEmpty(follower)) {
        initialValues = Object.assign({notifications: true,
                                       notification_method: false,
                                       enabled: true})
    } else {
        initialValues = Object.assign({},
                                      follower,
                                      {notification_method: follower.notification_method === 'push_notification' ?
                                                            true : false})
    }
    return {
        onSubmit,
        onCancelUser,
        follower,
        initialValues: initialValues,
        enableReinitialize: true,
        is_busy: followerList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(UserForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const fields_container = css`
display: flex;
width: 100%;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const asterisk = css`
color: red;
`
const notice = css`
color: red;
font-size: 0.8rem;
`
