/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { has, capitalize, find } from 'lodash'

import { messageUnassignedGroupList } from '../actions/group'
import { messageUnassignedFollowerList } from '../actions/follower'
import {
    messageList,
    messageAddFollowersList,
    messageRemoveFollowersList,
    messageAddGroupsList,
    messageRemoveGroupsList,
} from '../actions/message'
import { messageRecipientList } from '../actions/message_recipients'

import CommonTable from './CommonTable'
import Pagination from './Pagination'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import TableFilter from './TableFilter'
import DefaultButton from './DefaultButton'
import BusyMask from './BusyMask'
import ToggleButton from './ToggleButton'

import { default_theme as theme } from '../emotion/theme'
import left_dark_icon from '../images/left_dark.png'
import right_dark_icon from '../images/right_dark.png'

const UNASSIGNED_GROUP_FILTERS = { 'page': '1', 'order_by': 'name' }
const UNASSIGNED_FOLLOWER_FILTERS = { 'page': '1', 'order_by': 'last_name' }
const RECIPIENT_FILTERS = { 'page': '1', 'order_by': 'name' }

class MessageRecipients extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show_groups: false,
        }
    }

    componentDidMount() {
        const { dispatch, message_id } = this.props
        dispatch(messageList.ensureObjectLoaded(message_id))
        dispatch(messageUnassignedFollowerList.updateListFilter(
            Object.assign(UNASSIGNED_FOLLOWER_FILTERS, {not_in_message: message_id}))
        )
        dispatch(messageUnassignedGroupList.updateListFilter(
            Object.assign(UNASSIGNED_GROUP_FILTERS, {not_in_message: message_id}))
        )
        dispatch(messageRecipientList.updateListFilter(
            Object.assign(RECIPIENT_FILTERS, {message_id: message_id}))
        )
        dispatch(messageUnassignedFollowerList.invalidateList())
        dispatch(messageUnassignedGroupList.invalidateList())
        dispatch(messageRecipientList.invalidateList())
        this.refresh()
        this.setState({
            show_groups: true,
        })
    }

    componentDidUpdate(prevProps) {
        this.refresh()
    }

    refresh() {
        const { dispatch, message_id } = this.props
        dispatch(messageUnassignedFollowerList.fetchListIfNeeded())
        dispatch(messageUnassignedGroupList.fetchListIfNeeded())
        dispatch(messageRecipientList.fetchListIfNeeded())
        dispatch(messageList.ensureObjectLoaded(message_id))
    }

    getUnassignedFollowerCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'relationship':
                return item.relationship
            case 'action':
                return <img src={ right_dark_icon } alt="" css={ css`width:25px;` } />
            default:
                return <span>"No idea"</span>
        }
    }

    getUnassignedGroupCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'name':
                return item.name
            case 'description':
                return item.description
            case 'count':
                return item.count_followers
            case 'action':
                return <img src={ right_dark_icon } alt="" css={ css`width:25px;` } />
                default:
                return <span>"No idea"</span>
        }
    }

    getRecipientCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'name':
                return item.name
            case 'recipient_type':
                return capitalize(item.recipient_type)
            case 'relationship':
                return item.relationship
            case 'action':
                return (
                    <img src={ left_dark_icon } alt="" css={ css`width:25px;` } />
                )
            default:
                return <span>"No idea"</span>
        }
    }
    
    addFollower = (follower_id) => {
        const { dispatch, message, unassigned_followers } = this.props
        const follower = find(unassigned_followers, { id: follower_id })
        let values = {}
        values.isNewInstance = true
        values.message = message.id
        values.follower = follower.id
        values.recipient_type = 'contact'
        values.name = follower.display_name
        const on_done = function() {
            dispatch(messageUnassignedFollowerList.invalidateList())
            dispatch(messageRecipientList.invalidateList())
            dispatch(messageList.invalidateObject(message.id))
        }
        return dispatch(messageRecipientList.saveObject(values, on_done))
    }

    addGroup = (group_id) => {
        const { dispatch, message, unassigned_groups } = this.props
        const group = find(unassigned_groups, { id: group_id })
        let values = {}
        values.isNewInstance = true
        values.message = message.id
        values.group = group.id
        values.recipient_type = 'group'
        values.name = group.name
        const on_done = function() {
            dispatch(messageUnassignedGroupList.invalidateList())
            dispatch(messageRecipientList.invalidateList())
            dispatch(messageList.invalidateObject(message.id))
        }
        return dispatch(messageRecipientList.saveObject(values, on_done))
    }

    removeRecipient = (recipient_id) => {
        const { dispatch, message } = this.props
        const on_done = function() {
            dispatch(messageUnassignedFollowerList.invalidateList())
            dispatch(messageUnassignedGroupList.invalidateList())
            dispatch(messageRecipientList.invalidateList())
            dispatch(messageList.invalidateObject(message.id))
        }
        return dispatch(messageRecipientList.deleteObject(recipient_id, on_done))
    }

    filterUnassignedFollower = (e) => {
        const { dispatch } = this.props
        dispatch(messageUnassignedFollowerList.updateListFilter({'any_field': e.target.value, 'page' : 1}))
    }

    filterUnassignedGroup = (e) => {
        const { dispatch } = this.props
        dispatch(messageUnassignedGroupList.updateListFilter({'any_field': e.target.value, 'page' : 1}))
    }

    filterRecipients = (e) => {
        const { dispatch } = this.props
        dispatch(messageRecipientList.updateListFilter({'any_field': e.target.value, 'page' : 1}))
    }

    sortUnassignedFollowerOnHeader = (key) => {
        const { dispatch, unassigned_follower_filter } = this.props
        if (key !== 'action' && key !=='relationship') {
            let sort_by = key
            if (has(unassigned_follower_filter, 'order_by') &&
                unassigned_follower_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(messageUnassignedFollowerList.updateListFilter({'order_by':sort_by}))
        }
    }

    sortUnassignedGroupOnHeader = (key) => {
        const { dispatch, unassigned_group_filter } = this.props
        if (key !== 'action') {
            let sort_by = key
            if (has(unassigned_group_filter, 'order_by') &&
                unassigned_group_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(messageUnassignedGroupList.updateListFilter({'order_by':sort_by}))
        }
    }

    sortRecipientsOnHeader = (key) => {
        const { dispatch, recipient_filter } = this.props
        if (key !== 'action' && key !=='relationship') {
            let sort_by = key
            if (has(recipient_filter, 'order_by') &&
                recipient_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(messageRecipientList.updateListFilter({'order_by':sort_by}))
        }
    }

    onAddAllFollowers = () => {
        const { dispatch, message_id } = this.props
        const on_done = function() {
            dispatch(messageUnassignedFollowerList.updateListFilter({page: 1}))
            dispatch(messageRecipientList.updateListFilter({page: 1}))
            dispatch(messageList.invalidateObject(message_id))
        }
        return dispatch(messageAddFollowersList.update(message_id, on_done))
    }

    onAddAllGroups = () => {
        const { dispatch, message_id } = this.props
        const on_done = function() {
            dispatch(messageUnassignedGroupList.updateListFilter({page: 1}))
            dispatch(messageRecipientList.updateListFilter({page: 1}))
            dispatch(messageList.invalidateObject(message_id))
        }
        return dispatch(messageAddGroupsList.update(message_id, on_done))
    }

    onRemoveAllRecipients = () => {
        const { dispatch, message_id } = this.props
        dispatch(messageRemoveFollowersList.update(message_id)).then(() => {
            dispatch(messageRemoveGroupsList.update(message_id))
        }).then(() => {
            dispatch(messageUnassignedFollowerList.updateListFilter({page: 1}))
            dispatch(messageUnassignedGroupList.updateListFilter({page: 1}))
            dispatch(messageRecipientList.updateListFilter({page: 1}))
            dispatch(messageList.invalidateObject(message_id))
        })
    }

    onChangeToggle = (value) => {
        this.setState({show_groups:value})
    }

    renderUsersTable = () => {
        const {
            is_unassigned_followers_loading,
            unassigned_followers,
            unassigned_follower_headers,
        } = this.props
        const {
            show_groups,
        } = this.state
        return (
            <div css={ table_container }>
              <label css={ label }>Contacts</label>
              <TableHeader custom_height="50px">
                <TableFilter
                    updateOnChange={ this.filterUnassignedFollower }
                    form="UNASSIGNED_FOLLOWER_TABLE_FILTER_FORM"
                />
                <ToggleButton
                    value={ show_groups }
                    onChange={ this.onChangeToggle }
                    on_label="Groups"
                    off_label="Contacts"
                    light={ true }
                />
                <DefaultButton
                    label="Add All"
                    type="button"
                    light={ true }
                    onClickButton={ this.onAddAllFollowers }
                />
              </TableHeader>
              <CommonTable
                  is_loading={ is_unassigned_followers_loading }
                  empty_message="There are no contacts."
                  headers={ unassigned_follower_headers }
                  items={ unassigned_followers }
                  getCellValue={ this.getUnassignedFollowerCellValue }
                  onRowClick={ this.addFollower }
                  sortOnHeader={ this.sortUnassignedFollowerOnHeader }
                  custom_row_height="small"
              />
              <TableFooter custom_height="50px">
                <div css={ pagination_container }>
                  <Pagination
                      listKey={ messageUnassignedFollowerList.listKey }
                      filters={ UNASSIGNED_FOLLOWER_FILTERS }
                  />
                </div>
              </TableFooter>
            </div>
        )
    }

    renderGroupsTable = () => {
        const {
            is_unassigned_groups_loading,
            unassigned_groups,
            unassigned_group_headers,
        } = this.props
        const {
            show_groups,
        } = this.state
        return (
            <div css={ table_container } >
              <label css={ label }>Groups</label>
              <TableHeader custom_height="50px" >
                <TableFilter
                    updateOnChange={ this.filterUnassignedGroup }
                    form="UNASSIGNED_GROUP_TABLE_FILTER_FORM"
                />
                <ToggleButton
                    value={ show_groups }
                    onChange={ this.onChangeToggle }
                    on_label="Groups"
                    off_label="Contacts"
                    light={ true }
                />
                <DefaultButton
                    label="Add All"
                    type="button"
                    light={ true }
                    onClickButton={ this.onAddAllGroups }
                />
              </TableHeader>
              <CommonTable
                  is_loading={ is_unassigned_groups_loading }
                  empty_message="There are no groups."
                  headers={ unassigned_group_headers }
                  items={ unassigned_groups }
                  getCellValue={ this.getUnassignedGroupCellValue }
                  onRowClick={ this.addGroup }
                  sortOnHeader={ this.sortUnassignedGroupOnHeader }
                  custom_row_height="small"
              />
              <TableFooter custom_height="50px" >
                <div css={ pagination_container }>
                  <Pagination
                      listKey={ messageUnassignedGroupList.listKey }
                      filters={ UNASSIGNED_GROUP_FILTERS }
                  />
                </div>
              </TableFooter>
            </div>
        )
    }
    
    render() {
        const {
            is_recipients_loading,
            recipients,
            recipient_headers,
            is_busy,
            message,
        } = this.props
        const {
            show_groups,
        } = this.state
        return (
            <div css={ form_layout }>
              { is_busy && <BusyMask /> }
              <div css={ header } >
                <h2>Select Recipients</h2>
                <label css={ label } >{`Number of Recipients: ${message.count_recipients}`}</label>
              </div>
              <div className="form-group" css={ tables_container }>
                <div css={ row }>
                  { !show_groups ?
                    this.renderUsersTable()
                    :
                    this.renderGroupsTable()
                  }
                  <div css={ table_container }>
                    <label css={ label }>Message Recipients</label>
                    <TableHeader custom_height="50px">
                      <TableFilter
                          updateOnChange={ this.filterRecipients }
                          form="RECIPIENT_TABLE_FILTER_FORM"
                      />
                      <DefaultButton
                          label="Remove All"
                          type="button"
                          light={ true }
                          onClickButton={ this.onRemoveAllRecipients }
                      />
                    </TableHeader>
                    <CommonTable
                        is_loading={ is_recipients_loading }
                        empty_message="Message has no recipients."
                        headers={ recipient_headers }
                        items={ recipients }
                        getCellValue={ this.getRecipientCellValue }
                        onRowClick={ this.removeRecipient }
                        sortOnHeader={ this.sortRecipientsOnHeader }
                        custom_row_height="small"
                    />
                    <TableFooter custom_height="50px" >
                      <div css={ pagination_container }>
                        <Pagination
                            listKey={ messageRecipientList.listKey }
                            filters={ RECIPIENT_FILTERS }
                        />
                      </div>
                    </TableFooter>
                  </div>
                </div>
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { message_id } = props
    const message = messageList.getObject(message_id) || {}
    const unassigned_followers = messageUnassignedFollowerList.getVisibleObjects()
    const unassigned_groups = messageUnassignedGroupList.getVisibleObjects()
    const recipients = messageRecipientList.getVisibleObjects()
    
    return {
        message,
        unassigned_followers,
        unassigned_groups,
        recipients,
        unassigned_follower_filter: messageUnassignedFollowerList.getFilter(),
        unassigned_group_filter: messageUnassignedGroupList.getFilter(),
        recipient_filter: messageRecipientList.getFilter(),
        unassigned_follower_headers: {
            last_name: { name: 'Last name', column_size: 4 },
            first_name: { name: 'First name', column_size: 4 },
            relationship: { name: 'Relationship', column_size: 4 },
            action: { name: '', column_size: 1 },
        },
        unassigned_group_headers: {
            name: { name: 'Name', column_size: 4 },
            description: { name: 'Description', column_size: 6 },
            count: { name: 'Count', column_size: 4 },
            action: { name: '', column_size: 1 },
        },
        recipient_headers: {
            action: { name: '', column_size: 1 },
            name: { name: 'Name', column_size: 5 },
            recipient_type: { name: 'Type', column_size: 4 },
            relationship: { name: 'Relationship', column_size: 5 },
        },
        is_unassigned_followers_loading: messageUnassignedFollowerList.isLoading(),
        is_unassigned_groups_loading: messageUnassignedGroupList.isLoading(),
        is_group_loading: messageList.isLoading(),
        is_recipients_loading: messageRecipientList.isLoading(),
        is_busy: messageUnassignedFollowerList.isLoading() ||
                 messageUnassignedGroupList.isLoading() ||
                 messageList.isLoading() ||
                 messageList.getIsSavingObject() ||
                 messageRecipientList.isLoading() ||
                 messageRecipientList.getIsSavingObject(),
    }
}
export default withRouter(connect(mapStateToProps)(MessageRecipients))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
height: 670px;
`

const tables_container = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
height: 100%;
`

const row = css`
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
`

const table_container = css`
display: flex;
flex-direction: column;
min-width: 45%;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const header = css`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding-bottom: 20px;
`
