/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { capitalize, upperCase, head, split, remove } from 'lodash'
import moment from 'moment'
import { PDFDownloadLink }from '@react-pdf/renderer'

import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import Pagination from './Pagination'
import Timestamp from './Timestamp'
import DefaultButton from './DefaultButton'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import MonthYearPicker from './MonthYearPicker'
import TransactionsPDF from './TransactionsPDF'
import BusyMask from './BusyMask'

import { transactionList } from '../actions/transaction'
import { transactionNoPaginationList } from '../actions/transaction'
import { organisationList } from '../actions/organisation'
import { accountList } from '../actions/account'

import loading_icon from '../images/loading.gif'

const FILTERS = { 'page': '1' }

class Transactions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            table_filters: FILTERS,
            selected_item: null,
            startDate: null,
            displayGeneratePdf: true,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(transactionList.invalidateList())
        dispatch(transactionList.updateListFilter(FILTERS))
        dispatch(transactionList.fetchListIfNeeded())
        dispatch(accountList.invalidateList())
        dispatch(accountList.fetchListIfNeeded())
        dispatch(organisationList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(transactionList.fetchListIfNeeded())
    }

    getTransactionCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.updated_at} format='date' />
                )
            case 'type':
                return capitalize(item.type)
            case 'description':
                return capitalize(item.description)
            case 'method':
                return upperCase(item.payment_method)
            case 'debit':
                return item.debit_amount
            case 'credit':
                return item.credit_amount
            default:
                return (
                    <span>"No idea"</span>
                )
        }
    }

    onFilterByDate = (value) => {
        const { dispatch } = this.props
        dispatch(transactionList.updateListFilter({'month_created': moment(value).format('YYYY-MM-DD'), 'page': 1}))
    }

    onClearDate = () => {
        const { dispatch } = this.props
        dispatch(transactionList.clearListFilter())
        dispatch(transactionList.updateListFilter(FILTERS))
    }

    onGeneratePdf = () => {
        const { dispatch, transaction_filter } = this.props
        dispatch(transactionNoPaginationList.invalidateList())
        dispatch(transactionNoPaginationList.updateListFilter(Object.assign({all: true}, transaction_filter)))
        dispatch(transactionNoPaginationList.fetchListIfNeeded()).then(() => {
            this.setState({
                displayGeneratePdf: false,
            }, () => ReactDOM.render(this.renderDownload(), document.getElementById('button'))
            )
        })
    }

    renderDownload = () => {
        const { transactions_no_pagination, organisation } = this.props
        const { displayGeneratePdf } = this.state
        return (
            <div>
              { !displayGeneratePdf &&
                <div css={css`a {color:black; :hover {text-decoration:none;}}`}
                     className="btn btn-light"
                     onClick={ this.toggleGeneratePdf }>
                  <PDFDownloadLink
                      document={
                          <TransactionsPDF
                              items={transactions_no_pagination}
                                    organisation_name={organisation.name}
                          />
                      }
                      fileName={`${organisation.mobile_url}-${moment().format('DD-MM-YYYY-HH-mm-ss')}.pdf`}>
                    {
                        ({ blob, url, loading, error }) => (loading ? <img
                                                                          alt="loading"
                                                                          src={ loading_icon }
                                                                          css={css`height:20px;width:auto;`}
                        /> : 'Download PDF')
                    }
                  </PDFDownloadLink>
                </div>
              }
            </div>
        )
    }

    toggleGeneratePdf = () => {
        const { displayGeneratePdf } = this.state
        this.setState({
            displayGeneratePdf: !displayGeneratePdf,
        })

    }

    render() {
        const {
            transaction_headers,
            transactions,
            is_loading,
            transaction_filter,
            messaging_account,
            subscription_account,
            service_account,
            is_busy,
        } = this.props
        const { startDate, displayGeneratePdf } = this.state
        
        return (
            <MainLayout title="Transactions">
              { is_busy && <BusyMask/> }
              <div css={ container }>
                <div css={ accounts_container }>
                  <div css={ row }>
                    <span css={ [cell, css`flex:2`] }>
                      Topup Messaging Funds Balance
                    </span>
                    <span css={ [cell, css`flex:1`] }>
                      {messaging_account && `R ${messaging_account.parsed_balance}`}
                    </span>
                    <span css={ [cell, css`flex:1`] }>
                      { messaging_account && messaging_account.balance_rands > 0 ? 'Available' : 'No Funds'}
                    </span>
                  </div>
                  <div css={ row }>
                    <span css={ [cell, css`flex:2`] }>
                      Subscription Funds Balance
                    </span>
                    <span css={ [cell, css`flex:1`] }>
                      {subscription_account && `R ${subscription_account.parsed_balance}`}
                    </span>
                    <span css={ [cell, css`flex:1`] }>
                      {subscription_account && subscription_account.balance_rands > 0 ? 'Credit' : 'Owed'}
                    </span>
                  </div>
                  <div css={ row }>
                    <span css={ [cell, css`flex:2`] }>
                      Services Funds Balance
                    </span>
                    <span css={ [cell, css`flex:1`] }>
                      {service_account && `R ${service_account.parsed_balance}`}
                    </span>
                    <span css={ [cell, css`flex:1`] }>
                      {service_account && service_account.balance_rands > 0 ? 'Credit' : 'Owed'}
                    </span>
                  </div>
                </div>
              </div>
              <div css={ container }>
                <TableHeader>
                  <div css={ header }>
                    <MonthYearPicker
                        value={ startDate }
                        onChange={ this.onFilterByDate }
                        onClear={ this.onClearDate }
                    />
                    { !displayGeneratePdf ?
                      <div id="button"></div>
                      :
                      <DefaultButton
                          label="Generate PDF"
                          type="button"
                          light={ true }
                          onClickButton={ this.onGeneratePdf }
                      />
                    }
                  </div>
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no transactions."
                    headers={ transaction_headers }
                    items={ transactions }
                    getCellValue={ this.getTransactionCellValue }
                />
                <TableFooter>
                  <div css={ pagination_container }>
                    <Pagination listKey={transactionList.listKey} filters={ transaction_filter } />
                  </div>
                </TableFooter>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const transactions = transactionList.getVisibleObjects()
    const transactions_no_pagination = transactionNoPaginationList.getVisibleObjects()
    const organisation = head(organisationList.getObjects()) || {}
    const accounts = accountList.getVisibleObjects()
    let messaging_account = remove(accounts, (account) => {
        return split(account.name, '_')[1] === 'messaging'
    })
    let subscription_account = remove(accounts, (account) => {
        return split(account.name, '_')[1] === 'subscriptions'
    })
    let service_account = remove(accounts, (account) => {
        return split(account.name, '_')[1] === 'services'
    })
    
    return {
        transactions,
        transactions_no_pagination,
        organisation,
        transaction_filter: transactionList.getFilter(),
        messaging_account: head(messaging_account),
        subscription_account: head(subscription_account),
        service_account: head(service_account),
        transaction_headers: {
            created_at: { name: 'Date', column_size: 1 },
            type: { name: 'Type', column_size: 1 },
            description: { name: 'Description', column_size: 2 },
            method: { name: 'Payment Method', column_size: 1 },
            debit: { name: 'Debit', column_size: 1 },
            credit: { name: 'Credit', column_size: 1 },
        },
        is_loading: transactionList.isLoading(),
        is_busy: transactionList.isLoading() || transactionNoPaginationList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(Transactions))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 10px;
`

const header = css`
display: flex;
justify-content: space-between;
width: 100%;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const accounts_container = css`
width: 50%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 10px;
`

const row = css`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
border-bottom: 1px solid #e6e6e6;
`

const cell = css`
display: flex;
padding: 5px;
justify-content: flex-start;
width: 50%;
`
