/** @jsx jsx */
import { Component } from 'react'
import { jsx } from '@emotion/core'
import { map, capitalize, upperCase } from 'lodash'
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import moment from 'moment'

import npo_connect_logo from '../images/npo-connect-landscape.png'
import { default_theme as theme } from '../emotion/theme'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    details_container: {
        flexDirection: 'column',
        padding: 5,
    },
    details_row: {
        flexDirection: 'row',
    },
    details: {
        fontSize: 10,
    },
    table: {
        flexDirection: 'column',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: `${theme.colors.gray2}`,
        paddingBottom: 10,
        marginTop: 10,
    },
    table_header: {
        fontSize: 10,
        backgroundColor: `${theme.colors.primary}`,
        color: 'white',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 5,
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: `${theme.colors.gray2}`,
        fontSize: 10,
        justifyContent: 'space-between',
        padding: 5,
    },
    cell: {
        flex: 1,
        paddingLeft: 5,
    },
    large_cell: {
        flex: 2,
        paddingLeft: 5,
    },
    footer: {
        fontSize: 8,
        flexDirection: 'row',
        width: '100%',
        padding: 5,
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: 0,
    }
});

class TransactionsPDF extends Component {

    renderRow = (item) => {
        return (
            <View style={styles.row} key={item.id} wrap={false}>
              <Text style={styles.cell}>{moment(item.updated_at).format('DD-MM-YYYY HH:mm')}</Text>
              <Text style={styles.cell}>{capitalize(item.type)}</Text>
              <Text style={styles.large_cell}>{capitalize(item.description)}</Text>
              <Text style={styles.cell}>{upperCase(item.payment_method)}</Text>
              <Text style={styles.cell}>{item.debit_amount}</Text>
              <Text style={styles.cell}>{item.credit_amount}</Text>
            </View>
        )
    }
    
    render() {
        const { items, organisation_name } = this.props
        return (
            <Document>
              <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.header} fixed>
                  <Text>Transaction Report</Text>
                  <Image src={npo_connect_logo} />
                </View>
                <View style={styles.details_container} fixed>
                  <View style={styles.details_row}>
                    <Text style={styles.details}>Organisation: </Text>
                    <Text style={styles.details}>{organisation_name}</Text>
                  </View>
                  <View style={styles.details_row}>
                    <Text style={styles.details}>Generated on: </Text>
                    <Text style={styles.details}>{moment().format('LLLL')}</Text>
                  </View>
                </View>
                <View style={styles.table}>
                  <View style={styles.table_header} fixed>
                    <Text style={styles.cell}>Date</Text>
                    <Text style={styles.cell}>Type</Text>
                    <Text style={styles.large_cell}>Description</Text>
                    <Text style={styles.cell}>Payment Method</Text>
                    <Text style={styles.cell}>Debit</Text>
                    <Text style={styles.cell}>Credit</Text>
                  </View>
                  { map(items, (item) => {
                        return this.renderRow(item)
                  })  
                  }
                </View>
                <View style={styles.footer} fixed>
                  <Text render={
                  ({ pageNumber, totalPages }) => (
                      `Page ${pageNumber} of ${totalPages}`
                  )} fixed />
                </View>
              </Page>
            </Document>
        )
    }

}

export default TransactionsPDF
