/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'

import { default_theme as theme } from '../emotion/theme'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

class TokenLoginForm extends Component {
    
    render() {
        const { handleSubmit, onCancelToken, error } = this.props

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Password or Token</label>
                <div>
                  <Field
                      type="password"
                      name="password"
                      validate={[required]}
                      component={ InputField }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Cancel"
                      type="button"
                      onClickButton={ onCancelToken }
                  />
                </div>
                <div>
                  <PrimaryButton
                      label="Login"
                      type="submit"
                  />
                </div>
              </div>
            </form>
        )
    }
}
TokenLoginForm = reduxForm({
    form: 'TOKEN_LOGIN_FORM'
})(TokenLoginForm);
function mapStateToProps(state, props) {
    const { onSubmit, onCancelToken } = props
    return {
        onSubmit,
        onCancelToken
    }
}
export default withRouter(connect(mapStateToProps)(TokenLoginForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
`

const field_container = css`
width: 80%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 80%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
