import auth from './auth'
import orm_list_reducer from '../orm/orm_list_reducer'
import orm_objects_reducer from '../orm/orm_objects_reducer'
import settings from './settings'
import { ORM_LIST_KEY, ORM_OBJECT_KEY } from '../orm'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
    [ORM_OBJECT_KEY]: orm_objects_reducer,
    [ORM_LIST_KEY]: orm_list_reducer,
    form: formReducer,
    auth,
    settings,
})

const rootReducer = (state, action) => {
    return appReducer(state, action)
}
export default rootReducer;
