import { ItemList } from '../orm'
import { ENTITY_KEY__ACCOUNT, LIST_KEY__ACCOUNT } from './ItemListKeyRegistry'

class AccountList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__ACCOUNT
    }
}

export const accountList = new AccountList(LIST_KEY__ACCOUNT)
