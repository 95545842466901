import { ItemList } from '../orm'
import { ENTITY_KEY__DASHBOARD, LIST_KEY__DASHBOARD } from './ItemListKeyRegistry'

class DashboardList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__DASHBOARD
    }
}

export const dashboardList = new DashboardList(LIST_KEY__DASHBOARD)
