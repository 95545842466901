/** @jsx jsx */
import { Component } from 'react'
import { jsx } from '@emotion/core'
import Select from 'react-select'
import ReactTimeout from 'react-timeout'

import FormError from './FormError'
import FormWarning from './FormWarning'
import BusyMask from './BusyMask'


const SEARCH_DELAY_MILLISECONDS = 1000

class DropdownFetch extends Component {

    componentWillUnmount() {
        clearTimeout(this.filter_timeout_id)
    }

    showOptions = (value) => {
        const { fetchOptions } = this.props
        if ( this.filter_timeout_id ) {
            clearTimeout(this.filter_timeout_id)
            this.filter_timeout_id = null
        }
        this.filter_timeout_id = setTimeout(function () {
            fetchOptions(value)
        }, SEARCH_DELAY_MILLISECONDS)
    }
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            tabIndex,
            meta,
            is_searching,
            options,
            ...extraProps
        } = this.props
        return (
            <div>
              <Select
                  {...extraProps}
                  value={input.value}
                  options={options}
                  onChange={(value) => input.onChange(value)}
                  onInputChange={(value) => this.showOptions(value)}
                  css={ extra_class }
              />
              { is_searching && <BusyMask /> }
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}
export default ReactTimeout(DropdownFetch)
