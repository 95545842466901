/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, has } from 'lodash'

import { adminDashboardList } from '../actions/admin_dashboard'
import { adminOverdueSubscriptionList } from '../actions/admin_subscription'
import { adminActiveOrganisationList } from '../actions/admin_organisation'

import MainLayout from './MainLayout'
import DashboardItem from '../../components/DashboardItem'
import BusyMask from '../../components/BusyMask'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import Pagination from '../../components/Pagination'
import CommonTable from '../../components/CommonTable'
import Timestamp from '../../components/Timestamp'
import TableFilter from '../../components/TableFilter'

import { default_theme as theme } from '../../emotion/theme'
import content_dark from '../../images/content_dark.png'
import active_dark from '../../images/active_dark.png'
import inactive_dark from '../../images/inactive_dark.png'
import send_dark from '../../images/send_dark.png'
import sms_dark from '../../images/sms_outline_dark.png'
import notification_dark from '../../images/notification_outline_dark.png'

const SUBSCRIPTION_FILTER = {'page': '1'}
const ORGANISATION_FILTER = {'page': '1'}

class AdminDashboard extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminDashboardList.invalidateList())
        dispatch(adminDashboardList.fetchListIfNeeded())
        dispatch(adminOverdueSubscriptionList.invalidateList())
        dispatch(adminOverdueSubscriptionList.updateListFilter(
            Object.assign(SUBSCRIPTION_FILTER, {overdue: true}),
        ))
        dispatch(adminOverdueSubscriptionList.fetchListIfNeeded())
        dispatch(adminActiveOrganisationList.invalidateList())
        dispatch(adminActiveOrganisationList.updateListFilter(
            Object.assign(ORGANISATION_FILTER, {active: true}),
        ))
        dispatch(adminActiveOrganisationList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminOverdueSubscriptionList.fetchListIfNeeded())
        dispatch(adminActiveOrganisationList.fetchListIfNeeded())
    }

    sortOrganisationOnHeader = (key) => {
        const { dispatch, organisation_filter } = this.props
        if (key === 'name') {
            let sort_by = key
            if (has(organisation_filter, 'order_by') &&
                organisation_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(adminActiveOrganisationList.updateListFilter({'order_by':sort_by}))
        }
    }

    getSubscriptionCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'updated_at':
                return (
                    <Timestamp value={item.updated_at} format='date' />
                )
            case 'organisation_name':
                return item.organisation_name
            case 'amount_owed':
                return item.amount_owed
            default:
                return (
                    <span>"No idea"</span>
                )
        }
    }

    getOrganisationCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'name':
                return item.name
            case 'subscriptions_balance':
                return item.subscriptions_balance
            case 'messaging_balance':
                return item.messaging_balance
            case 'services_balance':
                return item.services_balance
            default:
                return (
                    <span>"No idea"</span>
                )
        }
    }

    filterOrganisations = (e) => {
        const { dispatch } = this.props
        dispatch(adminActiveOrganisationList.updateListFilter({'name': e.target.value, 'page' : 1}))
    }

    render() {
        const {
            data,
            is_busy,
            is_subscription_loading,
            subscription_filter,
            overdue_subscriptions,
            subscription_headers,
            organisations,
            organisation_headers,
            organisation_filter,
            is_organisation_loading,
        } = this.props

        return (
            <MainLayout title='Dashboard'>
              { is_busy && <BusyMask /> }
              <div css={ dashboard }>
                <div css={ column_container }>
                  <div css={ container }>
                    <TableHeader custom_height="50px">
                      <label css={ [label, css`text-align:right;width:100%;`] }>Overdue Payments</label>
                    </TableHeader>
                    <CommonTable
                        is_loading={ is_subscription_loading }
                        empty_message="There are no overdue subscriptions."
                        headers={ subscription_headers }
                        items={ overdue_subscriptions }
                        getCellValue={ this.getSubscriptionCellValue }
                    />
                    <TableFooter custom_height="50px">
                      <div css={ pagination_container }>
                        <Pagination
                            listKey={ adminOverdueSubscriptionList.listKey }
                            filters={ subscription_filter }
                        />
                      </div>
                    </TableFooter>
                  </div>
                  <div css={ container }>
                    <TableHeader custom_height="50px">
                      <TableFilter
                          updateOnChange={ this.filterOrganisations }
                          form="ORGANISATION_ACCOUNTS_TABLE_FILTER_FORM"
                      />
                      <label css={ label }>Customers Credit Balance</label>
                    </TableHeader>
                    <CommonTable
                        is_loading={ is_organisation_loading }
                        empty_message="There are no organisations."
                        headers={ organisation_headers }
                        items={ organisations }
                        getCellValue={ this.getOrganisationCellValue }
                        sortOnHeader={ this.sortOrganisationOnHeader }
                    />
                    <TableFooter custom_height="50px">
                      <div css={ pagination_container }>
                        <Pagination listKey={ adminActiveOrganisationList.listKey } filters={ organisation_filter } />
                      </div>
                    </TableFooter>
                  </div>
                </div>
                <div css={ row_container }>
                  <div css={ column_container }>
                    <DashboardItem
                        background_colour={ theme.colors.logo_1 }
                        icon={ content_dark }
                        stat={ data.organisations }
                        text={ data.organisations === 1 ?
                               "Organisation" :
                               "Organisations" }
                    />
                    <DashboardItem
                        background_colour={ theme.colors.logo_1 }
                        icon={ active_dark }
                        stat={ data.active_organisations }
                        text={ data.active_organisations === 1 ?
                               "Active organisation" :
                               "Active organisations" }
                    />
                    <DashboardItem
                        background_colour={ theme.colors.logo_1 }
                        icon={ inactive_dark }
                        stat={ data.inactive_organisations }
                        text={ data.inactive_organisations === 1 ?
                               "Inactive Organisation" :
                               "Inactive organisations" }
                    />
                  </div>
                  <div css={ column_container }>
                    <DashboardItem
                        background_colour={ theme.colors.logo_2 }
                        icon={ send_dark }
                        stat={ data.messages }
                        text={ data.messages === 1 ?
                               "Message sent this month" :
                               "Messages sent this month" }
                    />
                    <DashboardItem
                        background_colour={ theme.colors.logo_2 }
                        icon={ sms_dark }
                        stat={ data.text_messages_sent }
                        text={ data.text_messages_sent === 1 ?
                               "Text message sent this month" :
                               "Text messages sent this month" }
                    />
                    <DashboardItem
                        background_colour={ theme.colors.logo_2 }
                        icon={ notification_dark }
                        stat={ data.push_notifications_sent }
                        text={ data.push_notifications_sent === 1 ?
                               "Push notification sent this month" :
                               "Push notifications sent this month" }
                    />
                  </div>
                </div>
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state) {
    const data = head(adminDashboardList.getVisibleObjects()) || {}
    const overdue_subscriptions = adminOverdueSubscriptionList.getVisibleObjects() || []
    const organisations = adminActiveOrganisationList.getVisibleObjects() || []
    return {
        data,
        overdue_subscriptions,
        organisations,
        is_busy: adminDashboardList.isLoading() ||
                 adminOverdueSubscriptionList.isLoading() ||
                 adminActiveOrganisationList.isLoading(),
        is_subscription_loading: adminOverdueSubscriptionList.isLoading(),
        is_organisation_loading: adminActiveOrganisationList.isLoading(),
        subscription_filter: adminOverdueSubscriptionList.getFilter(),
        organisation_filter: adminActiveOrganisationList.getFilter(),
        subscription_headers: {
            organisation_name: { name: 'Customer', column_size: 1 },
            updated_at: { name: 'Due Date', column_size: 1 },
            amount_owed: { name: 'Amount', column_size: 1 },
        },
        organisation_headers: {
            name: { name: 'Name', column_size: 3 },
            subscriptions_balance: { name: 'Subscriptions', column_size: 2 },
            messaging_balance: { name: 'Messaging', column_size: 2 },
            services_balance: { name: 'Services', column_size: 2 },
        },
    }
}
export default connect(mapStateToProps)(AdminDashboard)

const dashboard = css`
display: flex;
flex-wrap: wrap;
justify-content: center;
`

const column_container = css`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`

const row_container = css`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 10px;
min-width: 680px;
max-width: 750px;
padding: 20px;
`

const label = css`
padding-top: 10px;
color: white;
font-weight: 600;
font-size: 18px;
`

