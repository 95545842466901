/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import { adminOrganisationList } from '../actions/admin_organisation'

import InputField from '../../components/InputField'
import DefaultButton from '../../components/DefaultButton'
import PrimaryButton from '../../components/PrimaryButton'
import Dropdown from '../../components/Dropdown'
import Timestamp from '../../components/Timestamp'
import RadioSelectField from '../../components/RadioSelectField'

import { default_theme as theme } from '../../emotion/theme'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const currency = value =>
    value && !/^(?:\d{1,3}(?:,\d{3})+|\d+)(?:\.\d+)?$/.test(value) ?
    'Invalid currency' : undefined
const hex = value => value && !/^#(?:[0-9a-f]{3}){1,2}$/i.test(value) ? 'Invalid Hex Code' : undefined
const tel = value =>
    value && !(/^\d{7,15}$/).test(value.replace(/[\s()+\-\.]|ext/gi, '')) ?
    'Invalid Phone Number' : undefined

class OrganisationAdminForm extends Component {
    
    componentDidMount() {
        const { dispatch, organisation_id } = this.props
        dispatch(adminOrganisationList.ensureObjectLoaded(organisation_id))
    }
    
    render() {
      const { handleSubmit,
              onCancel,
              onSendInvite,
              onTransactions,
              error,
              organisation,
            } = this.props
      
      const status_options = [
        {value: true, label: 'Active'},
        {value: false, label: 'Inactive'}
      ]

      const paynow_options = [
        {id: true, name: 'Enabled'},
        {id: false, name: 'Disabled'}
      ]

      const mobile_main_menu_option = [
        {id: 'events', name: 'Events'},
        {id: 'donations', name: 'Donations'}
      ]

      const npoconnect_logo_option = [
        {id: 'npoconnect', name: 'NPOConnect'},
        {id: 'clubconnect', name: 'Clubconnect'},
        {id: 'faithconnect', name: 'Faithconnect'},
        {id: 'profconnect', name: 'Profconnect'}
      ]

      const foreground_options = [
        {name: 'Dark', id: true},
        {name: 'Light', id: false}
      ]

      const show_donations_in_side_bar_options = [
        {id: true, name: "Show"},
        {id: false, name: "Hide"}
      ]

      const int_numbers_options = [
        {id: true, name: 'Allowed'},
        {id: false, name: 'Disallowed'}
      ]

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ row_container } >
                <div css={ column_container }>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Date Joined</label>
                    <div className="form-control" css={ css`background: #e9ecef;` }>
                      <Timestamp value={organisation.created_at} format='date' />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Administrator</label>
                    <div>
                      <Field
                          type="text"
                          name="admin_email"
                          component={ InputField }
                          readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Mobile URL Prefix</label>
                    <div>
                      <Field
                          type="text"
                          name="mobile_url"
                          component={ InputField }
                          readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Contact Person</label>
                    <div>
                      <Field
                          type="text"
                          name="contact_person"
                          component={ InputField }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Contact Person Cellphone Number</label>
                    <div>
                      <Field
                          type="text"
                          name="contact_person_cellphone_number"
                          component={ InputField }
                          validate={ [tel] }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Text Message Cost (ZAR)</label>
                    <div>
                      <Field
                          type="text"
                          name="cost_per_sms"
                          component={ InputField }
                          validate={ [required, currency] }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Push Notification Cost (ZAR)</label>
                    <div>
                      <Field
                          type="text"
                          name="cost_per_pn"
                          component={ InputField }
                          validate={ [required, currency] }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Subscription Fee (ZAR)</label>
                    <div>
                      <Field
                          type="text"
                          name="subscription_fee"
                          component={ InputField }
                          validate={ [required, currency] }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>Status</label>
                    <div>
                      <Field
                          name="status"
                          component={ Dropdown }
                          options={ status_options }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={field_container }>
                    <label css={label}>International SMS Messages</label>
                    <div>
                      <Field
                          component={ RadioSelectField }
                          name="allow_international_numbers"
                          options={ int_numbers_options }
                          horizontal={ true }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>NetCash PayNow</label>
                    <div>
                      <Field
                          component={ RadioSelectField }
                          name="use_paynow"
                          options={ paynow_options }
                          horizontal={ true }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>NetCash Service Key</label>
                    <div>
                      <Field
                          type="text"
                          name="paynow_service_key"
                          component={ InputField }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label }>NPOConnect Logo Options</label>
                    <div css={ logo_options_field }>
                      <Field
                        component={ RadioSelectField }
                        name="npoconnect_logo_option"
                        options={ npoconnect_logo_option }
                        horizontal={ true }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                <label css={ label }>Main Menu Options</label>
                <div>
                  <Field
                    component={ RadioSelectField }
                    name="mobile_main_menu_option"
                    options={ mobile_main_menu_option }
                    horizontal={ true }
                  />
                </div>
              </div>
               <div className="form-group" css={ field_container }>
                <label css={ label }>Show Donations in Side Tray</label>
                <div>
                  <Field
                    component={ RadioSelectField }
                    name="show_donations_in_side_bar"
                    options={ show_donations_in_side_bar_options }
                    horizontal={ true }
                  />
                </div>
              </div>
                </div>
              </div>
              <div css={ footer }>
                <div css={ footer_row }>
                  <div>
                    <PrimaryButton
                        label="Send Invite"
                        type="button"
                        onClickButton={ onSendInvite }
                    ></PrimaryButton>
                  </div>
                  <div>
                    <PrimaryButton
                        label="Transactions"
                        type="button"
                        onClickButton={ onTransactions }
                    ></PrimaryButton>
                  </div>
                </div>
                <div css={ footer_row }>
                  <div>
                    <DefaultButton
                        label="Cancel"
                        type="button"
                        onClickButton={ onCancel }
                    />
                  </div>
                  <div css={ error_container }>
                    { error && <p>{ error }</p>}
                  </div>
                  <div>
                    <PrimaryButton
                        label="Save"
                        type="submit"
                    ></PrimaryButton>
                  </div>
                </div>
              </div>
            </form>
        )
    }

    renderZapper() {
        const zapper_options = [{id: true, name: 'Enabled'}, {id: false, name: 'Disabled'}]

        return(
            <div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper</label>
                <div>
                  <Field
                      component={ RadioSelectField }
                      name="use_zapper"
                      options={ zapper_options }
                      horizontal={ true }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper Merchant Name</label>
                <div>
                  <Field
                      type="text"
                      name="merchant_name"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper Merchant ID</label>
                <div>
                  <Field
                      type="text"
                      name="merchant_id"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper Site ID</label>
                <div>
                  <Field
                      type="text"
                      name="site_id"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper POS Key</label>
                <div>
                  <Field
                      type="text"
                      name="pos_key"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper POS Secret Token</label>
                <div>
                  <Field
                      type="text"
                      name="pos_secret_token"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Zapper POS Signature</label>
                <div>
                  <Field
                      type="text"
                      name="pos_signature"
                      component={ InputField }
                  />
                </div>
              </div>
            </div>
        )
    }
}
OrganisationAdminForm = reduxForm({
    form: 'ORGANISATION_ADMIN_FORM'
})(OrganisationAdminForm);
function mapStateToProps(state, props) {
    const { onSubmit, organisation_id, onCancel, onSendInvite, onTransactions } = props
    let organisation = adminOrganisationList.getObject(organisation_id) || {}
    organisation.status = organisation.active ?
                          {value: true, label: 'Active'} :
                          {value: false, label: 'Inactive'}
    return {
        onSubmit,
        onCancel,
        organisation,
        onSendInvite,
        onTransactions,
        initialValues: organisation,
        enableReinitialize: true,
    }
}
export default withRouter(connect(mapStateToProps)(OrganisationAdminForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin: 10px;
padding: 10px;
@media(max-width: 1300px) {
align-items: center;
}
`

const row_container = css`
width: 50%;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media(max-width: 1300px) {
    width: 700px;
}
`

const column_container = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
height: 690px;
width: 100%;
@media(max-width: 1300px) {
    flex-wrap: nowrap;
    height: auto;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
margin-bottom: 0.7em;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: column;
width: 100%;
`

const footer_row = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex; 
justify-content: space-between;
`

const cell = css`
width: 45%;
`

const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`

const logo_options_field = css`
div  {
display: block;
}
`
















