/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { head } from 'lodash'
import { jsx, css } from '@emotion/core'
import cookie from 'react-cookies'

import PrimaryButton from './PrimaryButton'
import BusyMask from './BusyMask'

import { twitterList, twitterPostList } from '../actions/twitter'

import { default_theme as theme } from '../emotion/theme'
import npo_connect_logo from '../images/npo-connect-landscape.png'

class TwitterConfirmation extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(twitterList.invalidateList())
        dispatch(twitterList.fetchListIfNeeded())
    }

    handleSubmit = () => {
        const { dispatch, twitter } = this.props
        const values = Object.assign({}, twitter, {temp_npoconnect_url: window.location.href})
        dispatch(twitterList.saveObject(values)
        ).then(() => {
            dispatch(twitterPostList.post(
                {article_id: /[^/]*$/.exec(cookie.load('twitterRedirect'))[0]})
            ).then(() => {
                window.location.href = cookie.load('twitterRedirect')
            })
        })
    }
    
    render() {
        const { is_busy } = this.props
        return (
            <div css={ main }>
              { is_busy && <BusyMask /> }
              <div css={ container }>
                <div css={ header }>
                  <img src={ npo_connect_logo } alt="" css={ image } />
                  <p>Twitter Login Successful!</p>
                  <PrimaryButton
                      label="Post to Twitter"
                      onClickButton={ this.handleSubmit }
                  ></PrimaryButton>
                </div>
              </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const twitter = head(twitterList.getObjects()) || {}
    return {
        twitter,
        is_busy: twitterList.isLoading() ||
                 twitterList.getIsSavingObject(),
    }
}
export default withRouter(connect(mapStateToProps)(TwitterConfirmation))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
padding-bottom: 30px;
`

const header = css`
padding-top: 30px;
width: 80%;
text-align: center;
p {
    padding-top: 20px;
}
`

const image = css`
width: 80%;
`
