/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import MenuLeft from './MenuLeft'

import { default_theme as theme } from '../../emotion/theme'
import npo_connect_logo from '../../images/npo-connect-landscape.png'

class MainLayout extends Component {
    
    render() {
        const { title, children, max_width } = this.props
        return (
            <div css={ main }>
             <div css={ menu_left }>
               <MenuLeft/>
             </div>
             <div css={ body } style={{ maxWidth: max_width }}>
              <div css={ header }>
                <h1 css={ css`margin-top:5px;` }>{ title }</h1>
                <img src={ npo_connect_logo } alt="" css={ image } />
              </div>
               { children }
             </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { title, layout, children } = props
    let max_width = '1610px'
    if (layout === 'single') {
        max_width = '750px'
    }
    return {
        title,
        children,
        max_width,
    }
}
export default connect(mapStateToProps)(MainLayout)

const main = css`
min-height: 100vh;
display: flex;
flex-direction: row;
justify-content: center;
background: ${theme.colors.gray2};
`

const menu_left = css`
text-align: center;
display: flex;
flex-direction: column;
justify-content: flex-start;
width: 300px;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 1;
overflow-x: hidden;
`

const body = css`
margin-left: 300px;
width: 100%;
background-color: ${theme.colors.white};
display: flex;
flex-direction: column;
align-items: center;
`

const header = css`
display: flex;
flex-direction: row;
width: 100%;
height: 100px;
justify-content: space-between;
align-items: center;
color: ${theme.colors.secondary};
padding: 10px;
`

const image = css`
height: 50%;
`
