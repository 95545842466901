import { ItemList } from '../../orm'

class AdminSubscriptionList extends ItemList {
    getEntityKey() {
        return 'admin/subscription'
    }
}

export const adminSubscriptionList = new AdminSubscriptionList('admin/subscription')

class AdminOverdueSubscriptionList extends ItemList {
    getEntityKey() {
        return 'admin/overdue_subscription'
    }
}

export const adminOverdueSubscriptionList = new AdminOverdueSubscriptionList('admin/overdue_subscription')
