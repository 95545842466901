import React, { PureComponent } from 'react'
import { TwitterPicker } from 'react-color'
import FormError from './FormError'

class ColorPicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
        showPicker: false,
        color: this.props.input.value,
    }
    
    this.toggleColor = this.toggleColor.bind(this)
    this.handleChangeComplete = this.handleChangeComplete.bind(this)
  }

  toggleColor(e) {
    e.preventDefault()
    this.setState({
      showPicker: !this.state.showPicker
    })
  }

  handleChangeComplete(color, event) {
    const {onColorChange} = this.props
    this.setState({ color: color.hex })
    onColorChange(color.hex)
    this.setState({showPicker: false})
  }

  render() {
    const { meta } = this.props

    return (
        <div>
          <button onClick={this.toggleColor}>
            <div style={{ backgroundColor: this.state.color, height: '30px', width: '30px' }}></div>
          </button>
          {this.state.showPicker && <div>
            <TwitterPicker color={ this.state.color }
                onChangeComplete={this.handleChangeComplete}
                disableAlpha={true} width={250} />
          </div>}
          <FormError meta={meta} />
        </div>
    )
  }
}

export default ColorPicker
