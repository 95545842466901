/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import { messageList } from '../actions/message'

import InputArea from './InputArea'
import BusyMask from './BusyMask'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'

import { default_theme as theme } from '../emotion/theme'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const maxLength = max => value => 
    value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength600 = maxLength(600)

class MessageForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pn_warning: null,
            sms_warning: null,
        }
    }
    
    componentDidMount() {
      const { dispatch, message_id } = this.props
      dispatch(messageList.ensureObjectLoaded(message_id))
    }

    componentDidUpdate(prevProps) {
      const { dispatch, message, message_id } = this.props
      dispatch(messageList.ensureObjectLoaded(message_id))
      const { pn_warning } = this.state
      if (message && message.body && !pn_warning ) {
        this.updateWarnings(message.body)
      }
    }

    updateWarnings = (body) => {
        const char_count = body.length
        let sms_warning = 160 - char_count + ' characters left to fill 1 SMS message'
        if (char_count > 160) {
            const number_of_messages = Math.ceil(char_count / 153)
            sms_warning = ((number_of_messages * 153) - char_count) +
                          ' characters left to fill ' + number_of_messages + ' SMS messages'
        }
        const pn_warning = 600 - char_count + ' characters left in the message'
        this.setState({
            sms_warning: sms_warning,
            pn_warning: pn_warning,
        })
    }

    updateOnChange = (e) => {
        this.updateWarnings(e.target.value)
    }
    
    render() {
        const { 
            is_loading,
            error,
            handleSubmit,
            onBack,
            onPreview,
            message,
            message_id,
        } = this.props
        const {
            pn_warning,
            sms_warning,
        } = this.state
        
        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              { is_loading && <BusyMask>Loading...</BusyMask> }
              <div className="form-group" css={ field_container }>
                <label css={ label }>Message</label>
                <div>
                  <Field
                      name="body"
                      rows={5}
                      component={ InputArea }
                      validate={[required, maxLength600]}
                      onChange={ (e) => this.updateOnChange(e) }
                      maxLength={ 600 }
                  />
                </div>
                <div>{ pn_warning }</div>
                { (message && message.notified_by_sms > 0) &&
                  <div>{ sms_warning }</div>
                }
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Cancel"
                      type="button"
                      onClickButton={ onBack }
                  />
                </div>
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div css={ buttons }>
                  <div>
                    <PrimaryButton
                        label="Save Draft" 
                        type="submit"
                    ></PrimaryButton>
                  </div>
                  { message_id &&
                  <div css={ css`padding-left:20px;` }>
                    <PrimaryButton
                        label="Preview"
                        type="button"
                        onClickButton={ onPreview }
                    ></PrimaryButton>
                  </div>
                  }
                </div>
              </div>
            </form>
        )
    }
}
MessageForm = reduxForm({
    form: 'MESSAGE_FORM'
})(MessageForm);
function mapStateToProps(state, props) {
    const { message_id, onSubmit, onBack, onPreview } = props
    const message = messageList.getObject(message_id) || {}
    
    return {
        initialValues: {body: message.body, id: message.id},
        enableReinitialize: true,
        onSubmit,
        onBack,
        onPreview,
        is_loading: messageList.isLoading(),
        message,
        message_id,
    }
}
export default withRouter(connect(mapStateToProps)(MessageForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 50%;
display: flex;
flex-direction: column;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 50%;
height: 100px;
padding-top:20px;
`

const buttons = css`
display: flex;
`
