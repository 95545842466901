/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import { userList } from '../actions/user'

import InputField from './InputField'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'

const required = value => value ? undefined : 'Required'
const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined

class ProfileForm extends Component {

    componentDidMount() {
        const { dispatch, user } = this.props
        dispatch(userList.ensureObjectLoaded(user.id))
    }

    render() {
        const { handleSubmit, onCancelProfile, onRequestToken, error, user, is_loading } = this.props

        const otp_label = "Send OTP to: " + user.email
        
        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              { is_loading && <BusyMask /> }
              <div className="form-group" css={ field_container }>
                <label css={ label }>Email Address</label>
                <div>
                  <Field
                      type="text"
                      name="email"
                      validate={[email]}
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Password</label>
                <div>
                  <Field
                      type="password"
                      name="password"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <PrimaryButton
                    label={ otp_label }
                    type="button"
                    onClickButton={ onRequestToken }
                ></PrimaryButton>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Enter OTP</label>
                <div>
                  <Field
                      type="text"
                      name="otp"
                      validate={[required]}
                      component={ InputField }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Cancel"
                      type="button"
                      onClickButton={ onCancelProfile }
                  />
                </div>
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div>
                  <PrimaryButton
                      label="Save" 
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
ProfileForm = reduxForm({
    form: 'PROFILE_FORM'
})(ProfileForm);
function mapStateToProps(state, props) {
    const { onSubmit, onRequestToken, onCancelProfile, user_id } = props
    const user = userList.getObject(user_id) || {}
    return {
        onSubmit,
        onRequestToken,
        onCancelProfile,
        initialValues: user,
        enableReinitialize: true,
        user,
        is_loading: userList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(ProfileForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 90%;
display: flex;
flex-direction: column;
`

const error_container = css`
p {
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 90%;
padding-top:20px;
padding-bottom:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
