/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { getFormValues, SubmissionError } from 'redux-form'

import LoginForm from './LoginForm'

import { login, isLoggedIn, loggedInUser, sendForgotPasswordEmail } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import npo_connect_logo from '../images/npo-connect-landscape.png'


class Login extends Component {

    constructor(props) {
        super(props)
        this.state = { tokenError: null }
    }

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (has_usable_password) {
                history.push('/dashboard')
            } else {
                history.push('/first')
            }
        }
    }
    
    handleSubmit = (values) => {
        const { dispatch } = this.props
        if (!values || !values.email || !values.password) {
            throw new SubmissionError({_error: "Incomplete credentials provided"})
        } else {
            return dispatch(login(values.email, values.password))
        }
    }

    onRequestToken = () => {
        const { dispatch, history, loginFormValues } = this.props
        if (!loginFormValues || !loginFormValues.email) {
            this.setState({tokenError: 'No username entered'})
        } else {
            dispatch(sendForgotPasswordEmail(loginFormValues.email))
            this.setState({tokenError: null})
            history.push({
                pathname: '/token',
                state: { email: loginFormValues.email }
            })
        }
    }

    render() {
        const { tokenError } = this.state
        return (
            <div css={ main }>
              <div css={ container }>
                <div css={ header }>
                  <p css={ label }>Welcome to <img src={ npo_connect_logo } alt="" css={ image } /></p>
                </div>
                <LoginForm
                    onSubmit={ this.handleSubmit }
                    onRequestToken ={ this.onRequestToken }
                    tokenError={ tokenError }
                />
              </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
    }
    return {
        loginFormValues: getFormValues('LOGIN_FORM')(state),
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password
    }
}
export default withRouter(connect(mapStateToProps)(Login))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
`

const container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: 520px;
width: 100%;
background-color: ${theme.colors.white};
box-shadow: 10px 10px 30px 0px #888888;
`

const header = css`
text-align: center;
padding-top: 50px;
font-size: 24pt;
width: 100%;
`

const label = css`
color: #484a49;
font-weight: 600;
`

const image = css`
width: 80%;
`
