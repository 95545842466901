/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { capitalize, upperCase, head, split, remove, map, get, isEmpty } from 'lodash'
import moment from 'moment'
import { PDFDownloadLink }from '@react-pdf/renderer'
import ReactTimeout from 'react-timeout'

import MainLayout from './MainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import PrimaryButton from '../../components/PrimaryButton'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import MonthYearPicker from '../../components/MonthYearPicker'
import TransactionsPDF from '../../components/TransactionsPDF'
import BusyMask from '../../components/BusyMask'
import Select from 'react-select'

import { adminTransactionList, adminTransactionNoPaginationList } from '../actions/admin_transaction'
import { adminOrganisationList, adminOrganisationOptionList, adminOrganisationNoPaginationList } from '../actions/admin_organisation'
import { adminAccountList } from '../actions/admin_account'

import loading_icon from '../../images/loading.gif'

const SEARCH_DELAY_MILLISECONDS = 1000

class AdminTransactions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            start_date: null,
            display_generate_pdf: true,
            options_visible: false,
            no_options_message: 'No Options',
        }
    }
    
    componentDidMount() {
        const { dispatch, organisation_id } = this.props
        dispatch(adminOrganisationList.fetchListIfNeeded())
        dispatch(adminOrganisationOptionList.updateListFilter({name: 0, page: 1}))
        dispatch(adminOrganisationNoPaginationList.updateListFilter({all: true}))
        dispatch(adminOrganisationNoPaginationList.fetchListIfNeeded())
        dispatch(adminAccountList.updateListFilter({'organisation': organisation_id}))
        dispatch(adminAccountList.fetchListIfNeeded())
        dispatch(adminTransactionList.updateListFilter({'organisation': organisation_id}))
        dispatch(adminTransactionList.fetchListIfNeeded())
    }

    componentDidUpdate(prevProps) {
        const { dispatch, organisation_id } = this.props
        if (prevProps.organisation_id !== organisation_id) {
            dispatch(adminAccountList.updateListFilter({'organisation': organisation_id}))
            dispatch(adminAccountList.fetchListIfNeeded())
            dispatch(adminTransactionList.updateListFilter({'organisation': organisation_id}))
            dispatch(adminTransactionList.fetchListIfNeeded())
        }
        dispatch(adminTransactionList.fetchListIfNeeded())
    }

    componentWillUnmount() {
        clearTimeout(this.filter_timeout_id)
    }

    onChange = (option) => {
        const { history } = this.props
        history.push('/admin/transactions/' + option.value)
    }

    hideOptions = () => {
        this.setState({
            options_visible: false,
        })
    }

    showOptions = (value) => {
        const { dispatch } = this.props
        const that = this
        if ( this.filter_timeout_id ) {
            clearTimeout(this.filter_timeout_id)
            this.filter_timeout_id = null
            that.setState({
                no_options_message: 'Searching'
            })
        }
        this.filter_timeout_id = setTimeout(function () {
            const name = value === '' ? 0 : value
            dispatch(adminOrganisationOptionList.updateListFilter({name: name, page: 1}))
            dispatch(adminOrganisationOptionList.fetchListIfNeeded())
            that.setState({
                no_options_message: 'No Options'
            })
        }, SEARCH_DELAY_MILLISECONDS)
        const has_value = value === '' ? false : true
        this.setState({
            options_visible: has_value,
        })
    }

    getTransactionCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.updated_at} format='date' />
                )
            case 'type':
                return capitalize(item.type)
            case 'description':
                return capitalize(item.description)
            case 'method':
                return upperCase(item.payment_method)
            case 'debit':
                return item.debit_amount
            case 'credit':
                return item.credit_amount
            case 'action':
                return (
                    <PrimaryButton onClickButton={(e) => this.onEditTransaction(e, item.id)}
                                   label="Edit">
                    </PrimaryButton>
                )
            default:
                return (
                    <span>"No idea"</span>
                )
        }
    }

    onFilterByDate = (value) => {
        const { dispatch } = this.props
        dispatch(adminTransactionList.updateListFilter({'month_created': moment(value).format('YYYY-MM-DD'), 'page': 1}))
    }

    onClearDate = () => {
        const { dispatch, organisation_id } = this.props
        dispatch(adminTransactionList.clearListFilter())
        dispatch(adminTransactionList.updateListFilter({'organisation': organisation_id}))
    }

    onGeneratePdf = () => {
        const { dispatch, transaction_filter } = this.props
        dispatch(adminTransactionNoPaginationList.invalidateList())
        dispatch(adminTransactionNoPaginationList.updateListFilter(Object.assign({all: true}, transaction_filter)))
        dispatch(adminTransactionNoPaginationList.fetchListIfNeeded()).then(() => {
            this.setState({
                display_generate_pdf: false,
            }, () => ReactDOM.render(this.renderDownload(), document.getElementById('button'))
            )
        })
    }

    renderDownload = () => {
        const { transactions_no_pagination, organisation } = this.props
        const { display_generate_pdf } = this.state
        return (
            <div>
              { !display_generate_pdf &&
                <div css={css`a {color:black; :hover {text-decoration:none;}}`}
                     className="btn btn-light"
                     onClick={ this.toggleGeneratePdf }>
                  <PDFDownloadLink
                      document={
                          <TransactionsPDF
                              items={transactions_no_pagination}
                                    organisation_name={organisation.name}
                          />
                      }
                      fileName={`${organisation.mobile_url}-${moment().format('DD-MM-YYYY-HH-mm-ss')}.pdf`}>
                    {
                        ({ blob, url, loading, error }) => (loading ? <img
                                                                          alt="loading"
                                                                          src={ loading_icon }
                                                                          css={css`height:20px;width:auto;`}
                        /> : 'Download PDF')
                    }
                  </PDFDownloadLink>
                </div>
              }
            </div>
        )
    }

    toggleGeneratePdf = () => {
        const { display_generate_pdf } = this.state
        this.setState({
            display_generate_pdf: !display_generate_pdf,
        })
    }

    onAddTransaction = () => {
        const { history, organisation_id } = this.props
        history.push('/admin/transaction/' + organisation_id)
    }

    onEditTransaction = (e, transaction_id) => {
        const { history, organisation_id } = this.props
        history.push('/admin/transaction/' + organisation_id + '/' + transaction_id)
    }

    render() {
        const {
            organisations_no_pagination,
            organisation_id,
            organisation,
            service_account,
            subscription_account,
            messaging_account,
            transactions,
            transaction_filter,
            transaction_headers,
            is_loading,
            is_busy,
        } = this.props
        const { start_date, display_generate_pdf } = this.state

        const options = map(organisations_no_pagination, organisation => {
            return {value: organisation.id, label: organisation.name}
        })
        let option
        if (!isEmpty(organisation)) {
            option = {value: organisation.id, label: organisation.name}
        }

        return (
            <MainLayout title="Transactions">
              { is_busy && <BusyMask /> }
              <div css={ container }>
                <div css={ accounts_container }>
                  <div css={ row }>
                    <span css={ cell }>NPO</span>
                    <span css={ cell }>
                      <Select
                          value={ option }
                          options={ options }
                          onChange={ this.onChange }
                          onInputChange={(value) => this.showOptions(value)}
                          css={ full_width }
                      />
                    </span>
                  </div>
                  { organisation_id &&
                    <div css={ full_width }>
                      <div css={ row }>
                        <span css={ [cell, css`flex:2`] }>
                          Topup Messaging Funds Balance
                        </span>
                        <span css={ [cell, css`flex:1`] }>
                          {messaging_account && `R ${messaging_account.parsed_balance}`}
                        </span>
                        <span css={ [cell, css`flex:1`] }>
                          { messaging_account && messaging_account.balance_rands > 0 ? 'Available' : 'No Funds'}
                        </span>
                      </div>
                      <div css={ row }>
                        <span css={ [cell, css`flex:2`] }>
                          Subscription Funds Balance
                        </span>
                        <span css={ [cell, css`flex:1`] }>
                          {subscription_account && `R ${subscription_account.parsed_balance}`}
                        </span>
                        <span css={ [cell, css`flex:1`] }>
                          {subscription_account && subscription_account.balance_rands > 0 ? 'Credit' : 'Owed'}
                        </span>
                      </div>
                      <div css={ row }>
                        <span css={ [cell, css`flex:2`] }>
                          Services Funds Balance
                        </span>
                        <span css={ [cell, css`flex:1`] }>
                          {service_account && `R ${service_account.parsed_balance}`}
                        </span>
                        <span css={ [cell, css`flex:1`] }>
                          {service_account && service_account.balance_rands > 0 ? 'Credit' : 'Owed'}
                        </span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              { organisation_id &&
                <div css={ container }>
                  <TableHeader>
                    <div css={ header }>
                      <MonthYearPicker
                          value={ start_date }
                          onChange={ this.onFilterByDate }
                          onClear={ this.onClearDate }
                      />
                      <div css={ css`display:flex;` }>
                        { !display_generate_pdf ?
                          <div id="button"></div>
                          :
                          <DefaultButton
                              label="Generate PDF"
                              type="button"
                              light={ true }
                              onClickButton={ this.onGeneratePdf }
                          />
                        }
                        <div css={ css`padding-left:15px;` } >
                          <DefaultButton
                              label="Add Transaction"
                              type="button"
                              light={ true }
                              onClickButton={ this.onAddTransaction }
                          />
                        </div>
                      </div>
                    </div>
                  </TableHeader>
                  <CommonTable
                      is_loading={ is_loading }
                      empty_message="There are no transactions."
                      headers={ transaction_headers }
                      items={ transactions }
                      getCellValue={ this.getTransactionCellValue }
                  />
                  <TableFooter>
                    <div css={ pagination_container }>
                      <Pagination listKey={adminTransactionList.listKey} filters={ transaction_filter } />
                    </div>
                  </TableFooter>
                </div>
              }
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const organisation_id = get(props, ["match", "params", "organisation_id"], null)
    const organisation = adminOrganisationList.getObject(organisation_id) || {}
    const organisations_no_pagination = adminOrganisationNoPaginationList.getVisibleObjects() || []
    const accounts = adminAccountList.getVisibleObjects() || []
    const transactions = adminTransactionList.getVisibleObjects() || []
    const transactions_no_pagination = adminTransactionNoPaginationList.getVisibleObjects() || []
    let messaging_account = remove(accounts, (account) => {
        return split(account.name, '_')[1] === 'messaging'
    })
    let subscription_account = remove(accounts, (account) => {
        return split(account.name, '_')[1] === 'subscriptions'
    })
    let service_account = remove(accounts, (account) => {
        return split(account.name, '_')[1] === 'services'
    })
    return {
        organisation,
        organisation_id,
        organisations_no_pagination,
        transactions,
        transactions_no_pagination,
        transaction_filter: adminTransactionList.getFilter(),
        messaging_account: head(messaging_account),
        subscription_account: head(subscription_account),
        service_account: head(service_account),
        is_loading: adminTransactionList.isLoading(),
        transaction_headers: {
            created_at: { name: 'Date', column_size: 2 },
            type: { name: 'Type', column_size: 2 },
            description: { name: 'Description', column_size: 4 },
            method: { name: 'Payment Method', column_size: 2 },
            debit: { name: 'Debit', column_size: 2 },
            credit: { name: 'Credit', column_size: 2 },
            action: { name: 'Options', column_size: 1 },
        },
        is_busy: adminTransactionList.isLoading() ||
                 adminTransactionNoPaginationList.isLoading() ||
                 adminAccountList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(ReactTimeout(AdminTransactions)))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 10px;
`

const header = css`
display: flex;
justify-content: space-between;
width: 100%;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const accounts_container = css`
width: 50%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 10px;
`

const row = css`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
border-bottom: 1px solid #e6e6e6;
`

const cell = css`
display: flex;
padding: 5px;
justify-content: flex-start;
width: 100%;
`

const full_width = css`
width: 100%;
`
