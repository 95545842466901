/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'

class DashboardFooter extends Component {
    
    render() {
        const privacy_policy_url = window.LOCAL_SETTINGS.API_BASE.split("api/v1")[0]
                                 + 'static/NPOconnect_Privacy_Policy.pdf'
        const acceptable_use_policy_url = window.LOCAL_SETTINGS.API_BASE.split("api/v1")[0]
                                        + 'static/NPOconnectAUP.pdf'

        return (
            <div css={ footer }>
              <div css={ policy_container }>
                <a href={privacy_policy_url}
                   css={ link }
                   target={ "_blank" }>PRIVACY POLICY</a>
              </div>
              <div css={ policy_container }>
                <a href={acceptable_use_policy_url}
                   css={ link }
                   target={ "_blank" }>ACCEPTABLE USE POLICY</a>
              </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    return {}
}
export default connect(mapStateToProps)(DashboardFooter)

const footer = css`
display: flex;
flex-direction: row;
width: 100%;
height: 100px;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
`

const policy_container = css`
`

const link = css`
color: ${theme.colors.gray5};

&:hover {
color: ${theme.colors.primary};
}
`
