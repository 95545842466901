/** @jsx jsx */
import { Component } from "react";
import { css, jsx } from "@emotion/core";
import { has } from 'lodash'
import { default_theme as theme } from '../emotion/theme'

class EventAttendenceCount extends Component {

  render() {
    const { event, title } = this.props;
    if (has(event, "num_followers_confirmed")) {
      return (
        <div>
          <p css={label_style}>{title}</p>
          <table className="eventInfo_table_style">
            <tbody>
              <tr>
                <td css={table_cell_style}>
                  <b>Going</b>
                </td>
                <td css={table_cell_style}>{event.num_followers_confirmed}</td>
                <td css={table_cell_style}>
                  <b>Maybe</b>
                </td>
                <td css={table_cell_style}>{event.num_followers_maybe}</td>
                <td css={table_cell_style}>
                  <b>Rejected</b>
                </td>
                <td css={table_cell_style}>{event.num_followers_rejected}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }else{
      return null
    }
  }
}
export default EventAttendenceCount;

const label_style = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
const table_cell_style = css`
border: 1px solid;
padding: 5px 10px;
`