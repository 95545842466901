import { ItemList } from '../orm'
import { ENTITY_KEY__TRANSACTION, LIST_KEY__TRANSACTION } from './ItemListKeyRegistry'

class TransactionList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__TRANSACTION
    }
}

export const transactionList = new TransactionList(LIST_KEY__TRANSACTION)

class TransactionNoPaginationList extends ItemList {
    getEntityKey() {
        return 'transaction_no_pagination'
    }
}

export const transactionNoPaginationList = new TransactionNoPaginationList('transaction_no_pagination')
