/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, has } from 'lodash'
import { reset, getFormValues, isDirty, isValid, submit, isPristine } from 'redux-form'

import { eventList } from '../actions/event'

import MainLayout from './MainLayout'
import EventForm from './EventForm'
import BusyMask from './BusyMask'
import Modal from './Modal'

import npo_icon from '../images/npo-connect.png'

class Event extends Component {

    constructor(props) {
        super(props)
        this.state = {
            action: null,
            display_will_save_modal: false,
            display_did_save_modal: false,
        }
    }
    
    componentDidMount() {
        const { dispatch, event_id } = this.props
        dispatch(eventList.invalidateList())
        dispatch(eventList.ensureObjectsLoaded([event_id]))
    }
    
    handleSubmit = (values) => {
        const { dispatch, history } = this.props
        let on_done
        const that = this
        if (!has(values, 'id')) {
            values.isNewInstance = true
            on_done = function(json) {
                that.toggleDidSaveModal()
                history.push('/event')
            }
        } else {
            on_done = function() {
                that.toggleDidSaveModal()
            }
        }
        return dispatch(eventList.saveObject(values, on_done))
    }

    handleBack = () => {
        const { is_pristine } = this.props
        if (is_pristine) {
            this.backToEvents()
        } else {
            this.setState({
                action: 'back',
            })
            this.toggleWillSaveModal()
        }
    }

    backToEvents = () => {
        const { dispatch, history } = this.props
        dispatch(reset('EVENT_FORM'))
        history.push('/events')
    }

    toggleWillSaveModal = () => {
        const { display_will_save_modal } = this.state
        this.setState({
            display_will_save_modal: !display_will_save_modal,
        })
    }

    renderWillSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Save Changes"
                onActionModal={ this.handleWillSave }
                actionLabel="Save"
                onCloseModal={ this.handleWillNotSave }
            >
              <div css={ modal_container_style }>
                <span>Save changes made to event?</span>
              </div>
            </Modal>
        )
    }

    handleWillSave = () => {
        const { dispatch } = this.props
        this.toggleWillSaveModal()
        dispatch(submit('EVENT_FORM'))
    }

    handleWillNotSave = () => {
        this.toggleWillSaveModal()
        this.backToEvents()
    }

    toggleDidSaveModal = () => {
        const { display_did_save_modal } = this.state
        this.setState({
            display_did_save_modal: !display_did_save_modal
        })
    }

    renderDidSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Event Saved"
              onActionModal={ this.handleDidSave }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }

    handleDidSave = () => {
      this.toggleDidSaveModal()
      this.backToEvents()
    }

    clearAction = () => {
        this.setState({
            action: null
        })
    }

    render() {
        const { event_id, is_busy} = this.props
        const { display_will_save_modal, display_did_save_modal } = this.state
        let title = 'Add Event'
        if (event_id) {
            title = 'Edit Event'
        }
        return (
            <MainLayout title={ title }>
              { is_busy && <BusyMask /> }
              <EventForm
                  onSubmit={ this.handleSubmit }
                  event_id={ event_id }
                  onBack={ this.handleBack }
                  onSubmitFail={ this.clearAction }
              />
              { display_will_save_modal && this.renderWillSaveModal() }
              { display_did_save_modal && this.renderDidSaveModal() }
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const event_id = get(props, ["match", "params", "event_id"], null)
    const event = eventList.getObject(event_id) || {}
    return {
        eventFormValues: getFormValues('EVENT_FORM')(state),
        dirty: isDirty('EVENT_FORM')(state),
        isArticleFormValid: isValid('EVENT_FORM')(state),
        event,
        event_id,
        is_pristine: isPristine('EVENT_FORM')(state),
        is_busy:
                 eventList.isLoading() ||
                 eventList.getIsSavingObject() 
    }
}
export default connect(mapStateToProps)(Event)

const modal_container_style = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`
