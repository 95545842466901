/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'
import { isEmpty, map, upperCase, capitalize } from 'lodash'

import { adminOrganisationOptionList, adminOrganisationList } from '../actions/admin_organisation'
import { adminTransactionList } from '../actions/admin_transaction'

import InputField from '../../components/InputField'
import InputArea from '../../components/InputArea'
import Dropdown from '../../components/Dropdown'
import DropdownFetch from '../../components/DropdownFetch'
import DefaultButton from '../../components/DefaultButton'
import PrimaryButton from '../../components/PrimaryButton'
import BusyMask from '../../components/BusyMask'

import { default_theme as theme } from '../../emotion/theme'


const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const currency = value =>
    value && !/^([+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$)?$/.test(value) ?
    'Invalid currency' : undefined

class AdminTransactionForm extends Component {
    
    componentDidMount() {
        const { dispatch, transaction_id, organisation_id } = this.props
        dispatch(adminTransactionList.fetchListIfNeeded())
        dispatch(adminTransactionList.ensureObjectLoaded(transaction_id))
        dispatch(adminOrganisationList.fetchListIfNeeded())
        dispatch(adminOrganisationList.ensureObjectLoaded(organisation_id))
        dispatch(adminOrganisationOptionList.clearListFilter())
        dispatch(adminOrganisationOptionList.updateListFilter({all: true}))
        dispatch(adminOrganisationOptionList.fetchListIfNeeded())
        dispatch(adminOrganisationList.fetchListIfNeeded())
    }

    componentDidUpdate(prevProps) {
        const { dispatch } = this.props
        dispatch(adminOrganisationList.fetchListIfNeeded())
    }

    fetchOptions = (value) => {
        const { dispatch } = this.props
        const name = value === '' ? 0 : value
        dispatch(adminOrganisationOptionList.updateListFilter({name: name, page: 1}))
        dispatch(adminOrganisationOptionList.fetchListIfNeeded())
    }

    render() {
        const { handleSubmit, onCancel, error, is_busy, organisations, organisation_list_is_loading } = this.props

        const payment_options = [
            {value: 'cash', label: 'CASH'},
            {value: 'card', label: 'CARD'},
            {value: 'eft', label: 'EFT'},
            {value: 'invoice', label: 'INVOICE'},
        ]

        const type_options = [
            {value: 'subscriptions', label: 'Subscriptions'},
            {value: 'messaging', label: 'Messaging'},
            {value: 'services', label: 'Services'},
        ]

        const npo_options = map(organisations, (organisation) => {
            return {value: organisation.id, label: capitalize(organisation.name)}
        })

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              { is_busy && <BusyMask /> }
              <div className="form-group" css={ field_container }>
                <label css={ label }>Type</label>
                <div>
                  <Field
                      name="type"
                      component={ Dropdown }
                      options={ type_options }
                      validate={ [required] }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Amount (ZAR)</label>
                <div>
                  <Field
                      type="text"
                      name="amount_rands"
                      component={ InputField }
                      validate={[required, currency]}
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Description</label>
                <div>
                  <Field
                      name="description"
                      rows={2}
                      component={ InputArea }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Payment Method</label>
                <div>
                  <Field
                      name="method"
                      component={ Dropdown }
                      options={ payment_options }
                      validate={ [required] }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>NPO</label>
                <div>
                  <Field
                      name="organisation"
                      component={ DropdownFetch }
                      options={ npo_options }
                      validate={ [required] }
                      fetchOptions={ this.fetchOptions }
                      is_searching={ organisation_list_is_loading }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Cancel"
                      type="button"
                      onClickButton={ onCancel }
                  />
                </div>
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div>
                  <PrimaryButton
                      label="Save" 
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
AdminTransactionForm = reduxForm({
    form: 'ADMIN_TRANSACTION_FORM'
})(AdminTransactionForm);
function mapStateToProps(state, props) {
    const { onSubmit, onCancel, transaction_id, organisation_id  } = props
    let transaction = adminTransactionList.getObject(transaction_id) || {}
    const organisations = adminOrganisationOptionList.getVisibleObjects()
    let initialValues = {}
    const organisation = adminOrganisationList.getObject(organisation_id) || {}
    if (transaction_id && !isEmpty(transaction)) {
        initialValues = Object.assign({},
                                      transaction,
                                      {method: {value: transaction.payment_method,
                                                label: upperCase(transaction.payment_method)}},
                                      {type: {value: transaction.type,
                                              label: capitalize(transaction.type)}},
                                      {organisation: {value: organisation.id, label: capitalize(organisation.name)}})
    } else {
        initialValues = Object.assign({},
                                      {method: {value: 'eft', label: 'EFT'}},
                                      {type: null},
                                      {organisation: {value: organisation.id, label: capitalize(organisation.name)}})
    }
    return {
        onSubmit,
        onCancel,
        transaction,
        transaction_id,
        organisations,
        organisation_id,
        initialValues,
        enableReinitialize: true,
        is_busy: adminTransactionList.isLoading() ||
                 adminOrganisationList.isLoading(),
        organisation_list_is_loading: adminOrganisationList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminTransactionForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
