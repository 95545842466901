/** @jsx jsx */
import { Component } from 'react'
import { jsx } from '@emotion/core'

import FormError from './FormError'
import FormWarning from './FormWarning'

class InputArea extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            tabIndex,
            maxLength,
            rows,
            meta,
            ...extraProps
        } = this.props
            
        return (
            <div>
              <textarea {...input}
                        {...extraProps}
                        placeholder={placeholder}
                        css={[extra_class]}
                        rows={rows}
                        maxLength={maxLength}
                        className="form-control"
              />
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}
export default InputArea
