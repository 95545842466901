/** @jsx jsx */
import { Component } from 'react'
import { jsx } from '@emotion/core'

import FormError from './FormError'
import FormWarning from './FormWarning'

class InputField extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            type,
            tabIndex,
            maxLength,
            meta,
            ...extraProps
        } = this.props
        return (
            <div>
              <input {...input}
                     {...extraProps}
                     placeholder={placeholder}
                     css={[extra_class]}
                     type={type}
                     maxLength={maxLength}
                     className="form-control"
              />
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}
export default InputField;
