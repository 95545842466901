/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, isEmpty } from 'lodash'
import moment from 'moment'

import { dashboardList } from '../actions/dashboard'

import MainLayout from './MainLayout'
import DashboardItem from './DashboardItem'
import DashboardItemLarge from './DashboardItemLarge'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import public_dark from '../images/public_outline_dark.png'
import article_dark from '../images/article_add_outline_dark.png'
import publish_dark from '../images/publish_outline_dark.png'
import receipt_dark from '../images/receipt_outline_dark.png'
import payment_dark from '../images/payment_outline_dark.png'
import contact_dark from '../images/contact_outline_dark.png'
import sms_dark from '../images/sms_outline_dark.png'
import notification_dark from '../images/notification_outline_dark.png'
import funds_dark from '../images/funds_outline_dark.png'
import error_dark from '../images/error_outline_dark.png'
import { organisationList } from '../actions/organisation'

class Dashboard extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(dashboardList.invalidateList())
        dispatch(dashboardList.fetchListIfNeeded())
    }

    formatDate = (date) => {
        const ts = moment(date)
        return ts.format('DD MMM YYYY')
    }

    dateExpired = (date) => {
        return moment(date).isBefore(new Date())
    }

    renderAccountFunds = () => {
        const { data, organisation } = this.props
        let account_standing_color
        if (this.dateExpired(data.next_payment_due_at) == true) {
            account_standing_color = "red"
        }else if (data.next_payment_due_at === null){
            account_standing_color = theme.colors.logo_2
        }else if (this.dateExpired(data.next_payment_due_at) === false) {
            account_standing_color = theme.colors.logo_3
        }else {
            console.error("Unable to assign account standing color")
        }
        return (
            <div>
                <div css={ column_container }>
                  <DashboardItemLarge
                      background_colour={ account_standing_color }
                      icon1={ receipt_dark }
                      stat1={ isEmpty(data.last_payment_made_at) ? 'Never' : this.formatDate(data.last_payment_made_at) }
                      text1="Last payment made"
                      icon2={ payment_dark }
                      stat2={ isEmpty(data.next_payment_due_at) ? 'Paid' : this.formatDate(data.next_payment_due_at) }
                      text2={ account_standing_color === theme.colors.logo_2 ? "No payment due" : "Next payment due"}
                      alert_icon={ error_dark }
                      alert={ account_standing_color === "red" ? true : false }
                  />
                  <DashboardItem
                      background_colour={ theme.colors.logo_3 }
                      icon={ error_dark }
                      stat={ "" || organisation.subscriptions_balance}
                      text="Subscription Balance"
                  />
                  <DashboardItem
                      background_colour={ theme.colors.logo_4 }
                      icon={ funds_dark }
                      stat={ `R ${data.message_funds_balance}` }
                      text="Message Funds Balance"
                  />
                </div>
            </div>
        )
    }
    
    render() {
        const { data, is_busy } = this.props
        return (
            <MainLayout title='Dashboard'>
              { is_busy && <BusyMask /> }
              <div css={ dashboard }>
                <div css={ column_container }>
                  <DashboardItem
                      background_colour={ theme.colors.logo_1 }
                      icon={ public_dark }
                      stat={ data.active_articles }
                      text={ data.active_articles === 1 ?
                             "Active news article" :
                             "Active news articles" }
                  />
                  <DashboardItem
                      background_colour={ theme.colors.logo_1 }
                      icon={ article_dark }
                      stat={ data.articles_created_this_month }
                      text={ data.articles_created_this_month === 1 ?
                             "News article created this month" :
                             "News articles created this month" }
                  />
                  <DashboardItem
                      background_colour={ theme.colors.logo_1 }
                      icon={ publish_dark }
                      stat={ data.articles_published_this_month }
                      text={ data.articles_published_this_month === 1 ?
                             "News article published this month" :
                             "News articles published this month" }
                  />
                </div>
                <div css={ column_container }>
                  <DashboardItem
                      background_colour={ theme.colors.logo_2 }
                      icon={ contact_dark }
                      stat={ data.contacts }
                      text={ data.contacts === 1 ?
                             "Contact" :
                             "Contacts" }
                  />
                  <DashboardItem
                      background_colour={ theme.colors.logo_2 }
                      icon={ sms_dark }
                      stat={ data.text_messages_sent }
                      text={ data.text_messages_sent === 1 ?
                             "Text message sent this month" :
                             "Text messages sent this month" }
                  />
                  <DashboardItem
                      background_colour={ theme.colors.logo_2 }
                      icon={ notification_dark }
                      stat={ data.push_notifications_sent }
                      text={ data.push_notifications_sent === 1 ?
                             "Push notification sent this month" :
                             "Push notifications sent this month" }
                  />
                </div>
                {!is_busy && this.renderAccountFunds()}
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state) {
    const data = head(dashboardList.getVisibleObjects()) || {}
    const organisation = head(organisationList.getVisibleObjects()) || {}
    return {
        data,
        organisation,
        is_busy: dashboardList.isLoading() || organisationList.isLoading(),
    }
}
export default connect(mapStateToProps)(Dashboard)

const dashboard = css`
display: flex;
flex-wrap: wrap;
`
const column_container = css`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`
