import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import TokenSent from './TokenSent'
import Login from './Login'
import NewPassword from './NewPassword'
import Dashboard from './Dashboard'
import Content from './Content'
import Profile from './Profile'
import NewEmail from './NewEmail'
import Users from './Users'
import User from './User'
import News from './News'
import NewsArticle from './NewsArticle'
import Groups from './Groups'
import Group from './Group'
import Events from './Events'
import Event from './Event'
import EventReminder from './EventReminder'
import Messaging from './Messaging'
import Message from './Message'
import Transactions from './Transactions'
import TwitterConfirmation from './TwitterConfirmation'
import AdminDashboard from '../admin/components/AdminDashboard'
import Organisations from '../admin/components/Organisations'
import AdminOrganisation from '../admin/components/AdminOrganisation'
import AdminTransactions from '../admin/components/AdminTransactions'
import AdminTransaction from '../admin/components/AdminTransaction'
import Subscriptions from '../admin/components/Subscriptions'

import { isLoggedIn, loggedInUser } from '../actions/auth'

class AuthRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            }
            else if (has_usable_password) {
                history.push('/dashboard')
            } else {
                history.push('/first')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/login')
        }
    }
    
    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  <Switch>
                    <Route path="/token" exact={ true } component={ TokenSent } />
                    <Route path="/" component={ Login } />
                  </Switch>
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/first" exact={ true } component={ NewPassword } />
                <Route path="/dashboard" exact={ true } component={ Dashboard } />
                <Route path="/content" exact={ true } component={ Content } />
                <Route path="/profile" exact={ true } component={ Profile } />
                <Route path="/email/:new_email" component={ NewEmail } />
                <Route path="/users" exact={ true } component={ Users } />
                <Route path="/user/:follower_id" component={ User } />
                <Route path="/user" exact={ true } component={ User } />
                <Route path="/news" exact={ true } component={ News } />
                <Route path="/article/:article_id" component={ NewsArticle } />
                <Route path="/article" exact={ true } component={ NewsArticle } />
                <Route path="/groups" exact={ true } component={ Groups } />
                <Route path="/group/:group_id" component={ Group } />
                <Route path="/events" exact={ true } component={ Events } />
                <Route path="/event" exact={ true } component={ Event } />
                <Route path="/event/:event_id" exact={ true } component={ Event } />
                <Route path="/event_reminder/:message_id" component={ EventReminder } />
                <Route path="/group" exact={ true } component={ Group } />
                <Route path="/messaging" exact={ true } component={ Messaging } />
                <Route path="/message/:message_id" component={ Message } />
                <Route path="/message" exact={ true } component={ Message } />
                <Route path="/transactions" exact={ true } component={ Transactions } />
                <Route path="/twitter_confirmation" component={ TwitterConfirmation } />
                <Route path="/admin/dashboard" exact={ true } component={ AdminDashboard } />
                <Route path="/admin/organisations" exact={ true } component={ Organisations } />
                <Route path="/admin/organisation/admin/:organisation_id"
                       component={ (props) => <AdminOrganisation page="admin" {...props} /> } />
                <Route path="/admin/organisation/content/:organisation_id"
                       component={ (props) => <AdminOrganisation page="content" {...props} /> } />
                <Route path="/admin/organisation" exact={ true } component={ AdminOrganisation } />
                <Route path="/admin/transactions/:organisation_id" component={ AdminTransactions } />
                <Route path="/admin/transactions" exact={ true } component={ AdminTransactions } />
                <Route path="/admin/transaction/:organisation_id/:transaction_id" component={ AdminTransaction } />
                <Route path="/admin/transaction/:organisation_id" component={ AdminTransaction } />
                <Route path="/admin/subscriptions" exact={ true } component={ Subscriptions } />
                <Route path="/" component={ is_superuser ? AdminDashboard : Dashboard } />
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
    }
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
