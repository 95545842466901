/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import DefaultButton from './DefaultButton'

import { default_theme as theme } from '../emotion/theme'

class PrimaryButton extends Component {
    render = () => {
        return (
            <DefaultButton {...this.props} extra_class={ [this.props.extra_class, primary] }>{this.props.children}</DefaultButton>
        )
    }

}
export default PrimaryButton

const primary = css`
background-color: ${theme.colors.primary};
color: ${theme.colors.white};
transition: background-color 0.15s ease-in-out;
&:hover {
background-color: ${theme.colors.secondary};
}
`
