/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'
import { isEmpty } from 'lodash'

import { organisationList } from '../actions/organisation'
import { imageList, imageCropList } from '../actions/image'

import InputField from './InputField'
import InputArea from './InputArea'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'
import FileUploader from './FileUploader'
import ImageCropper from './ImageCropper'
import ToolTip from './ToolTip'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import placeholder from '../images/placeholder_dark.png'
import RadioSelectField from '../components/RadioSelectField'

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const charCount = max => value => `${value ? value.length : 0}/${max}`
const maxLength300 = maxLength(300)
const charCount300 = charCount(300)
const tel = value =>
  value && !(/^\d{7,15}$/).test(value.replace(/[\s()+\-\.]|ext/gi, '')) ?
    'Invalid Phone Number' : undefined
const hex = value => value && !/^#(?:[0-9a-f]{3}){1,2}$/i.test(value) ? 'Invalid Hex Code' : undefined

class ContentForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      display_image_cropper: false,
      image_id: null,
    }
  }

  componentDidMount() {
    const { dispatch, organisation_id, image_id } = this.props
    dispatch(organisationList.ensureObjectLoaded(organisation_id))
    dispatch(imageList.ensureObjectLoaded(image_id))
  }

  componentDidUpdate() {
    const { dispatch } = this.props
    dispatch(imageList.fetchListIfNeeded())
  }

  handleCrop = (crop) => {
    const { dispatch, newImageFile, updateNewImageFile, organisation_id } = this.props
    const { image_id } = this.state
    const that = this
    const on_done = function (json) {
      dispatch(imageList.invalidateList())
      if (newImageFile) {
        updateNewImageFile(json.fileinfo)
      }
      that.toggleImageCropper()
    }
    return dispatch(imageCropList.update({ image_id: image_id, crop: crop, organisation_id: organisation_id }, on_done))
  }

  renderImageCropper = () => {
    const { image_id } = this.state
    const { organisation } = this.props
    return (
      <ImageCropper
        image_id={image_id}
        onCrop={this.handleCrop}
        onCancel={this.toggleImageCropper}
        background={organisation.mobile_logo_background_colour_hex}
      />
    )
  }

  toggleImageCropper = () => {
    const { newImageFile, image } = this.props
    const { display_image_cropper } = this.state
    this.setState({
      image_id: newImageFile ? newImageFile.id : image.id,
      display_image_cropper: !display_image_cropper,
    })
  }

  render() {
    const { 
      handleSubmit,
      onCancelContent,
      onSuccess,
      onFailure,
      error,
      upload_relative_url,
      organisation,
      image,
      newImageFile,
      is_busy,
    } = this.props
    const { display_image_cropper } = this.state
    const foreground_options = [
      {name: 'Dark', id: true},
      {name: 'Light', id: false}
  ]

    return (
      <form css={form_layout} onSubmit={handleSubmit}>
        {is_busy && <BusyMask />}
        <div css={column_container}>
          <div className="form-group" css={field_container}>
            <label css={label}>Logo</label>
            <div css={logo}>
              <div css={thumbnail}>
                <div css={image_container}>
                  {newImageFile &&
                    <img src={newImageFile.thumbnail_download_url} alt="" css={image_src} />

                  }
                  {!isEmpty(image) && !newImageFile &&
                    <img src={image.thumbnail_download_url} alt="" css={image_src} />
                  }
                  {isEmpty(image) && !newImageFile &&
                    <img src={placeholder} alt="" css={image_src} />
                  }
                </div>
                <FileUploader
                  upload_relative_url={upload_relative_url}
                  upload_params={{ organisation_id: organisation.id }}
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  label="Upload"
                  file_types=".jpg, .png, .jpeg"
                  class_name="btn"
                />
              </div>
              <div css={details}>
                <div>
                  <p css={image_text}>
                    Please upload your logo as at least a 512x512 pixel square image. If your logo is not square, please upload a larger image and use the crop button to select a square-shaped size for the logo in your mobile app.
                  </p>
                </div>
                <div css={buttons}>
                  {(newImageFile || (!isEmpty(image) && !newImageFile)) &&
                    <PrimaryButton
                      label="Crop"
                      type="button"
                      onClickButton={this.toggleImageCropper}
                    ></PrimaryButton>
                  }
                  {(newImageFile || (!isEmpty(image) && !newImageFile)) &&
                    <a target="_blank" rel="noopener noreferrer" href={newImageFile ? newImageFile.thumbnail_download_url : image.thumbnail_download_url} css={link_button} className="btn">View</a>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>Name</label>
            <div>
              <Field
                type="text"
                name="name"
                component={InputField}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>Header</label>
            <div>
              <Field
                type="text"
                name="header"
                component={InputField}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>Mission Statement</label>
            <div>
              <Field
                name="mission"
                rows={3}
                component={InputArea}
                validate={[maxLength300]}
                warn={[charCount300]}
                maxLength={300}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>Address</label>
            <div>
              <Field
                name="address"
                rows={3}
                component={InputArea}
              />
            </div>
          </div>
          <div className="form-group" css={ field_container }>
            <label css={ label }>Phone Number</label>
            <div>
              <Field
                type="text"
                name="phone_number"
                component={ InputField }
                validate={ tel }
              />
            </div>
          </div>
          <div className="form-group" css={ field_container }>
            <label css={ label }>Website URL</label>
            <div>
              <Field
                type="text"
                name="website"
                component={ InputField }
              />
            </div>
          </div>
        </div>
        <div css={column_container}>
          <div className="form-group" css={field_container}>
            <div css={row}>
              <label css={label}>Twitter Handle</label>
              <ToolTip>
                This may be obtained by visiting your Twitter profile page and looking right beneath your name.
              </ToolTip>
            </div>
            <div>
              <Field
                type="text"
                name="twitter"
                placeholder="@npoconnect"
                component={InputField}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <div css={row}>
              <label css={label}>Facebook Page URL</label>
              <ToolTip>
                This may be obtained by visiting your Facebook page and looking at your browser's address bar.
              </ToolTip>
            </div>
            <div>
              <Field
                type="text"
                name="facebook"
                placeholder="https://www.facebook.com/NpoConnect-596483697149660/"
                component={InputField}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>Email address</label>
            <div>
              <Field
                type="text"
                name="email"
                validate={[email]}
                component={InputField}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>NPO Registration Number</label>
            <div>
              <Field
                type="text"
                name="registration_number"
                component={InputField}
              />
            </div>
          </div>
          <div className="form-group" css={field_container}>
            <label css={label}>Donation Bank Details</label>
            <div>
              <Field
                name="bank_details"
                rows={3}
                component={InputArea}
              />
            </div>
          </div>
          <div className="form-group" css={ field_container }>
                    <label css={ label }>Logo Background Colour</label>
                    <div>
                      <Field
                          type="text"
                          name="mobile_logo_background_colour_hex"
                          component={ InputField }
                          validate={ [hex] }
                      />
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label_center }>Primary Colour</label>
                    <div css={ row }>
                      <div css={ cell }>
                        <label css={ label }>Background</label>
                        <Field
                            type="text"
                            name="mobile_primary_colour_hex"
                            component={ InputField }
                            validate={ [hex] }
                        />
                      </div>
                      <div css={ cell }>
                        <label css={ label }>Foreground</label>
                        <Field
                            component={ RadioSelectField }
                            name="is_mobile_primary_colour_light"
                            options={ foreground_options }
                            horizontal={ true }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group" css={ field_container }>
                    <label css={ label_center }>Secondary Colour</label>
                    <div css={ row }>
                      <div css={ cell }>
                        <label css={ label }>Background</label>
                        <Field
                            type="text"
                            name="mobile_secondary_colour_hex"
                            component={ InputField }
                            validate={ [hex] }
                        />
                      </div>
                      <div css={ cell }>
                        <label css={ label }>Foreground</label>
                        <Field
                            component={ RadioSelectField }
                            name="is_mobile_secondary_colour_light"
                            options={ foreground_options }
                            horizontal={ true }
                        />
                      </div>
                    </div>
                    </div>
          <div css={field_container}>
            <label css={label}>Mobile Site URL</label>
            <div>
              <a target="_blank" rel="noopener noreferrer" href={organisation.mobile_site_url} >{organisation.mobile_site_url}</a>
            </div>
          </div>
        </div>
        <div css={footer}>
          <div>
            <DefaultButton
              label="Cancel"
              type="button"
              onClickButton={onCancelContent}
            />
          </div>
          <div css={error_container}>
            {error && <p>{error}</p>}
          </div>
          <div>
            <PrimaryButton
              label="Save"
              type="submit"
            ></PrimaryButton>
          </div>
        </div>
        {display_image_cropper && this.renderImageCropper()}
      </form>
    )
  }
}
ContentForm = reduxForm({
  form: 'CONTENT_FORM'
})(ContentForm);
function mapStateToProps(state, props) {
  const { onSubmit, organisation_id, onCancelContent, onSuccess, onFailure, newImageFile, image_id } = props
  const upload_relative_url = 'fileinfo/'
  const organisation = organisationList.getObject(organisation_id) || {}
  const image = imageList.getObject(organisation.image_id) || {}

  return {
    onSubmit,
    onCancelContent,
    onSuccess,
    onFailure,
    organisation,
    initialValues: organisation,
    enableReinitialize: true,
    upload_relative_url,
    image,
    image_id,
    newImageFile,
    is_busy: organisationList.isLoading() ||
      imageList.isLoading() ||
      imageCropList.isLoading() ||
      organisationList.getIsSavingObject() ||
      imageList.getIsSavingObject() ||
      imageCropList.getIsSavingObject(),
  }
}
export default withRouter(connect(mapStateToProps)(ContentForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
justify-content: center;
padding: 20px;
`

const column_container = css`
display: flex;
flex-direction: column;
width: 48%;
min-width: 400px;
max-width: 600px;
padding: 20px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const logo = css`
display: flex;
justify-content: flex-start;
`

const thumbnail = css`
display: flex;
flex-direction: column;
justify-items: center;
align-items: center;
padding-right: 20px;
`

const image_container = css`
width: 110px;
height: 110px;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 10px;
`

const image_src = css`
max-width: 110px;
max-height: 110px;
`

const details = css`
display: flex;
flex-direction: column;
justify-content: space-between;
`

const buttons = css`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
const link_button = css`
background-color: ${theme.colors.primary};
color: ${theme.colors.white};
margin-left: 15px;
transition: background-color 0.15s ease-in-out;
&:hover {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
}
`

const row = css`
display: flex; 
flex-direction: row;
`

const image_text = css`
font-size: 0.8rem;
@media(max-width: 1300px) {
font-size: 0.75rem;
}
`
const cell = css`
width: 45%;
`
const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`