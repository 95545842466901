/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import { head } from 'lodash'
import { reset } from 'redux-form'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'

import MainLayout from './MainLayout'
import ContentForm from './ContentForm'
import BusyMask from './BusyMask'

class Content extends Component {

    constructor(props) {
        super(props)
        this.state = { newImageFile: null }
    }
    
    componentDidMount() {
        const { dispatch, organisation } = this.props
        dispatch(organisationList.invalidateList())
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.ensureObjectLoaded(organisation.image_id))
    }

    componentDidUpdate() {
        const { dispatch, organisation } = this.props
        dispatch(imageList.ensureObjectLoaded(organisation.image_id))
    }
    
    handleSubmit = (values) => {
        const { dispatch, history } = this.props
        const { newImageFile } = this.state
        if (newImageFile) {
            values.image_id = newImageFile.id
        }
        const on_done = function() {
            history.push('/dashboard')
        }
        return dispatch(organisationList.saveObject(values, on_done))
    }

    onCancelContent = () => {
        const { dispatch, history } = this.props
        dispatch(reset('CONTENT_FORM'))
        history.push('/dashboard')
    }

    onFailure = () => {}

    onSuccess = (file, response) => {
        this.setState({ newImageFile: response.fileinfo })
    }

    updateNewImageFile = (fileinfo) => {
        this.setState({ newImageFile: fileinfo})
    }
    
    render() {
        const { organisation, is_busy } = this.props
        const { newImageFile } = this.state
        return (
            <MainLayout title='Customer Details'>
              { is_busy && <BusyMask /> }
              <ContentForm
                  onSubmit={ this.handleSubmit }
                  onCancelContent={ this.onCancelContent }
                  organisation_id={ organisation.id }
                  image_id={ organisation.image_id}
                  onSuccess={ this.onSuccess }
                  onFailure={ this.onFailure }
                  newImageFile={ newImageFile }
                  updateNewImageFile={ this.updateNewImageFile }
              />
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const image = imageList.getObject(organisation.image_id) || {}
    return {
        organisation,
        image,
        is_busy: organisationList.getIsSavingObject() ||
                 organisationList.isLoading() ||
                 imageList.isLoading() ||
                 imageList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(Content)

