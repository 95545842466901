/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get } from 'lodash'

import { adminOrganisationList, sendInvite } from '../actions/admin_organisation'
import { imageList } from '../../actions/image'

import MainLayout from './MainLayout'
import OrganisationContentForm from './OrganisationContentForm'
import OrganisationAdminForm from './OrganisationAdminForm'
import OrganisationNewForm from './OrganisationNewForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'

import npo_icon from '../../images/npo-connect.png'

class AdminOrganisation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            newImageFile: null,
            display_did_save_modal: false,
            display_did_send_modal: false,
        }
    }
    
    componentDidMount() {
        const { dispatch, organisation_id } = this.props
        dispatch(adminOrganisationList.fetchListIfNeeded())
        dispatch(adminOrganisationList.ensureObjectLoaded(organisation_id))
        dispatch(imageList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch, organisation } = this.props
        dispatch(adminOrganisationList.fetchListIfNeeded())
        dispatch(imageList.ensureObjectLoaded(organisation.image_id))
    }

    onSubmitAdmin = (values) => {
        const { dispatch } = this.props
        const that = this
        values.active = values.status.value
        const on_done = function(json) {
            that.toggleDidSaveModal()
            dispatch(adminOrganisationList.invalidateList())
        }
        return dispatch(adminOrganisationList.saveObject(values, on_done))
    }

    onSendInvite = () => {
        const { dispatch, organisation } = this.props
        const that = this
        const values = Object.assign({
            admin_email: organisation.admin_email
        })
        const on_done = function() {
            that.toggleDidSendModal()
        }
        return dispatch(sendInvite(values, on_done))
    }

    onTransactions = () => {
        const { history, organisation_id } = this.props
        history.push('/admin/transactions/' + organisation_id)
    }
    
    onSubmitContent = (values) => {
        const { dispatch } = this.props
        const { newImageFile } = this.state
        const that = this
        if (newImageFile) {
            values.image_id = newImageFile.id
        }
        const on_done = function() {
            that.toggleDidSaveModal()
            dispatch(adminOrganisationList.invalidateList())
        }
        return dispatch(adminOrganisationList.saveObject(values, on_done))
    }

    onFailure = () => {}

    onSuccess = (file, response) => {
        this.setState({ newImageFile: response.fileinfo })
    }

    updateNewImageFile = (fileinfo) => {
        this.setState({ newImageFile: fileinfo})
    }

    onSubmitNew = (values) => {
        const { dispatch, history } = this.props
        values.isNewInstance = true
        const on_done = function(json) {
            history.push('/admin/organisation/admin/' + json.organisation.id)
            dispatch(adminOrganisationList.invalidateList())
        }
        return dispatch(adminOrganisationList.saveObject(values, on_done))
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/admin/organisations')
    }

    toggleDidSaveModal = () => {
        const { display_did_save_modal } = this.state
        this.setState({
            display_did_save_modal: !display_did_save_modal
        })
    }

    renderDidSaveModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Details Saved"
                onActionModal={ this.toggleDidSaveModal }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }

    toggleDidSendModal = () => {
        const { display_did_send_modal } = this.state
        this.setState({
            display_did_send_modal: !display_did_send_modal,
        })
    }

    renderDidSendModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Invite Sent"
                onActionModal={ this.toggleDidSendModal }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }
    
    render() {
        const { organisation_id, is_busy, organisation, page } = this.props
        const { newImageFile, display_did_save_modal, display_did_send_modal } = this.state
        let title = 'Add NPO'
        if (organisation_id) {
            title = 'Edit NPO'
        }
        return (
            <MainLayout title={ title }>
              { is_busy && <BusyMask /> }
              { !organisation_id ?
                <div css={ new_container }>
                  <OrganisationNewForm
                      onSubmit={ this.onSubmitNew }
                      onCancel={ this.onCancel }
                  />
                </div>
                :
                <div css={ forms_container } >
                  { page === "content" ?
                    <div css={ content_container } >
                      <OrganisationContentForm
                          onSubmit={ this.onSubmitContent }
                          onCancel={ this.onCancel }
                          organisation_id={ organisation_id }
                          image_id={ organisation.image_id }
                          onSuccess={ this.onSuccess }
                          onFailure={ this.onFailure }
                          newImageFile={ newImageFile }
                          updateNewImageFile={ this.updateNewImageFile }
                      />
                    </div>
                    :
                    <div css={ admin_container } >
                      <OrganisationAdminForm
                          onSubmit={ this.onSubmitAdmin }
                          onCancel={ this.onCancel }
                          organisation_id={ organisation_id }
                          onSendInvite={ this.onSendInvite }
                          onTransactions={ this.onTransactions }
                      />
                    </div>
                  }
                </div>
              }
              { display_did_save_modal && this.renderDidSaveModal() }
              { display_did_send_modal && this.renderDidSendModal() }
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const { page } = props
    const organisation_id = get(props, ["match", "params", "organisation_id"], null)
    const organisation = adminOrganisationList.getObject(organisation_id)
    const image = imageList.getObject(organisation.image_id) || {}
    return {
        organisation_id,
        organisation,
        image,
        page,
        is_busy: adminOrganisationList.getIsSavingObject() ||
                 adminOrganisationList.isLoading() ||
                 imageList.isLoading() ||
                 imageList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(AdminOrganisation)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const new_container = css`
display: flex;
flex-direction: column;
align-items: center;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
