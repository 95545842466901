/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from './InputField'
import PrimaryButton from './PrimaryButton'

import { default_theme as theme } from '../emotion/theme'
import show_dark from '../images/show_dark.png'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

class NewPasswordForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show_password: false,
        }
    }

    toggleShowPassword = () => {
        const { show_password } = this.state
        this.setState({
            show_password: !show_password,
        })
    }
    
    render() {
        const { handleSubmit, error } = this.props
        const { show_password } = this.state

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div css={ error_container }>
                { error && <p>{ error }</p>}
              </div>
              <div className="form-group" css={ field_container }>
                <div css={ row }>
                  <label css={ label }>Password</label>
                  <div>
                    <img src={ show_dark } alt="" css={ eye_con } onClick={ this.toggleShowPassword } />
                  </div>
                </div>
                <div>
                  <Field
                      type={ show_password ? 'text' : 'password'}
                      validate={[required]}
                      name="password"
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Confirm Password</label>
                <div>
                  <Field
                      type={ show_password ? 'text' : 'password'}
                      validate={[required]}
                      name="confirm_password"
                      component={ InputField }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <PrimaryButton
                      label="Save"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
NewPasswordForm = reduxForm({
    form: 'NEW_PASSWORD_FORM'
})(NewPasswordForm);
function mapStateToProps(state, props) {
    const { onSubmit } = props
    return {
        onSubmit
    }
}
export default withRouter(connect(mapStateToProps)(NewPasswordForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin: 20px;
`

const field_container = css`
width: 80%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};

`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: flex-end;
width: 80%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex;
`

const eye_con = css`
height: 24px;
padding-left: 5px;
margin-bottom: 8px;
&:hover{
cursor: pointer;
}
`
