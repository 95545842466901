import { ItemList } from '../../orm'

class AdminAccountList extends ItemList {
    getEntityKey() {
        return 'admin/account'
    }
}

export const adminAccountList = new AdminAccountList('admin/account')

class AdminAccountNoPaginationList extends ItemList {
    getEntityKey() {
        return 'admin/account_no_pagination'
    }
}

export const adminAccountNoPaginationList = new AdminAccountNoPaginationList('admin/account_no_pagination')
