/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has, capitalize } from 'lodash'

import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import PrimaryButton from './PrimaryButton'
import Pagination from './Pagination'
import Timestamp from './Timestamp'
import DefaultButton from './DefaultButton'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import Modal from './Modal'

import { messageList } from '../actions/message'

import npo_icon from '../images/npo-connect.png'

const FILTERS = { 'page': '1' }

class Messaging extends Component {

    constructor(props) {
        super(props)
        this.state = {
            table_filters: FILTERS,
            selected_item: null,
            displayMessagePreviewModal: false,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(messageList.updateListFilter(Object.assign(FILTERS, {active: true})))
        this.refreshMessages()
    }

    componentDidUpdate(old_props) {
        const { dispatch } = this.props
        dispatch(messageList.fetchListIfNeeded())
    }

    onViewMessage = (evt, item) => {
        const { history } = this.props
        if (item.status === 'draft' || item.status === 'Draft') {
            history.push({
                pathname: '/message/'+item.id,
            })
        } else {
            this.setState({
                selected_item: item,
            }, () => this.toggleMessagePreviewModal())
        }
    }

    onDeleteMessage = (evt,item) => {
        const { dispatch } = this.props
        dispatch(messageList.deleteObject(item.id))
        dispatch(messageList.invalidateList())
    }

    toggleMessagePreviewModal = () => {
        const { displayMessagePreviewModal } = this.state
        this.setState({
            displayMessagePreviewModal: !displayMessagePreviewModal
        })
    }

    renderMessagePreviewModal = () => {
        const { selected_item } = this.state
        return (
            <Modal
                title_icon={ npo_icon }
                title="View Message"
                onActionModal={ this.toggleMessagePreviewModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
              <div css={ [modal_container, css`align-items:flex-start;padding:20px;`] }>
                <label>{`Message ${selected_item.status}`}</label>
                <Timestamp value={ selected_item.sent_at } format='dateshort-time' />
                <br />
                <label>Recipients</label>
                <textarea
                    rows={ 2 }
                    className="form-control"
                    css={ modal_textarea }
                    readOnly={ true }
                    value={ selected_item.recipients_string }
                />
                <br />
                <div css={ css`display:flex;justify-content:space-between;width:100%;`}>
                  <div css={ css`width:45%;`}>
                    <label>Text Messages</label>
                    <input
                        value={ selected_item.notified_by_sms }
                        className="form-control"
                        readOnly={ true }
                    />
                  </div>
                  <div css={ css`width:45%;`}>
                    <label>Push Notifications</label>
                    <input
                        value={ selected_item.notified_by_pn }
                        className="form-control"
                        readOnly={ true }
                    />
                  </div>
                </div>
                <br />
                <label>Cost</label>
                <input
                    value={ `R ${selected_item.expected_cost}` }
                    className="form-control"
                    readOnly={ true }
                />
                <br />
                <label>Message Text</label>
                <textarea
                    rows={ 5 }
                    className="form-control"
                    css={ modal_textarea }
                    readOnly={ true }
                    value={ selected_item.body }
                />
              </div>
            </Modal>
        )
    }

    refreshMessages = () => {
        const { dispatch } = this.props
        dispatch(messageList.invalidateList())
        dispatch(messageList.updateListFilter())
        dispatch(messageList.fetchListIfNeeded())
    }

    onAddMessage = () => {
        const { history, dispatch } = this.props
        const on_done = function(json) {
            dispatch(messageList.updateListFilter({active: false}))
            history.push('/message/' + json.id)
        }
        return dispatch(messageList.saveObject({isNewInstance: true}, on_done))
    }

    sortOnHeader = (key) => {
        const { table_filters } = this.state
        if (key !== 'action') {
            let sort_by = key
            if (has(table_filters, 'order_by') && table_filters['order_by'] === key) {
                sort_by = '-' + key
            }
            const order = {'order_by':sort_by}
            const filters = Object.assign(FILTERS, order)
            this.setState({table_filters: filters})
            this.updateTableFilters()
        }
    }

    updateTableFilters = () => {
        const { dispatch } = this.props
        const { table_filters } = this.state
        dispatch(messageList.updateListFilter(table_filters))
    }
    
    getMessageCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'recipient_type':
                return item.recipients_string
            case 'message_text':
                return item.body
            case 'status':
                return capitalize(item.status)
            case 'action':
                if (capitalize(item.status) === 'Draft') {
                    return (
                        <span>
                          <PrimaryButton onClickButton={(evt) => this.onViewMessage(evt, item)}
                                         label="Edit">
                          </PrimaryButton>
                          <span css={ css`padding-left:20px;` }>
                          <PrimaryButton onClickButton={(evt) => this.onDeleteMessage(evt, item)}
                                         label="Delete">
                          </PrimaryButton>
                          </span>
                        </span>
                    )
                } else {
                    return (
                        <span>
                          <PrimaryButton onClickButton={(evt) => this.onViewMessage(evt, item)}
                                         label="View">
                          </PrimaryButton>
                        </span>
                    )
                }
                
            default:
                return <span>"No idea"</span>
        }
    }

    render() {
        const {
            message_headers,
            messages,
            is_loading,
        } = this.props
        const { table_filters, displayMessagePreviewModal } = this.state
        
        return (
            <MainLayout title="Messaging">
              <div css={ container }>
                <TableHeader>
                  <div css={ header }>
                    <DefaultButton
                        label="Send New Message"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAddMessage }
                    />
                  </div>
                </TableHeader>
                  <CommonTable
                      is_loading={ is_loading }
                      empty_message="There are no messages."
                      headers={ message_headers }
                      items={ messages }
                      getCellValue={ this.getMessageCellValue }
                      sortOnHeader={ this.sortOnHeader }
                  />
                <TableFooter>
                  <div css={ pagination_container }>
                    <Pagination listKey={messageList.listKey} filters={ table_filters } />
                  </div>
                </TableFooter>
              </div>
              { displayMessagePreviewModal && this.renderMessagePreviewModal() }
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const messages = messageList.excludeEventReminderMessages()
    return {
        messages,
        message_headers: {
            recipient_type: { name: 'Recipient Type', column_size: 3},
            message_text: { name: 'Message Text', column_size: 2},
            status: { name: 'Status', column_size: 1},
            created_at: { name: 'Date Created', column_size: 1},
            action: { name: 'Options', column_size: 1 }
        },
        is_loading: messageList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(Messaging))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 10px;
`

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`

const modal_textarea = css`
width: 100%;
`
