/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import { head } from 'lodash'

import { organisationList } from '../actions/organisation'
import { imageList } from '../actions/image'

import DefaultButton from './DefaultButton'

import { clearAuthentication, loggedInUser } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import dashboard_light from '../images/dashboard_light.png'
import content_light from '../images/content_light.png'
import messaging_light from '../images/messaging_light.png'
import users_light from '../images/users_light.png'
import groups_light from '../images/groups_light.png'
import news_light from '../images/news_light.png'
import profile_light from '../images/profile_light.png'
import transactions_light from '../images/transactions_light.png'
import events_light from '../images/event.png'

class MenuLeft extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { facebook_app_id } = this.props
        if (facebook_app_id) {
            this.loadFBLoginAPi()
        }
    }
    
    onLogout = () => {
        const { dispatch } = this.props
        dispatch(clearAuthentication())
    }

    loadFBLoginAPi = () => {
        const { facebook_app_id } = this.props
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : facebook_app_id,
                cookie     : true,
                xfbml      : true,
                version    : 'v5.0'
            });
        };

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));        
    }
    
    render() {
        const { email, logo, logo_background, organisation } = this.props
        return (
            <div css={ main }>
              <div css={ logo ? [header, css`background:${logo_background};`] : empty_header } >
                <a className="navbar-brand" href="/dashboard" css={ href }>
                  <img src={ logo } css={ image_src } alt="" />
                </a>   
              </div>
              <div css={ menu } >
                <Link
                    to="/dashboard"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ dashboard_light } css={ icon } alt="" />
                  Dashboard
                </Link>
                <Link
                    to="/news"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ news_light } css={ icon } alt="" />
                  News
                </Link>
                <Link
                    to="/events"
                    className="nav-item nav-link"
                    css={ button }
                    >
                  <img src={ events_light } css={ event_icon } alt="" />
                  Events
                </Link>
                <Link
                    to="/messaging"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ messaging_light } css={ icon } alt="" />
                  Messaging
                </Link>
                <Link
                    to="/users"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ users_light } css={ icon } alt="" />
                  Contacts
                </Link>
                <Link
                    to="/groups"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ groups_light } css={ icon } alt="" />
                  Groups
                </Link>
                <Link
                    to="/transactions"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ transactions_light } css={ icon } alt="" />
                  Transactions
                </Link>
                <Link
                    to="/content"
                    className="nav-item nav-link"
                    css={ button }
                >
                  <img src={ content_light } css={ icon } alt="" />
                  Content
                </Link>
              </div>
              <div css={ footer } >
                <label><img src={ profile_light } css={ icon } alt="" />{ email }</label>
                <Link
                    to="/profile"
                    className="nav-item nav-link"
                    css={ footer_link }
                >
                  Profile
                </Link>
                <DefaultButton
                    label="Logout"
                    type="button"
                    onClickButton={ this.onLogout }
                    extra_class={ footer_button }
                />
              </div>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { facebook_app_id } = props
    const organisation = head(organisationList.getVisibleObjects()) || {}
    const admin = loggedInUser()

    return {
        email: admin.email,
        logo: organisation.thumbnail,
        logo_background: organisation.mobile_logo_background_colour_hex,
        facebook_app_id,
        organisation,
    }
}
export default connect(mapStateToProps)(MenuLeft)

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
height: 100vh;
font-size: 20px;
background-color: ${theme.colors.primary};
`

const button = css`
color: ${theme.colors.white};
display: flex;
align-items: center;
height: 100%;
width: 100%;
transition: background-color 0.3s ease-in-out;
letter-spacing: 1.5px;
:hover, :focus, :active {
color: ${theme.colors.white};
background-color: ${theme.colors.secondary};
font-size: 20px;
}
`

const icon = css`
padding-right: 15px;
`

const event_icon = css`
padding-right: 10px;
width: 50px;
position: relative;
right: 2px;
`


const header = css`
height: 150px;
width: 90%;
display: flex;
flex-direction: column;
justify-content: center;
margin: 15px;
border-radius: 10px;
`

const empty_header = css`
height: 150px;
width: 90%;
display: flex;
flex-direction: column;
justify-content: center;
background: ${theme.colors.white};
margin: 15px;
border-radius: 10px;
`

const menu = css`
height: 350px;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-top: 50px;
margin-bottom: 35px;
justify-content: space-around;
`

const image_src = css`
object-fit: contain;
max-width: 80%;
max-height: 80%;
`

const footer = css`
flex-grow: 1;
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: flex-start;
padding-left: 15px;
color: ${theme.colors.white};
`

const footer_button = css`
color: ${theme.colors.white};
background-color: ${theme.colors.primary};
display: flex;
align-items: center;
padding-left: 49px;
font-size: 20px;
`

const footer_link = css`
color: ${theme.colors.white};
display: flex;
align-items: center;
padding: 0;
padding-left: 50px;
:hover, :focus, :active {
    color: ${theme.colors.white};
}
`

const href = css`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
`
