/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has } from 'lodash'

import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import PrimaryButton from './PrimaryButton'
import Pagination from './Pagination'
import Timestamp from './Timestamp'
import DefaultButton from './DefaultButton'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'

import { groupList } from '../actions/group'

const FILTERS = { 'page': '1' }

class Messaging extends Component {

    constructor(props) {
        super(props)
        this.state = {
            table_filters: FILTERS,
        }
    }
    
    componentDidMount() {
        this.refreshGroups()
    }

    componentDidUpdate(old_props) {
        const { dispatch } = this.props
        dispatch(groupList.fetchListIfNeeded())
    }

    onEditGroup = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/group/'+item.id,
        })
    }

    refreshGroups = () => {
        const { dispatch } = this.props
        dispatch(groupList.invalidateList())
        dispatch(groupList.updateListFilter())
        dispatch(groupList.fetchListIfNeeded())
    }

    onAddGroup = () => {
        const { history } = this.props
        history.push('/group')
    }

    sortOnHeader = (key) => {
        const { table_filters } = this.state
        if (key !== 'action') {
            let sort_by = key
            if (has(table_filters, 'order_by') && table_filters['order_by'] === key) {
                sort_by = '-' + key
            }
            const order = {'order_by':sort_by}
            const filters = Object.assign(FILTERS, order)
            this.setState({table_filters: filters})
            this.updateTableFilters()
        }
    }

    updateTableFilters = () => {
        const { dispatch } = this.props
        const { table_filters } = this.state
        dispatch(groupList.updateListFilter(table_filters))
    }
    
    getGroupCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
                case 'name':
                return item.name
                case 'description':
                return item.description
                case 'count':
                return item.count_followers
                case 'action':
                return (
                    <span>
                      <PrimaryButton onClickButton={(evt) => this.onEditGroup(evt, item)}
                                     label="Edit">
                      </PrimaryButton>
                    </span>
                )
                
            default:
                return <span>"No idea"</span>
        }
    }

    render() {
        const {
            group_headers,
            groups,
            is_loading,
        } = this.props
        const { table_filters } = this.state
        
        return (
            <MainLayout title="Groups">
              <div css={ container }>
                <TableHeader>
                  <div css={ header }>
                    <DefaultButton
                        label="Add Group"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAddGroup }
                    />
                  </div>
                </TableHeader>
                  <CommonTable
                      is_loading={ is_loading }
                      empty_message="There are no groups."
                      headers={ group_headers }
                      items={ groups }
                      getCellValue={ this.getGroupCellValue }
                      sortOnHeader={ this.sortOnHeader }
                  />
                <TableFooter>
                  <div css={ pagination_container }>
                    <Pagination listKey={groupList.listKey} filters={ table_filters } />
                  </div>
                </TableFooter>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const groups = groupList.getVisibleObjects()
    return {
        groups,
        group_headers: {
            name: { name: 'Name', column_size: 4 },
            description: { name: 'Description', column_size: 8 },
            count: { name: 'Count', column_size: 2 },
            created_at: { name: 'Date Created', column_size: 2 },
            action: { name: 'Options', column_size: 1 }
        },
        is_loading: groupList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(Messaging))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
`

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
