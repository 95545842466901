/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { map, has, isEmpty, keys as _keys } from 'lodash'

import loading_icon from '../images/loading.gif'
import { default_theme as theme } from '../emotion/theme'

class CommonTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            header_sizes: [],
            visible_rows: [],
            selected_row: null,
        }
    }

    handleRowClick = (e, row_id) => {
        const { onRowClick } = this.props
        e.preventDefault()
        if (onRowClick) {
            onRowClick(row_id)
        }
    }

    sortOnHeader = (header_key) => {
        const { sortOnHeader } = this.props
        if (sortOnHeader) {
            sortOnHeader(header_key)
        }
    }

    renderCell(item, header_key, index, row_index, current_state) {
        const { headers, renderCell, getCellValue, editable } = this.props
        if ( renderCell ) {
            return renderCell(header_key, item, headers[header_key].column_size, row_index, index, current_state, editable)
        } else {
            let cell_value = undefined
            if ( getCellValue ) {
                cell_value = getCellValue(header_key, item, index)
            }
            if ( cell_value === undefined ) {
                cell_value = item[header_key]
            }
            
            return (
                <div
                    key={ `${header_key}_cell_${item[header_key]}` }
                    style={{
                        flex: headers[header_key].column_size,
                        justifyContent: 'center'
                    }}
                    css={ [td] }>
                  <div css={ css`display: flex; align-items: flex-start; flex-direction: column;` }>
                    <div css={ truncate }>
                      {cell_value}
                    </div>
                  </div>
                </div>
            )
        }
    }

    renderRow = (item, row_index) => {
        const { headers, onRowClick, custom_row_height } = this.props
        const keys = _keys(headers)
        let row_id = null
        if (keys[0] === 'id') {
            keys.splice(0, 1)
            row_id = item.id
        }

        const current_state = (has(this.state, row_id) && this.state[row_id]) || false

        return (
            <div
                key={ `row__` + item.id }
                css={[
                    tr,
                    onRowClick ?
                    css`cursor:pointer;&:hover{background:${theme.colors.primary};color:white;}` :
                    undefined ,
                    custom_row_height === "small" ?
                    css`padding:5px;` :
                    undefined
                ]}
                onClick={ (e) => this.handleRowClick(e, item.id) }>
              {map(keys, (header_key, index) => {
                  if (headers[header_key] === undefined) {
                      return null
                  }
                  return this.renderCell(item, header_key, index, row_index, current_state)
                  
              })}
            </div>
        )
    }

    renderEmptyRow = () => {
        const { empty_message, custom_row_height } = this.props
        return (
            <div key="empty_row" css={ [tr, custom_row_height === "small" ?
                                       css`padding:5px;` :
                                       undefined] }>
              <div css={ td }>
                <em>{ empty_message }</em>
              </div>
            </div>
        )
    }

    renderLoadingRow = () => {
        return (
            <div key="loading_row" css={ tr }>
              <div css={ td }>
                <em>Loading please wait..</em>
              </div>
            </div>
        )
    }

    render() {
        const {
            headers,
            items,
            is_loading,
            sortOnHeader,
            rows_displayed,
        } = this.props
        const keys = _keys(headers)
        let height = 0;
        if (rows_displayed) {
            height = (rows_displayed * 45)
        }
        return (
            <div css={ table }>
              <div css={ thead }>
                <div css={ tr }>
                  {keys && map(keys, (header_key, index) => {
                      return (
                          <div key={ `header_` + index }
                               style={ {flex: headers[header_key].column_size} }
                               css={ th }
                              >
                            <strong onClick={ (e) => this.sortOnHeader(header_key) }
                                    css={ sortOnHeader ? css`cursor:pointer;` : undefined}>
                              { headers[header_key].name }
                            </strong>
                          </div>
                      )
                  })}
                </div>
              </div>
              <div css={ [rows_displayed ? css`overflow-y: auto;max-height: ${height}px;` : undefined ,tbody] }>
                { !is_loading && isEmpty(items) &&
                  this.renderEmptyRow()
                }
                { is_loading && !isEmpty(items) &&
                  <span css={ loading_container }>
                    <img alt="loading" src={ loading_icon } css={ loading } />
                  </span>
                }
                {map(items, (item, row_index) => {
                    return this.renderRow(item, row_index)
                })}
              </div>
            </div>
        )
    }
}
export default CommonTable

const table = css`
display: flex;
flex-direction: column;
flex: 1;
border: 2px solid ${theme.colors.primary};
color: ${theme.colors.secondary};
padding-bottom: 10px;
width: 100%;
`

const thead = css`
color: ${theme.colors.secondary};
`

const th = css`
display: flex;
`

const tr = css`
display: flex;
flex: 1;
border-bottom: 1px solid ${theme.colors.gray2};
padding-top: 10px;
padding-bottom: 10px;
padding-left: 5px;
padding-right: 5px;
`

const tbody = css`
display: flex;
flex-direction: column;
position: relative;
`

const td = css`
display: flex;
flex-direction: column;
overflow: hidden;
`

const truncate =css`
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
width: 90%;
max-width: 400px;
`

const loading_container = css`
position: absolute; 
z-index:900; 
background-color: rgba(255, 255, 255, 0.8); 
width: 100%; 
height: 100%;
display: flex;
justify-content: center; 
align-items: center;
`

const loading = css`
width: 32px;
height: 32px;
`
