import { ItemList } from '../orm'

class FacebookList extends ItemList {
    getEntityKey() {
        return 'facebook'
    }
}

export const facebookList = new FacebookList('facebook')

class FacebookTokenList extends ItemList {
    getEntityKey() {
        return 'facebook/post_access_token'
    }

    post(values) {
        return this.saveNewObject(values)
    }
}

export const facebookTokenList = new FacebookTokenList('facebook/post_access_token')

class FacebookPostList extends ItemList {
    getEntityKey() {
        return 'facebook/publish_to_facebook'
    }

    post(values) {
        return this.saveNewObject(values)
    }
}

export const facebookPostList = new FacebookPostList('facebook/publish_to_facebook')
