export const default_theme = {
    colors: {
        white: '#FFFFFF',
        black: '#000000',
        primary: '#599DC2',
        primary_hover: '#15aed3',
        gray1: '#3c4858',
        gray2: '#e6e6e6',
        gray3: '#eeeeee',
        gray4: '#bfbfbf',
        gray5: '#7b7979',
        error: '#FF0000',
        green: '#36801e',
        dark_green: '#2c6b17',
        red: '#b32626',
        dark_red: '#9a1919',
        secondary: '#282828',
        //secondary: '#3c4858',
        secondary_hover: '#bfbfbf',
        light: '#f8f9fa',
        logo_1: '#e56325',
        logo_2: '#9bcb3c',
        logo_3: '#feba18',
        logo_4: '#15aed3',
    }
}
