import { ItemList } from '../orm'

class TwitterList extends ItemList {
    getEntityKey() {
        return 'twitter'
    }
}

export const twitterList = new TwitterList('twitter')

class TwitterPostList extends ItemList {
    getEntityKey() {
        return 'twitter/publish_to_twitter'
    }

    post(values) {
        return this.saveNewObject(values)
    }
}

export const twitterPostList = new TwitterPostList('twitter/post_to_twitter')

class TwitterRedirectList extends ItemList {
    getEntityKey() {
        return 'twitter/get_redirect_url'
    }

    fetch() {
        return this.saveNewObject()
    }
}

export const twitterRedirectList = new TwitterRedirectList('twitter/get_redirect_url')
