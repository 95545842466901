/** @jsx jsx */
import { Component } from 'react'
import { css, jsx } from '@emotion/core'

class TopupInstructionModalText extends Component {

  render() {
    const { organisation } = this.props

    return (
      <div>
        <div css={[modal_container, instructions_container]}>
          <h3>Instructions for topping up your notification fund</h3>
          <div css={css`width:95%;text-align:justify;`}>
            <p>You currently do not have sufficient funds in your messaging balance to send further messages. In order to top-up your balance, please deposit funds using an EFT payment to Coral Tech using the following details:</p>
            <p>
              <div>Account Name: Coral Technologies</div>
              <div>Bank: Standard Bank</div>
              <div>Account Number: 27 003 3815</div>
              <div>Branch Code: 025609</div>
              <div>Branch Name: Blue Route Mall</div>
            </p>
            <p>IMPORTANT: You must please use the payment reference: <strong>{`MGS${organisation.ref_code}`}</strong> when making your payment so that we can correctly allocate your new messaging funds.</p>
            <p>
              <div>Please note:</div>
              <ul>
                <li>You can only deposit a maximum of R1000 at a time to your messaging balance</li>
                <li>It may take a few days before your deposit reaches our bank account and we update your messaging balance</li>
                <li>Please log into your NPOconnect portal to see when the new amount has been added to your messaging balance</li>
                <li>Once the new amount has been added to your messaging balance, you may start sending messages again up to the balance amount</li>
                <li>The amount your deposit will include the VAT amount charged on messages</li>
                <li>If you have any queries, please email us at npoconnect@coraltech.co.za</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
export default TopupInstructionModalText;

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`

const instructions_container = css`
align-items: center;
justify-content: flex-start;
padding: 10px;
`