/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css, Global } from '@emotion/core'
import { reduxForm, Field, getFormValues, Fields } from 'redux-form'
import { has, isNull } from 'lodash'
import { dataFileList } from '../actions/datafile'
import { eventList } from '../actions/event'

import InputField from './InputField'
import InputArea from './InputArea'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'
import FileUploader from './FileUploader'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import DatetimePickerField from './DatetimePickerField'
import DatetimeRangePickerField from './DatetimeRangePickerField'
import ColorPicker from './ColorPickerField'
import EventAttendenceCount from './EventAttendenceCount'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

class EventForm extends Component {

  constructor(props) {
    super(props)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.state = {
      datafile: {
          id: null,
          name: null
      },
      response: {
        error: null,
        tip: null,
      },
      is_all_day_event: false
    }
  }

  componentDidUpdate() {
    const { dispatch, document_id, event, eventFormValues } = this.props
    dispatch(dataFileList.ensureObjectsLoaded([document_id]))
      if (has(eventFormValues, 'is_all_day')) {
          if (eventFormValues.is_all_day !== this.state.is_all_day_event) {
              this.setState({is_all_day_event: event.is_all_day})
          }
      }
  }

  handleColorChange(mobile_event_colour_hex) {
    this.props.change('mobile_event_colour_hex', mobile_event_colour_hex)
  }

  addEventDocumentID = (file_id) => {
    const { change } = this.props
      change('document', file_id)
  }

    setAllDayEventFalse = () => {
    const { change } = this.props
    this.setState({
        is_all_day_event: false
    })
      change('is_all_day', false)
  }

    setAllDayEventTrue = () => {
    const { change } = this.props
    this.setState({
      is_all_day_event: true
    })
      change('event_end', null)
      change('is_all_day', true)
  }

    onSuccess = (file, response) => {
        console.log(response)
    this.setState({
      datafile: {
        id: response.datafileinfo.id,
        name: response.datafileinfo.filename
      }
    })
    this.addEventDocumentID(response.datafileinfo.id)
  }

  onFailure = () => {
    this.setState({
        response: {
            error: 'Failed to upload file',
            tip: 'Ensure that the file has this extension: .pdf'
        }
    })
  }
  
    renderAllDayEventControls = () => {
    return (
      <div className="form-group" css={ field_container_style }>
      <label css={ label_style }>All day event?</label>
      <div>
        <label css={label_style}>
          No
          <input 
            type="radio" 
            value="No"
            css={radio_input_style}
            checked={this.state.is_all_day_event === false}  
            onChange={() => this.setAllDayEventFalse()} 
          />
        </label>
        <label css={label_style}>
          Yes
            <input 
              type="radio" 
              value="Yes"
              css={radio_input_style}
              checked={this.state.is_all_day_event === true}  
              onChange={() => this.setAllDayEventTrue()} 
            />
        </label>
      </div>
    </div>
    )
  }
  
  renderEventAttendenceCount = () => {
    const { event } = this.props
    return (
        <EventAttendenceCount event={event} title={'Event Attendence'}/>
    )
  }

    onStartDateChange = (val) => {
        const { eventFormValues, change } = this.props
        if (!(eventFormValues && eventFormValues.event_end)) {
            change('event_end', val)
        }
    }

    render() {
    const { handleSubmit,
      error,
      upload_relative_url,
      is_busy,
            onBack,
            eventFormValues,
    } = this.props

    return (
      <form css={form_layout_style} onSubmit={handleSubmit}>
        {is_busy && <BusyMask />}
        <div css={column_container_style}>
          {this.renderEventAttendenceCount()}
          <div className="form-group" css={field_container_style}>
            <label css={label_style}>Event Name</label>
            <div>
              <Field
                type="text"
                name="name"
                component={InputField}
                validate={[required]}
              />
            </div>
          </div>
          <div className="form-group" css={field_container_style}>
            <label css={label_style}>Event Description</label>
            <div>
              <Field
                name="description"
                rows={3}
                component={InputArea}
                validate={[required]}
              />
            </div>
          </div>
          <div className="form-group" css={field_container_style}>
            <label css={label_style}>Event Address</label>
            <div>
              <Field
                name="address"
                type="text"
                component={InputField}
                validate={[required]}
              />
            </div>
          </div>
          <div className="form-group" css={field_container_style}>
            <label css={label_style}>Event Colour</label>
            <div>
              <Field
                name="mobile_event_colour_hex"
                component={ColorPicker}
                onColorChange={this.handleColorChange}
                type="text"
                validate={[required]}
              />
            </div>
          </div>
          <Global styles={global_styles} />
          {this.renderAllDayEventControls()}
          {this.state.is_all_day_event === true ?
          <div className="form-group">
            <label>Event Start</label>
            <div>
              <Field
                name="event_start"
                component={DatetimePickerField}
                type="text"
                validate={[required]}
              />
            </div>
          </div>
          :
          <div>
              <div className="form-group">
                <label>Event Start</label>
                  <div>
                    <Field
                      name="event_start"
                      component={DatetimeRangePickerField}
                      type="text"
                        validate={[required]}
                    onChange={ (val) => this.onStartDateChange(val) }
                    />
                  </div>
               </div>
              <div className="form-group">
                <label>Event End</label>
                  <div>
                    <Field
                      name="event_end"
                      component={DatetimeRangePickerField}
                      type="text"
                      validate={[required]}
                    />
                  </div>
               </div>
          </div>
        }
          <div className="form-group" css={field_container_style}>
            <label css={label_style}>Event document (optional)</label>
            <div css={logo_style}>
              <div css={thumbnail_style}>
                <FileUploader
                  upload_relative_url={upload_relative_url}
                  onSuccess={this.onSuccess}
                  onFailure={ this.onFailure }
                  label="Upload"
                  name="document"
                  file_types=".pdf"
                  class_name="btn"
                />
              </div>
              <div css={details_style}>
                <div>
                  <p css={document_text_style}>
                    Note: When a document is loaded for the event, the document name is shown below the field. Only one document allowed so it must be deleted if different document is needed.
                  </p>
                </div>
              </div>
            </div>
              <p>
                {this.state.datafile.name || this.props.document.filename}
                {this.state.response.error}
                {this.state.response.tip}
              </p>
          </div>
        </div>
        <div css={footer_style}>
          <div>
            <DefaultButton
              label="Cancel"
              type="button"
              onClickButton={ onBack }
            />
          </div>
          <div css={error_container_style}>
            {error && <p>{error}</p>}
          </div>
          <div>
            <PrimaryButton
              label="Save"
              type="submit"
            ></PrimaryButton>
          </div>
        </div>
      </form>
    )
  }
}
EventForm = reduxForm({
  form: 'EVENT_FORM'
})(EventForm);
function mapStateToProps(state, props) {
  const { onSubmit,
    event_id,
    onCancelContent,
    onSuccess,
          onFailure,
          onBack
  } = props
  const upload_relative_url = 'datafileinfo/'
  const event = eventList.getObject(event_id) || {}
  const eventFormValues = getFormValues('EVENT_FORM')(state)
  const document_id = event.document
  const document = dataFileList.getObject(document_id) || {}
  return {
    onSubmit,
    onCancelContent,
    onSuccess,
    onFailure,
    event_id,
    event,
    document,
    document_id,
    initialValues: event,
    eventFormValues,
    enableReinitialize: true,
    upload_relative_url,
    is_busy: 
      eventList.isLoading() ||
      dataFileList.isLoading() ||
      eventList.getIsSavingObject() ||
      dataFileList.getIsSavingObject()
  }
}
export default withRouter(connect(mapStateToProps)(EventForm))

const form_layout_style = css`
width: 90%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
justify-content: center;
padding: 20px;
`
const global_styles = css`
.eventInfo_table_style {
  margin-bottom: 30px;
}
`

const column_container_style = css`
display: flex;
flex-direction: column;
width: 48%;
min-width: 400px;
max-width: 600px;
padding: 20px;
`

const radio_input_style = css`
margin: auto 10px;
`

const field_container_style = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container_style = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer_style = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const logo_style = css`
display: flex;
justify-content: flex-start;
`

const thumbnail_style = css`
display: flex;
flex-direction: column;
justify-items: center;
align-items: center;
padding-right: 20px;
`

const details_style = css`
display: flex;
flex-direction: column;
justify-content: space-between;
`
const label_style = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
const document_text_style = css`
font-size: 0.8rem;
@media(max-width: 1300px) {
font-size: 0.75rem;
}
`
