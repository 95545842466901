export const LIST_KEY__ORGANISATION = 'organisation'
export const LIST_KEY__ORGANISATION_LOGO = 'organisation_logo'
export const LIST_KEY__USER = 'user'
export const LIST_KEY__ARTICLE = 'article'
export const LIST_KEY__FOLLOWER = 'follower'
export const LIST_KEY__GROUP_FOLLOWER = 'group_follower'
export const LIST_KEY__IMAGE = 'fileinfo'
export const LIST_KEY__DATAFILE = 'datafileinfo'
export const LIST_KEY__GROUP = 'group'
export const LIST_KEY__EVENT = 'event'
export const LIST_KEY__MESSAGE_GROUP = 'message_group'
export const LIST_KEY__MESSAGE = 'message'
export const LIST_KEY__NOTIFICATION = 'notification'
export const LIST_KEY__MESSAGE_QUEUE = 'message_queue'
export const LIST_KEY__DASHBOARD = 'dashboard'
export const LIST_KEY__TRANSACTION = 'transaction'
export const LIST_KEY__ACCOUNT = 'account'

export const ENTITY_KEY__ORGANISATION = 'organisation'
export const ENTITY_KEY__USER = 'user'
export const ENTITY_KEY__ORGANISATION_LOGO = 'organisation_logo'
export const ENTITY_KEY__ARTICLE = 'article'
export const ENTITY_KEY__FOLLOWER = 'follower'
export const ENTITY_KEY__GROUP_FOLLOWER = 'group_follower'
export const ENTITY_KEY__IMAGE = 'fileinfo'
export const ENTITY_KEY__DATAFILE = 'datafileinfo'
export const ENTITY_KEY__GROUP = 'group'
export const ENTITY_KEY__EVENT = 'event'
export const ENTITY_KEY__MESSAGE_GROUP = 'message_group'
export const ENTITY_KEY__MESSAGE = 'message'
export const ENTITY_KEY__NOTIFICATION = 'notification'
export const ENTITY_KEY__MESSAGE_QUEUE = 'message_queue'
export const ENTITY_KEY__DASHBOARD = 'dashboard'
export const ENTITY_KEY__TRANSACTION = 'transaction'
export const ENTITY_KEY__ACCOUNT = 'account'
