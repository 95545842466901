import { ItemList } from '../../orm'

class AdminTransactionList extends ItemList {
    getEntityKey() {
        return 'admin/transaction'
    }
}

export const adminTransactionList = new AdminTransactionList('admin/transaction')

class AdminTransactionNoPaginationList extends ItemList {
    getEntityKey() {
        return 'admin/transaction_no_pagination'
    }
}

export const adminTransactionNoPaginationList = new AdminTransactionNoPaginationList('admin/transaction_no_pagination')
