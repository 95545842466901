/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, isEmpty } from 'lodash'
import { reset, getFormValues, isDirty, isValid } from 'redux-form'
import cookie from 'react-cookies'

import { organisationList } from '../actions/organisation'
import { articleList } from '../actions/article'
import { imageList } from '../actions/image'
import { facebookTokenList, facebookPostList, facebookList } from '../actions/facebook'
import { twitterList, twitterRedirectList, twitterPostList } from '../actions/twitter'

import MainLayout from './MainLayout'
import ArticleForm from './NewsArticleForm'
import Modal from './Modal'
import BusyMask from './BusyMask'

import npo_icon from '../images/npo-connect.png'

class NewsArticle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            newImageFile: null,
            displayArticleSavedModal: false,
            displayArticleCancelModal: false,
            displayFacebookLoginModal: false,
            displayFacebookLoginFailedModal: false,
            displayPostedFacebookTokenModal: false,
            displayPostFacebookTokenFailedModal: false,
            displayPostedToFacebookModal: false,
            displayPostToFacebookFailedModal: false,
            displayTwitterLoginModal: false,
            displayTwitterLoginFailedModal: false,
            displayPostedToTwitterModal: false,
            displayPostToTwitterFailedModal: false,
            isCancelled: false,
        }
    }
    
    componentDidMount() {
        const { dispatch, article_id } = this.props
        dispatch(organisationList.invalidateList())
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(articleList.invalidateList())
        dispatch(articleList.ensureObjectsLoaded([article_id]))
        dispatch(imageList.invalidateList())
        dispatch(imageList.fetchListIfNeeded())
        dispatch(facebookList.invalidateList())
        dispatch(facebookList.fetchListIfNeeded())
        dispatch(twitterList.invalidateList())
        dispatch(twitterList.fetchListIfNeeded())
    }

    componentDidUpdate(prevProps) {
        const { dispatch, article, twitter } = this.props
        const { publishToTwitterIfNeeded } = this.state
        dispatch(imageList.ensureObjectLoaded(article.image_id))
        dispatch(organisationList.fetchListIfNeeded())
    }
    
    handleSubmit = (values) => {
        const { dispatch, history, organisation } = this.props
        const { newImageFile } = this.state
        const that = this
        if (!values.id) {
            values.isNewInstance = true
            values.organisation_id = organisation.id
        }
        if (newImageFile) {
            values.image_id = newImageFile.id
        }
        values = this.unpublishIfNeeded(values)
        const on_done = function(json) {
            that.toggleArticleSavedModal()
            that.setState({newImageFile: null})
            history.push('/article/' + json.id)
        }
        return dispatch(articleList.saveObject(values, on_done))
    }

    onCancelArticle = () => {
        const { dispatch, history, dirty } = this.props
        const { newImageFile } = this.state
        if (newImageFile === null && !dirty) {
            dispatch(reset('ARTICLE_FORM'))
            history.push('/news')
        } else {
            this.setState({isCancelled: true}, () => {this.toggleArticleCancelModal()})
        }
    }

    onNewArticle = () => {
        const {dispatch, history, dirty } = this.props
        const { newImageFile } = this.state
        if (newImageFile === null && !dirty) {
            dispatch(reset('ARTICLE_FORM'))
            history.push('/article')
        } else {
            this.toggleArticleCancelModal()
        }
    }

    renderArticleCancelModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Changes Detected"
                onActionModal={ this.handleSavedChanges }
                actionLabel="Save"
                onCloseModal={ this.handleCancelChanges }
            >
              <div css={ modal_container }>
                <span>Save changes made to article?</span>
              </div>
            </Modal>
        )
    }

    handleSavedChanges = () => {
        const { articleFormValues, dispatch, history, organisation, isArticleFormValid } = this.props
        const { newImageFile, isCancelled } = this.state
        if (isArticleFormValid) {
            let values = articleFormValues
            const that = this
            if (!values.id) {
                values.isNewInstance = true
                values.organisation_id = organisation.id
            }
            if (newImageFile) {
                values.image_id = newImageFile.id
            }
            const on_done = function(json) {
                that.toggleArticleCancelModal()
                if (isCancelled === true) {
                    history.push('/news')
                    that.setState({ isCancelled: false })
                } else {
                    history.push('/article' + json.id)
                    history.push('/article')
                }
            }
            dispatch(articleList.saveObject(values, on_done))
        } else {
            this.toggleArticleCancelModal()
        }
    }

    handleCancelChanges = () => {
        const { dispatch, history } = this.props
        const { isCancelled } = this.state
        this.toggleArticleCancelModal()
        dispatch(reset('ARTICLE_FORM'))
        if (isCancelled === true) {
            this.setState({isCancelled: true})
            history.push('/news')
        } else {
            history.push('/news')
            history.push('/article')
        }
    }

    renderArticleSavedModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Article Saved"
                onActionModal={ this.postSave }
                actionLabel="Close"
                hide_secondary_button={ true }
                modal_size="small"
            >
            </Modal>
        )
    }

    updateNewImageFile = (fileinfo) => {
        this.setState({ newImageFile: fileinfo})
    }

    onFailure = () => {}

    onSuccess = (file, response) => {
        this.setState({ newImageFile: response.fileinfo })
    }

    postSave = () => {
        const { article } = this.props
        this.toggleArticleSavedModal()
        if (article.published &&
                   article.display_in_facebook && !article.is_published_to_facebook) {
            this.loginToFacebookIfNeeded()
        } else if (article.published &&
                   article.display_in_twitter && !article.is_published_to_twitter) {
            this.loginToTwitterIfNeeded()
        }
    }

    loginToFacebookIfNeeded = () => {
        const { facebook } = this.props
        if (facebook.is_valid) {
            this.postArticleToFacebook()
        } else {
            this.toggleFacebookLoginModal()
        }
    }

    postArticleToFacebook = () => {
        const { dispatch, article } = this.props
        dispatch(facebookPostList.post({article_id: article.id})).then((res) => {
            if (res.status === "success") {
                this.togglePostedToFacebookModal()
            } else {
                this.togglePostToFacebookFailedModal()
            }
        })
    }

    renderPostedToFacebookModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Article successfully published to Facebook"
                onActionModal={ this.handlePostedToFacebook }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    handlePostedToFacebook = () => {
        this.togglePostedToFacebookModal()
        this.postToTwitterIfNeeded()
    }

    renderPostToFacebookFailedModal = () => {
        const that = this
        return (
            <Modal
                title_icon={ npo_icon }
                title="Article could not be published to Facebook"
                onActionModal={ () => this.handleFacebookFailed(() => { that.togglePostToFacebookFailedModal() }) }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }    

    renderPostedFacebookTokenModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Facebook login details saved successfully"
                onActionModal={ this.handlePostedFacebookToken }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    handlePostedFacebookToken = () => {
        this.togglePostedFacebookTokenModal()
        this.postArticleToFacebook()
    }

    renderFacebookLoginModal = () => {
        const that = this
        return (
            <Modal
                title_icon={ npo_icon }
                title="Facebook Authentication Required"
                onActionModal={ this.handleFacebookLogin }
                use_fb_button={ true }
                actionLabel="Login"
                onCloseModal={ () => this.handleFacebookFailed(() => { that.toggleFacebookLoginModal() } ) }
            >
              <div css={ modal_container }>
                <span>Please login to Facebook to continue.</span>
              </div>
            </Modal>
        )
    }

    handleFacebookLogin = () => {
        const { dispatch } = this.props
        this.toggleFacebookLoginModal()
        window.FB.login((res) => {
            if (res.status === 'connected') {
                dispatch(facebookTokenList.post({
                    access_token: res.authResponse.accessToken,
                    user_id: res.authResponse.userID
                })).then((res) => {
                    if(res.status === 'success') {
                        this.togglePostedFacebookTokenModal()
                    } else {
                        const that = this
                        this.handleFacebookFailed(() => { that.togglePostFacebookTokenFailedModal() })
                    }
                }).catch(() => {
                    const that = this
                    this.handleFacebookFailed(() => { that.togglePostFacebookTokenFailedModal() })
                })
            }
            else {
                const that = this
                this.handleFacebookFailed(() => { that.toggleFacebookLoginFailedModal() })
            }
        }, {scope: 'manage_pages, publish_pages'})
    }

    handleFacebookFailed = (toggle_modal) => {
        const { dispatch, article } = this.props
        let values = Object.assign({}, article, {display_in_facebook: false})
        values = this.unpublishIfNeeded(values)
        const that = this
        const on_done = () => {
            toggle_modal()
            that.postToTwitterIfNeeded()
        }
        return dispatch(articleList.saveObject(values, on_done))
    }

    unpublishIfNeeded = (values) => {
        const { display_in_app, display_in_facebook, display_in_twitter } = values
        const displayMethods = [display_in_app, display_in_facebook, display_in_twitter]
        const shouldUnpublish = methods => methods.every(method => method === false)
        if (shouldUnpublish(displayMethods)) {
            values.published = false
            values.status = 'Unpublished'
        }
        return values
    }

    renderFacebookLoginFailedModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Facebook login details could not be saved"
                onActionModal={ this.toggleFacebookLoginFailedModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    renderPostFacebookTokenFailedModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Unable to publish article on Facebook"
                onActionModal={ this.togglePostFacebookTokenFailedModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    postToTwitterIfNeeded = () => {
        const { article } = this.props
        if (article.published &&
            article.display_in_twitter && !article.is_published_to_twitter) {
            this.loginToTwitterIfNeeded()
        }
    }

    loginToTwitterIfNeeded = () => {
        const { twitter } = this.props
        if (twitter.is_valid) {
            this.postArticleToTwitter()
        } else {
            this.toggleTwitterLoginModal()
        }
    }

    postArticleToTwitter = () => {
        const { dispatch, article } = this.props
        dispatch(twitterPostList.post({article_id: article.id})).then((res) => {
            if (res.status === "success") {
                this.togglePostedToTwitterModal()
            } else {
                this.togglePostToTwitterFailedModal()
            }
        })
    }

    renderPostedToTwitterModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Article successfully published to Twitter"
                onActionModal={ this.togglePostedToTwitterModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    renderPostToTwitterFailedModal = () => {
        const that = this
        return (
            <Modal
                title_icon={ npo_icon }
                title="Article could not be published to Twitter"
                onActionModal={ () => this.handleTwitterFailed(() => { that.togglePostToTwitterFailedModal() }) }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    renderTwitterLoginModal = () => {
        const that = this
        return (
            <Modal
                title_icon={ npo_icon }
                title="Twitter Authentication Required"
                onActionModal={ this.handleTwitterLogin }
                actionLabel="Login"
                onCloseModal={ () => this.handleTwitterFailed(() => { that.toggleTwitterLoginModal() } ) }
            >
              <div css={ modal_container }>
                <span>Please login to Twitter to continue.</span>
              </div>
            </Modal>
        )
    }

    handleTwitterLogin = () => {
        const { dispatch } = this.props
        this.toggleTwitterLoginModal()
        dispatch(twitterRedirectList.fetch()).then((res) => {
            if (res.status === "success") {
                cookie.save('twitterRedirect', window.location.href, {path: '/'})
                window.location.href = res.url
            } else {
                const that = this
                this.handleTwitterFailed(() => { that.toggleTwitterLoginFailedModal() })
            }
        })
    }

    handleTwitterFailed = (on_done) => {
        const { dispatch, article } = this.props
        let values = Object.assign({}, article, {display_in_twitter: false})
        values = this.unpublishIfNeeded(values)
        return dispatch(articleList.saveObject(values, on_done))
    }

    renderTwitterLoginFailedModal = () => {
        return (
            <Modal
                title_icon={ npo_icon }
                title="Could not login to Twitter"
                onActionModal={ this.toggleTwitterLoginFailedModal }
                actionLabel="Close"
                hide_secondary_button={ true }
            >
            </Modal>
        )
    }

    toggleTwitterLoginModal = () => {
        const { displayTwitterLoginModal } = this.state
        this.setState({displayTwitterLoginModal: !displayTwitterLoginModal})
    }

    togglePostedToTwitterModal = () => {
        const { displayPostedToTwitterModal } = this.state
        this.setState({displayPostedToTwitterModal: !displayPostedToTwitterModal})
    }

    togglePostToTwitterFailedModal = () => {
        const { displayPostToTwitterFailedModal } = this.state
        this.setState({displayPostToTwitterFailedModal: !displayPostToTwitterFailedModal})
    }

    toggleTwitterLoginFailedModal = () => {
        const { displayTwitterLoginFailedModal } = this.state
        this.setState({displayTwitterLoginFailedModal: !displayTwitterLoginFailedModal})
    }

    toggleArticleSavedModal = () => {
        const { displayArticleSavedModal } = this.state
        this.setState({displayArticleSavedModal: !displayArticleSavedModal})
    }

    toggleFacebookLoginModal = () => {
        const { displayFacebookLoginModal } = this.state
        this.setState({displayFacebookLoginModal: !displayFacebookLoginModal})
    }

    togglePostedFacebookTokenModal = () => {
        const { displayPostedFacebookTokenModal } = this.state
        this.setState({displayPostedFacebookTokenModal: !displayPostedFacebookTokenModal})
    }

    toggleArticleCancelModal = () => {
        const { displayArticleCancelModal } = this.state
        this.setState({displayArticleCancelModal: !displayArticleCancelModal})
    }

    toggleFacebookLoginFailedModal = () => {
        const { displayFacebookLoginFailedModal } = this.state
        this.setState({displayFacebookLoginFailedModal: !displayFacebookLoginFailedModal})
    }

    togglePostFacebookTokenFailedModal = () => {
        const { displayPostFacebookTokenFailedModal } = this.state
        this.setState({displayPostFacebookTokenFailedModal: !displayPostFacebookTokenFailedModal})
    }

    togglePostedToFacebookModal = () => {
        const { displayPostedToFacebookModal } = this.state
        this.setState({displayPostedToFacebookModal: !displayPostedToFacebookModal})
    }

    togglePostToFacebookFailedModal = () => {
        const { displayPostToFacebookFailedModal } = this.state
        this.setState({displayPostToFacebookFailedModal: !displayPostToFacebookFailedModal})
    }
    
    render() {
        const { article_id, is_busy, article } = this.props
        const {
            newImageFile,
            displayArticleSavedModal,
            displayArticleCancelModal,
            displayFacebookLoginModal,
            displayFacebookLoginFailedModal,
            displayPostFacebookTokenFailedModal,
            displayPostedToFacebookModal,
            displayPostToFacebookFailedModal,
            displayPostedFacebookTokenModal,
            displayTwitterLoginModal,
            displayTwitterLoginFailedModal,
            displayPostedToTwitterModal,
            displayPostToTwitterFailedModal,
        } = this.state
        let title = 'Add News Article'
        if (article_id) {
            title = 'Edit News Article'
        }
        return (
            <MainLayout title={ title }>
              { is_busy && <BusyMask /> }
              <ArticleForm
                  onSubmit={ this.handleSubmit }
                  onCancelArticle={ this.onCancelArticle }
                  onNewArticle={ this.onNewArticle }
                  article_id={ article_id }
                  image_id={ article.image_id}
                  onSuccess={ this.onSuccess }
                  onFailure={ this.onFailure }
                  newImageFile={ newImageFile }
                  updateNewImageFile={ this.updateNewImageFile }
              />
              { displayArticleSavedModal && this.renderArticleSavedModal() }
              { displayArticleCancelModal && this.renderArticleCancelModal() }
              { displayFacebookLoginModal && this.renderFacebookLoginModal() }
              { displayFacebookLoginFailedModal && this.renderFacebookLoginFailedModal() }
              { displayPostedFacebookTokenModal && this.renderPostedFacebookTokenModal() }
              { displayPostFacebookTokenFailedModal && this.renderPostFacebookTokenFailedModal() }
              { displayPostedToFacebookModal && this.renderPostedToFacebookModal() }
              { displayPostToFacebookFailedModal && this.renderPostToFacebookFailedModal() }
              { displayTwitterLoginModal && this.renderTwitterLoginModal() }
              { displayTwitterLoginFailedModal && this.renderTwitterLoginFailedModal() }
              { displayPostedToTwitterModal && this.renderPostedToTwitterModal() }
              { displayPostToTwitterFailedModal && this.renderPostToTwitterFailedModal() }
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const article_id = get(props, ["match", "params", "article_id"], null)
    const organisation = head(organisationList.getObjects()) || {}
    const article = articleList.getObject(article_id) || {}
    const images = imageList.getVisibleObjects() || {}
    const facebook = head(facebookList.getObjects()) || {}
    const twitter = head(twitterList.getObjects()) || {}
    return {
        articleFormValues: getFormValues('ARTICLE_FORM')(state),
        dirty: isDirty('ARTICLE_FORM')(state),
        isArticleFormValid: isValid('ARTICLE_FORM')(state),
        organisation: organisation,
        article,
        article_id,
        images,
        facebook,
        twitter,
        is_busy: organisationList.isLoading() ||
                 articleList.isLoading() ||
                 imageList.isLoading() ||
                 articleList.getIsSavingObject() ||
                 facebookList.isLoading() ||
                 facebookList.getIsSavingObject() ||
                 facebookTokenList.isLoading() ||
                 facebookTokenList.getIsSavingObject() ||
                 facebookPostList.isLoading() ||
                 facebookPostList.getIsSavingObject() ||
                 twitterList.isLoading() ||
                 twitterList.getIsSavingObject() ||
                 twitterRedirectList.isLoading() ||
                 twitterRedirectList.getIsSavingObject() ||
                 twitterPostList.isLoading() ||
                 twitterPostList.getIsSavingObject(),
                 
    }
}
export default connect(mapStateToProps)(NewsArticle)

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
`
