import { ItemList } from '../orm'
import { ENTITY_KEY__DATAFILE, LIST_KEY__DATAFILE } from './ItemListKeyRegistry'
import { http } from '../lib'
import { has } from 'lodash'

export const ANNOUNCE_PROCESSING_FILE = "ANNOUNCE_PROCESSING_FILE"
export const ANNOUNCE_FILE_PROCESSED = "ANNOUNCE_FILE_PROCESSED"
export const ANNOUNCE_FILE_NOT_PROCESSED = "ANNOUNCE_FILE_NOT_PROCESSED"

class DataFileList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__DATAFILE
    }

    processFile(datafile_id, on_success, on_fail) {
        return async (dispatch, getState) => {
            try {
                dispatch(this.announceProcessingFile())
                let [json, success] = await http.post(getState(), 'customer/datafile/process', {datafile_id: datafile_id })
                if (success.status === 200 && has(json, 'status')) {
                    if (json.status === 'success') {
                        dispatch(this.announceFileProcessed())
                        on_success(json)
                    } else {
                        dispatch(this.announceFileNotProcessed())
                        on_fail(json)
                    }
                } else {
                    dispatch(this.announceFileNotProcessed())
                    on_fail({'error': success.statusText, 'users_imported': '0', 'tip': 'Contact the team at NPOConnect should this error persist.'})
                }
            } catch (e) {
                dispatch(this.announceFileNotProcessed())
                on_fail({'error': e, 'users_imported': '0', 'tip': 'Check internet connection.'})
            }
        }
    }

    announceProcessingFile() {
        return {
            type: ANNOUNCE_PROCESSING_FILE
        }
    }

    announceFileProcessed() {
        return {
            type: ANNOUNCE_FILE_PROCESSED
        }
    }

    announceFileNotProcessed() {
        return {
            type: ANNOUNCE_FILE_NOT_PROCESSED
        }
    }
}

export const dataFileList = new DataFileList(LIST_KEY__DATAFILE)
