/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import DatePicker from 'react-datepicker'
import DefaultButton from './DefaultButton'

import 'react-datepicker/dist/react-datepicker.css'

class MonthYearPicker extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: null,
        }
    }
    
    onChange = (value) => {
        const { onChange } = this.props
        this.setState({
            value: value,
        })
        onChange(value)
    }

    onClear = () => {
        const { onClear } = this.props
        this.setState({
            value: null,
        })
        onClear()
    }

    render() {
        const { value } = this.state

        return (
            <div css={ main }>
              <DatePicker
                  selected={value}
                  placeholderText="Filter by month"
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  onChange={this.onChange}
              className="form-control"
              css={ date }
              />
              <div css={ clear }>
                <DefaultButton
                    label="Clear"
                    type="button"
                    light={ true }
                    onClickButton={ this.onClear }
                />
              </div>
            </div>
        )
    }
}
export default MonthYearPicker

const main = css`
display: flex;
align-items: center;
`

const date = css`
&:hover {
cursor:pointer;
}
`

const clear = css`
padding-left: 10px;
`
