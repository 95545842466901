/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import { groupList } from '../actions/group'

import InputField from './InputField'
import InputArea from './InputArea'
import DefaultButton from './DefaultButton'
import PrimaryButton from './PrimaryButton'
import BusyMask from './BusyMask'
import { default_theme as theme } from '../emotion/theme'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')

class GroupForm extends Component {

    render() {
        const { error,
                is_loading,
                handleSubmit,
                onBack,
        } = this.props
        
        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              { is_loading && <BusyMask /> }
              <div className="form-group" css={ field_container }>
                <label css={ label }>Name</label>
                <div>
                  <Field
                      type="text"
                      name="name"
                      component={ InputField }
                      validate={[required]}
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Description</label>
                <div>
                  <Field
                      name="description"
                      component={ InputArea }
                      rows={ 3 }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Back to Groups"
                      type="button"
                      onClickButton={ onBack }
                  />
                </div>
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div>
                  <PrimaryButton
                      label="Save" 
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
GroupForm = reduxForm({
    form: 'GROUP_FORM'
})(GroupForm);
function mapStateToProps(state, props) {
    const { group_id, onSubmit, onBack } = props
    const group = groupList.getObject(group_id) || {}

    return {
        initialValues: group,
        group,
        enableReinitialize: true,
        onSubmit,
        onBack,
    }
}
export default withRouter(connect(mapStateToProps)(GroupForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 50%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 50%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
