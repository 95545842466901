import { ItemList } from '../orm'
import { ENTITY_KEY__USER, LIST_KEY__USER } from './ItemListKeyRegistry'

class UserList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__USER
    }
}

export const userList = new UserList(LIST_KEY__USER)
