/** @jsx jsx */
import { Component } from 'react'
import classNames from 'classnames'
import { jsx, css } from '@emotion/core'

export default class RadioSelectField extends Component {

    constructor() {
        super()
        this.handleSetValue = this.handleSetValue.bind(this)
    }

    handleSetValue(value) {
        const { input: { onChange }} = this.props
        onChange(value)
    }

    renderOptions() {
        const { options, input } = this.props

        return (
            <div>
              { options.map(option =>
                  <div className="radio" key={'radio' + option.id}>
                    <label css={ container }>
                      <input type="radio"
                             value={option.id}
                             onChange={() => this.handleSetValue(option.id)}
                             checked={input.value === option.id }
                             name={input.name}
                      />
                      <div css={ label }>
                        { option.name }
                      </div>
                    </label>
                  </div>
              )}
            </div>
        )
    }

    renderOptionsHorizontal() {
        const { options, input } = this.props

        return (
            <div css={ horizontal }>
              { options.map(option =>
                  <div className="radio" key={'radio' + option.id}>
                    <label css={ container }>
                      <input type="radio"
                             value={option.id}
                             onChange={() => this.handleSetValue(option.id)}
                             checked={input.value === option.id }
                             name={input.name}
                      />
                      <div css={ label }>
                        { option.name }
                      </div>
                    </label>
                  </div>
              )}
            </div>
        )
    }

    render() {
        const { description, label, meta, horizontal } = this.props

        return (
            <div className={classNames('radio-select-field', {'has-error': meta.touched && meta.error})}>
              {label && <label>{label}</label>}
              { horizontal ? this.renderOptionsHorizontal() : this.renderOptions() }
              {description &&
               <div>
                 <label className="help-block">{description}</label>
               </div>
              }
              {meta.touched && meta.error && <label className="help-block">{meta.error}</label>}
            </div>
        )
    }
}

const container = css`
padding-left: 20px;
display: flex;
align-items: baseline;
`

const label = css`
padding-left: 10px;
`

const horizontal = css`
padding-top: 5px;
display: flex;
`
