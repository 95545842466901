/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import { get } from 'lodash'

import { adminTransactionList } from '../actions/admin_transaction'
import { adminAccountList } from '../actions/admin_account'

import MainLayout from './MainLayout'
import AdminTransactionForm from './AdminTransactionForm'
import BusyMask from '../../components/BusyMask'

class AdminTransaction extends Component {

    componentDidMount() {
        const { dispatch, transaction_id } = this.props
        dispatch(adminTransactionList.ensureObjectLoaded(transaction_id))
    }
    
    handleSubmit = (values) => {
        const { dispatch, history } = this.props
        if (!values.id) {
            values.isNewInstance = true
        }
        values.payment_method = values.method.value
        values.organisation = values.organisation.value
        values.type = values.type.value
        const on_done = function(json) {
            dispatch(adminTransactionList.invalidateList())
            dispatch(adminAccountList.invalidateList())
            history.push('/admin/transactions/' + json.organisation)
        }
        return dispatch(adminTransactionList.saveObject(values, on_done))
    }

    onCancel = () => {
        const { history, organisation_id } = this.props
        history.push('/admin/transactions/' + organisation_id)
    }
    
    render() {
        const { transaction_id, is_busy, organisation_id } = this.props
        let title = 'Add Transaction'
        if (transaction_id) {
            title = 'Edit Transaction'
        }
        return (
            <MainLayout title={ title } layout="single">
              { is_busy && <BusyMask /> }
              <AdminTransactionForm
                  onSubmit={ this.handleSubmit }
                  onCancel={ this.onCancel }
                  transaction_id={ transaction_id }
                  organisation_id={ organisation_id }
              />
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const transaction_id = get(props, ["match", "params", "transaction_id"], null)
    const organisation_id = get(props, ["match", "params", "organisation_id"], null)
    const transaction = adminTransactionList.getObject(transaction_id) || {}
    return {
        transaction_id,
        transaction,
        organisation_id,
        is_busy: adminTransactionList.isLoading() ||
                 adminTransactionList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminTransaction)

