/** @jsx jsx */
import { Component } from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { jsx, css, Global } from '@emotion/core'
import DatePicker from 'react-datepicker'
import FormError from './FormError'
import 'react-datepicker/dist/react-datepicker.css'

import { default_theme as theme } from '../emotion/theme'

export default class DatetimeRangePickerField extends Component {

    onChange = (value) => {
        const { input: { onChange } } = this.props
        if (!isEmpty(value) && value.format) {
            onChange(value.format())
        } else {
            onChange(null)
        }
    }

    onInputChange = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        const { input, meta } = this.props
        let value;

        if (input.value) {
            value = moment(input.value).toDate()
        }
        
        return (
            <div css={css`width:100%`}>
            <Global styles={global_styles_style}/>
              <DatePicker selected={value}
                          placeholderText={"Click to select a date"} 
                          dateFormat="yyyy, MMMM, d, h:mm aa"
                          minDate={new Date()}
                          showTimeSelect={true}
                          timeFormat="HH:mm"
                          timeIntervals={5}
                          onChange={input.onChange} />
                 <FormError meta={meta} />
            </div>
        )
    }
}

const form_input_style = css`
display: flex;
flex: 1;
align-items: center;
padding: 0 8px 0 8px;
height: 25px;
width: 260px;
border: none;
border-bottom: 2px solid ${theme.colors.gray2};
border-radius: 3px;
margin-right: 20px;

font-size: 16px;
outline: none;
background-color: transparent;

&:hover {
    cursor: pointer;
}

&:focus {
    border: 1px solid ${theme.colors.primary};
    background-color: ${theme.colors.white};
    outline: none;
}
`

const disabled_input_style = css`
color: #848484;
`

const global_styles_style = css`
.react-datepicker__input-container input {
    width: 240px;
    text-align: center;
}
`
