/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has } from 'lodash'

import MainLayout from './MainLayout'
import CommonTable from '../../components/CommonTable'
import PrimaryButton from '../../components/PrimaryButton'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import TableFilter from '../../components/TableFilter'
import ToggleButton from '../../components/ToggleButton'

import { adminOrganisationList } from '../actions/admin_organisation'

class Organisations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            active: true,
        }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminOrganisationList.updateListFilter({active: true}))
        dispatch(adminOrganisationList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminOrganisationList.fetchListIfNeeded())
    }

    onEditAdmin = (evt, item) => {
        const { history } = this.props
        history.push('/admin/organisation/admin/' + item.id)
    }

    onEditContent = (evt, item) => {
        const { history } = this.props
        history.push('/admin/organisation/content/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/organisation')
    }

    filterOrganisation = (e) => {
        const { dispatch } = this.props
        dispatch(adminOrganisationList.updateListFilter({'any_field': e.target.value, 'page' : 1}))
    }

    onChangeToggle = (value) => {
        const { dispatch } = this.props
        this.setState({active: value})
        dispatch(adminOrganisationList.updateListFilter({'active': value}))
    }


    sortOnHeader = (key) => {
        const { dispatch, organisation_filter } = this.props
        if (key !== 'action' && key !== 'admin_email') {
            let sort_by = key
            if (has(organisation_filter, 'order_by') && organisation_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(adminOrganisationList.updateListFilter({'order_by': sort_by}))
        }
    }
    
    getOrganisationCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='date' />
                )
            case 'name':
                return item.name
            case 'mobile_url':
                return item.mobile_url
            case 'admin_email':
                return item.admin_email
            case 'active':
                return item.active ? 'Active' : 'Inactive'
            case 'action':
                return (
                    <div css={ css`display:flex;flex-wrap:wrap;justify-content: center;` }>
                      <div css={ css`padding: 5px;` }>
                        <PrimaryButton onClickButton={(evt) => this.onEditAdmin(evt, item)}
                                       label="Admin">
                        </PrimaryButton>
                      </div>
                      <div css={ css`padding: 5px;` }>
                        <PrimaryButton onClickButton={(evt) => this.onEditContent(evt, item)}
                                       label="Content">
                        </PrimaryButton>
                      </div>
                    </div>
                )
            default:
                return <span>"No idea"</span>
        }
    }

    render() {
        const {
            organisation_headers,
            organisations,
            is_loading,
            organisation_filter,
        } = this.props
        const { active } = this.state

        
        return (
            <MainLayout title="NPOs">
              <div css={ container }>
                <TableHeader>
                  <div css={ filter_container }>
                    <TableFilter
                        updateOnChange={ this.filterOrganisation }
                        form="ADMIN_ORGANISATION_TABLE_FILTER_FORM"
                    />
                    <ToggleButton
                        value={ active }
                        onChange={ this.onChangeToggle }
                        on_label="Show Only Active"
                        off_label="Show All"
                        light={ true }
                    />
                  </div>
                  <div css={ header }>
                    <DefaultButton
                        label="Add NPO"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAdd }
                    />
                  </div>
                </TableHeader>
                <CommonTable
                      is_loading={ is_loading }
                      empty_message="There are no NPOs."
                      headers={ organisation_headers }
                      items={ organisations }
                      getCellValue={ this.getOrganisationCellValue }
                      sortOnHeader={ this.sortOnHeader }
                  />
                  <TableFooter>
                    <div css={ pagination_container }>
                      <Pagination listKey={ adminOrganisationList.listKey }
                                  filters={ organisation_filter } />
                    </div>
                  </TableFooter>
                </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const organisations = adminOrganisationList.getVisibleObjects() || []
    return {
        organisations,
        organisation_filter: adminOrganisationList.getFilter(),
        organisation_headers: {
            created_at: { name: 'Date Joined', column_size: 1 },
            name: { name: 'Name', column_size: 1 },
            admin_email: { name: 'Administrator', column_size: 1 },
            mobile_url: { name: 'Mobile Site Prefix', column_size: 1 },
            active: { name: 'Status', column_size: 1 },
            action: { name: 'Options', column_size: 1 },
        },
        is_loading: adminOrganisationList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(Organisations))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 10px;
`

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const filter_container = css`
display: flex;
align-items: center;
width: 100%;
`
