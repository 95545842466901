/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'

import InputField from '../../components/InputField'
import DefaultButton from '../../components/DefaultButton'
import PrimaryButton from '../../components/PrimaryButton'

import { default_theme as theme } from '../../emotion/theme'

const required = value => (value || typeof value === 'number' ? undefined : 'Required')
const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined

class OrganisationNewForm extends Component {

    render() {
        const { handleSubmit,
                onCancel,
                error,
        } = this.props

        return (
            <form css={ form_layout } onSubmit={ handleSubmit }>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Name</label>
                <div>
                  <Field
                      type="text"
                      name="name"
                      validate={ [required] }
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Email Address</label>
                <div>
                  <Field
                      type="text"
                      name="admin_email"
                      validate={ [required, email] }
                      component={ InputField }
                  />
                </div>
              </div>
              <div className="form-group" css={ field_container }>
                <label css={ label }>Mobile URL Prefix</label>
                <div>
                  <Field
                      type="text"
                      name="mobile_url"
                      component={ InputField }
                      validate={ [required] }
                  />
                </div>
              </div>
              <div css={ footer }>
                <div>
                  <DefaultButton
                      label="Cancel"
                      type="button"
                      onClickButton={ onCancel }
                  />
                </div>
                <div css={ error_container }>
                  { error && <p>{ error }</p>}
                </div>
                <div>
                  <PrimaryButton
                      label="Save" 
                      type="submit"
                  ></PrimaryButton>
                </div>
              </div>
            </form>
        )
    }
}
OrganisationNewForm = reduxForm({
    form: 'ORGANISATION_NEW_FORM'
})(OrganisationNewForm);
function mapStateToProps(state, props) {
    const { onSubmit, onCancel } = props

    return {
        onSubmit,
        onCancel,
        enableReinitialize: true,
    }
}
export default withRouter(connect(mapStateToProps)(OrganisationNewForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: flex-start;
justify-content: center;
margin: 10px;
padding: 10px;
width: 700px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`
