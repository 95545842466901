/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import { head, get } from 'lodash'
import { reset } from 'redux-form'
import { followerList } from '../actions/follower'
import { organisationList } from '../actions/organisation'

import MainLayout from './MainLayout'
import UserForm from './UserForm'
import BusyMask from './BusyMask'

class User extends Component {

    componentDidMount() {
        const { dispatch, follower_id } = this.props
        dispatch(organisationList.invalidateList())
        dispatch(organisationList.fetchListIfNeeded())
        dispatch(followerList.ensureObjectsLoaded([follower_id])) 
    }
    
    handleSubmit = (values) => {
        const { dispatch, history, organisation_id } = this.props

        if (!values.id) {
            values.isNewInstance = true
            values.organisation_id = organisation_id
        }
        values.notification_method = values.notification_method ? 'push_notification' : 'text_message'
        const on_done = function() {
            dispatch(followerList.invalidateList())
            history.push('/users')
        }
        return dispatch(followerList.saveObject(values, on_done))
    }

    onCancelUser = () => {
        const { dispatch, history } = this.props
        dispatch(reset('USER_FORM'))
        history.push('/users')
    }
    
    render() {
        const { follower_id, is_busy } = this.props
        let title = 'Add Contact'
        if (follower_id) {
            title = 'Edit Contact'
        }
        return (
            <MainLayout title={ title } layout="single">
              { is_busy && <BusyMask /> }
              <UserForm
                  onSubmit={ this.handleSubmit }
                  onCancelUser={ this.onCancelUser }
                  follower_id={ follower_id }
              />
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const follower_id = get(props, ["match", "params", "follower_id"], null)
    const organisation =  head(organisationList.getObjects()) || {}
    const follower = followerList.getObject(follower_id) || {}
    return {
        organisation_id: organisation.id,
        follower_id,
        follower,
        is_busy: organisationList.isLoading() || followerList.isLoading() || followerList.getIsSavingObject(),
    }
}
export default connect(mapStateToProps)(User)

