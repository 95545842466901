import { ItemList } from '../orm'
import { ENTITY_KEY__FOLLOWER, LIST_KEY__FOLLOWER } from './ItemListKeyRegistry'

class FollowerList extends ItemList {
    getEntityKey() {
        return ENTITY_KEY__FOLLOWER
    }
}

export const followerList = new FollowerList(LIST_KEY__FOLLOWER)
export const messageAssignedFollowerList = new FollowerList(LIST_KEY__FOLLOWER+"_message_assigned")
export const messageUnassignedFollowerList = new FollowerList(LIST_KEY__FOLLOWER+"_message_unassigned")
export const groupAssignedFollowerList = new FollowerList(LIST_KEY__FOLLOWER+"_group_assigned")
export const groupUnassignedFollowerList = new FollowerList(LIST_KEY__FOLLOWER+"_group_unassigned")

export const invalidateFollowerLists = (dispatch) => {
    dispatch(followerList.invalidateList())
    dispatch(messageAssignedFollowerList.invalidateList())
    dispatch(messageUnassignedFollowerList.invalidateList())
    dispatch(groupAssignedFollowerList.invalidateList())
    dispatch(groupUnassignedFollowerList.invalidateList())
}
