/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has } from 'lodash'

import MainLayout from './MainLayout'
import CommonTable from './CommonTable'
import PrimaryButton from './PrimaryButton'
import Pagination from './Pagination'
import Timestamp from './Timestamp'
import DefaultButton from './DefaultButton'
import TableFilter from './TableFilter'
import TableHeader from './TableHeader'
import ToggleButton from './ToggleButton'
import TableFooter from './TableFooter'

import { articleList } from '../actions/article'
import { imageList } from '../actions/image'

const FILTERS = { 'page': '1' }

class News extends Component {

    constructor(props) {
        super(props)
        this.state = {
            table_filters: FILTERS,
            toggle_filter: { published: false }
        }
    }
    
    componentDidMount() {
        this.refreshNews()
    }

    componentDidUpdate(old_props) {
        const { dispatch } = this.props
        dispatch(articleList.fetchListIfNeeded())
        dispatch(imageList.fetchListIfNeeded())
    }

    onEditArticle = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/article/'+item.id
        })
    }

    onAddArticle = () => {
        const { history } = this.props
        history.push('/article')
    }

    refreshNews = () => {
        const { dispatch } = this.props
        dispatch(articleList.invalidateList())
        dispatch(articleList.updateListFilter())
        dispatch(articleList.fetchListIfNeeded())
        dispatch(imageList.invalidateList())
        dispatch(imageList.fetchListIfNeeded())        
    }

    updateTableFilters = () => {
        const { dispatch } = this.props
        const { table_filters } = this.state
        dispatch(articleList.updateListFilter(table_filters))
    }

    handleSubmit = (values) => {
        const search = {'heading':values.any_field}
        const filters = Object.assign(FILTERS, search)
        this.setState({table_filters: filters})
        this.updateTableFilters()
    }

    onChangeToggle = (value) => {
        this.setState({published:value})
        const toggle = {'published':value}
        const filters = Object.assign(FILTERS, toggle)
        this.setState({table_filters: filters})
        this.updateTableFilters()
    }

    sortOnHeader = (key) => {
        const { table_filters } = this.state
        if (key !== 'thumbnail' && key !== 'action' && key !== 'status') {
            let sort_by = key
            if (has(table_filters, 'order_by') && table_filters['order_by'] === key) {
                sort_by = '-' + key
            }
            const order = {'order_by':sort_by}
            const filters = Object.assign(FILTERS, order)
            this.setState({table_filters: filters})
            this.updateTableFilters()
        }
    }
    
    getArticleCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format="date" />
            case 'status':
                if (item.is_expired) {
                    return 'Expired'
                } else if (item.published) {
                    return 'Published'
                } else {
                    return 'Unpublished'
                }
            case 'expires_at':
                if (item.never_expires) {
                    return 'Never'
                } else {
                    return <Timestamp value={item.expires_at} format="from_now" />
                }
            case 'thumbnail':
                return <img src={ item.thumbnail } alt="" css={ thumbnail } />
            case 'heading':
                return item.heading
            case 'action':
                return (
                <PrimaryButton
                    onClickButton={(evt) => this.onEditArticle(evt, item)}
                    label="Edit"
                >
                </PrimaryButton>
                )
                
            default:
                return <span>"No idea"</span>
        }
    }

    render() {
        const {
            news_headers,
            articles,
            is_loading,
        } = this.props
        const { table_filters, published } = this.state

        return (
            <MainLayout title="News">
              <div css={ container }>
                <TableHeader>
                  <div css={ filter_container }>
                    <TableFilter
                        onSubmit={ this.handleSubmit }
                        label="Articles"
                    />
                    <ToggleButton
                        value={ published }
                        onChange={ this.onChangeToggle }
                        on_label="Show Only Published"
                        off_label="Show All"
                        light={ true }
                    />
                  </div>
                    <DefaultButton
                        label="Add Article"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAddArticle }
                    />
                </TableHeader>
                  <CommonTable
                      is_loading={ is_loading }
                      empty_message="There are no articles."
                      headers={ news_headers }
                      items={ articles }
                      getCellValue={ this.getArticleCellValue }
                      sortOnHeader={ this.sortOnHeader }
                  />
                <TableFooter>
                  <div css={ pagination_container }>
                    <Pagination listKey={articleList.listKey} filters={ table_filters } />
                  </div>
                </TableFooter>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    let articles = articleList.getVisibleObjects()
    const images = imageList.getVisibleObjects()

    return {
        articles,
        images,
        news_headers: {
            created_at: { name: 'Date Created', column_size: 2},
            heading: { name: 'Article Heading', column_size: 4},
            thumbnail: { name: 'Thumbnail', column_size: 2},
            status: { name: 'Status', column_size: 2},
            expires_at: { name: 'Expires', column_size: 2},
            action: { name: 'Options', column_size: 1 }
        },
        is_loading: articleList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(News))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
`

const filter_container = css`
display: flex;
align-items: center;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const thumbnail = css`
max-width: 80px;
max-height: 80px;
`
