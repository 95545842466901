/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { jsx, css } from '@emotion/core'

class CheckboxField extends Component {

    constructor() {
        super()
        this.onChange = this.onChange.bind(this)
    }

    onChange(event) {
        const { isChecked, input, readOnly, postChange } = this.props
        if (readOnly) {
            return
        }
        input.onChange(!isChecked)
        if (postChange) {
            postChange(!isChecked)
        }
    }

    render() {
        const { readOnly, label, meta, input, isChecked} = this.props
        
        return (
            <div className={classNames('form-group', {'has-error': meta.touched && meta.error})}>
              <div className="checkbox">
                <label css={ checkbox }>
                  <input className="checkbox-field__input"
                         readOnly={readOnly}
                         onChange={this.onChange}
                         type="checkbox"
                         checked={isChecked} />
                  <div css={ label_container }>
                    {label}
                  </div>
                </label>
                {meta.touched && meta.error && <label className="label--invalid">{meta.error}</label>}
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { input: { value }, postChange } = props
    
    return {
        isChecked: Boolean(value),
        postChange,
    }
}

export default connect(mapStateToProps)(CheckboxField)

const checkbox = css`
display: flex;
align-items: center;
padding-left: 20px;
`

const label_container = css`
padding-left: 5px;
`
