/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import facebook_light from '../images/facebook_light.png'

class FacebookLoginButton extends Component {
    render() {
        const { onClickButton } = this.props
        
        return (
            <button css={ main } onClick={ onClickButton && onClickButton}>
              <div css={ content }>
                <img src={ facebook_light } alt="" css={ icon } /> Continue with Facebook
              </div>
            </button>
        )
    }
}
export default FacebookLoginButton

const main = css`
display: flex;
flex-direction: row;
align-items: center;
height: 38px;
color: white;
background: #1877F2;
border: none;
border-radius: .25rem;
cursor: pointer;
`

const content = css`
display: flex;
flex-direction: row;
align-items: center;
height: 100%;
padding: 6px;
`

const icon = css`
height: 100%;
padding-right: 5px;
`
