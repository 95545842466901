/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { capitalize, map } from 'lodash'

import MainLayout from './MainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import PrimaryButton from '../../components/PrimaryButton'
import DropdownFilter from '../../components/DropdownFilter'

import { adminSubscriptionList } from '../actions/admin_subscription'
import { adminOrganisationOptionList } from '../actions/admin_organisation'

import true_dark from '../../images/true_dark.png'
import false_dark from '../../images/false_dark.png'

class Subscriptions extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminOrganisationOptionList.clearListFilter())
        dispatch(adminOrganisationOptionList.updateListFilter({all: true}))
        dispatch(adminOrganisationOptionList.fetchListIfNeeded())
        this.onClearFilter()
        dispatch(adminSubscriptionList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminOrganisationOptionList.fetchListIfNeeded())
        dispatch(adminSubscriptionList.fetchListIfNeeded())
    }

    onFilterOrganisation = (value) => {
        const { dispatch } = this.props
        dispatch(adminSubscriptionList.updateListFilter({'organisation': value.value, 'page': 1}))
    }

    onClearFilter = () => {
        const { dispatch } = this.props
        dispatch(adminSubscriptionList.clearListFilter())
    }

    onEdit = (evt, item) => {
        window.open(item.admin_url, '_blank')
    }

    getSubscriptionCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'organisation_name':
                return capitalize(item.organisation_name)
            case 'updated_at':
                return (
                    <Timestamp value={item.updated_at} format='month-year' />
                )
            case 'amount':
                return item.amount
            case 'paid':
                return (
                    <img src={ item.paid ? true_dark : false_dark } alt="" css={ css`height: 25px;` } />
                )
            case 'amount_owed':
                return item.amount_owed
            case 'is_overdue':
                return (
                    <img src={ item.is_overdue ? true_dark : false_dark } alt="" css={ css`height: 25px;` } />
                )
            case 'action':
                return (
                    <PrimaryButton onClickButton={(evt) => this.onEdit(evt, item)}
                                   label="Edit">
                    </PrimaryButton>
                )
            default:
                return <span>"No idea"</span>
        }
    }

    render() {
        const {
            is_loading,
            subscription_headers,
            subscriptions,
            subscription_filter,
            filter_options,
        } = this.props

        return (
            <MainLayout title="Subscriptions">
              <div css={ container }>
                <TableHeader>
                  <div css={ header }>
                    <DropdownFilter
                        options={ filter_options }
                        placeholder="Organisations"
                        onChange={ this.onFilterOrganisation }
                        onClear={ this.onClearFilter }
                    />
                  </div>
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no subscriptions."
                    headers={ subscription_headers }
                    items={ subscriptions }
                    getCellValue={ this.getSubscriptionCellValue }
                />
                <TableFooter>
                  <div css={ pagination_container }>
                    <Pagination listKey={ adminSubscriptionList.listKey }
                                filters={ subscription_filter } />
                  </div>
                </TableFooter>
              </div>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const subscriptions = adminSubscriptionList.getVisibleObjects() || []
    const organisations = adminOrganisationOptionList.getVisibleObjects() || []
    const filter_options = map(organisations, (organisation) => {
        return {value: organisation.id, label: capitalize(organisation.name)}
    })
    return {
        subscriptions,
        filter_options,
        subscription_filter: adminSubscriptionList.getFilter(),
        subscription_headers: {
            organisation_name: { name: 'Organisation', column_size: 2 },
            updated_at: { name: 'Month', column_size: 2 },
            amount: { name: 'Fee', column_size: 2 },
            paid: { name: 'Paid', column_size: 2 },
            amount_owed: { name: 'Amount Owed', column_size: 2 },
            is_overdue: { name: 'Overdue', column_size: 2 },
            action: { name: 'Options', column_size: 1 },
        },
        is_loading: adminSubscriptionList.isLoading() || adminOrganisationOptionList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(Subscriptions))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 10px;
`

const header = css`
display: flex;
justify-content: flex-start;
width: 100%;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
