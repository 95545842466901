/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import {range} from 'lodash'
import { jsx, css } from '@emotion/core'

import { catch_errors } from '../lib'
import { default_theme as theme } from '../emotion/theme'
import { updateListFilter, invalidateList, getPagination } from '../orm/orm_list_actions'

class Pagination extends Component {

    gotoPage(number) {
        const { dispatch, listKey, filters } = this.props
        dispatch(updateListFilter(listKey, Object.assign(filters, {page: number})))
        dispatch(invalidateList(listKey))
        if (this.props.onChange) {
            this.props.onChange(number)
        }
    }

    renderPrevPage() {
        const { prev_page } = this.props
        if (!prev_page) {
            return (<li key={'prev_page'} className="disabled" css={ prev }>{ "<" }</li>)
        } else {
            return (<li onClick={() => this.gotoPage(prev_page)} key={'prev_page'} css={ prev }>{ "<" }</li>)
        }
    }

    renderNextPage() {
        const { next_page } = this.props
        if (!next_page) {
            return (<li key={'next_page'} className="disabled" css={ next }>{ ">" }</li>)
        } else {
            return (<li onClick={() => this.gotoPage(next_page)} key={'next_page'} css={ next }>{ ">" }</li>)
        }
    }

    renderItem(number, class_names, index) {
        return (
            <li onClick={() => this.gotoPage(number)} className={class_names} key={`page_${number}.${index}`}>
              {number}
            </li>
        )

    }

    getPage(number, index) {
        let {current_page } = this.props
        switch (number) {
            case current_page:
                return this.renderItem(number, 'active')
            case 'prev':
                return this.renderPrevPage()
            case 'next':
                return this.renderNextPage()
            case 'splitter':
                return this.renderItem('...', 'splitter', index)
            default:
                return this.renderItem(number)
        }
    }

    getPageNumbers() {
        const { num_pages, current_page} = this.props
        const adjacents = 3

        let numbers = ['prev']
        if (num_pages < 7 + (adjacents * 2)) {
            numbers = numbers.concat(range(1, num_pages + 1))
        } else if (num_pages > 5 + (adjacents * 2)) {
            if (current_page < 1 + (adjacents * 2)) {
                // close to beginning; only hide later pages
                numbers = numbers.concat([...range(1, 4 + (adjacents * 2)), 'splitter', num_pages -1, num_pages])
            } else if ((num_pages - (adjacents * 2) > current_page) && (current_page > (adjacents * 2))) {
                // in middle; hide some front and some back
                numbers = numbers.concat([
                    1, 2, 'splitter',
                    ...range(current_page - adjacents, current_page + adjacents + 1),
                    'splitter',
                    num_pages])
            } else {
                // close to the end, hide early pages
                numbers = numbers.concat([1, 2, ...range(num_pages - (2+(adjacents * 2)), num_pages)], num_pages)
            }
        }
        numbers.push('next')
        return numbers.map((number, index) => this.getPage(number, index))
    }

    render() {
        const {num_pages} = this.props

        if (this.props.num_items === 0) {
            return null;
        }
        const to_index = Math.min(this.props.last_item_index, this.props.num_items)
        return (
            <div css={ pagination_item }>
              <div css={ pagination_status }>
                <strong>
                  Showing {this.props.first_item_index} to {to_index} out of {this.props.num_items} entries
                </strong>
              </div>
              {num_pages > 1 &&
               <div css={ pagination_numbers } id="example_paginate">
                 <ul css={ pagination }>
                   {this.getPageNumbers()}
                 </ul>
               </div>
              }
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const pagination = getPagination(props.listKey, state)

    return {
        listKey: props.listKey,
        min_page_num: 1,
        current_page: pagination.current_page || 1,
        num_pages: pagination.num_pages || 0,
        num_items: pagination.num_items || 0,
        first_item_index: pagination.first_item_index || 1,
        last_item_index: pagination.last_item_index || 1,
        next_page: pagination.next_page,
        prev_page: pagination.prev_page
    }
}

export default connect(catch_errors(mapStateToProps))(Pagination)

const pagination_item = css`
display: flex;
justify-content: flex-start;
align-items: center;
padding-right: 15px;
color: ${theme.colors.white};
div {
padding-left: 15px;
}
`
const pagination_status = css`
display: flex;
align-items: center;
`

const pagination_numbers = css`
display: flex;
justify-content: flex-end;
align-items: flex-end;
`

const pagination = css`
list-style: none;
padding: 0;
margin: 0;

li {
display: inline-block;
background-color: ${theme.colors.light};
color: ${theme.colors.black};
padding: 7px;
text-align: center;
min-width: calc(38px - 20px);
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
&:hover {
background-color: ${theme.colors.gray2}
}
}
`

const prev = css`
border-top-left-radius: .25rem;
border-bottom-left-radius: .25rem;
`

const next = css`
border-top-right-radius: .25rem;
border-bottom-right-radius: .25rem;
`
